import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { FilePond, registerPlugin } from 'react-filepond';
import axios from 'axios';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

class UploadImage extends PureComponent {

  render(){
    const token = localStorage.getItem('x-auth');
    const { pictures, processURL, onDrop, picturesURL, t } = this.props

    return (
      <FilePond
        files={pictures}
        name="pics"
        acceptedFileTypes={['image/*']}
        allowMultiple={false}
        maxFiles={1}
        server={{
          url: window.location.protocol + '//' + window.location.hostname + '/api',
          process: {
            url: processURL,
            method: 'POST',
            withCredentials: false,
            headers: { Authorization: token },
            timeout: 7000,
            onload: response => {
              // console.log('onload process', response);
              return response;
            },
            onerror: response => console.log('post onerror', response),
            ondata: formData => {
              // console.log('ondata process', formData);
              return formData;
            }
          },
          revert: {
            url: processURL,
            method: 'DELETE',
            withCredentials: false,
            headers: { Authorization: token },
            timeout: 7000,
            // onload: response => console.log('onload', response),
            onerror: response => console.log('revert onerror', response),
            ondata: null
          },
          remove: async (source, load, error) => {
            try {
              // console.log('source', source);
              const token = localStorage.getItem('x-auth');
              await axios.delete(window.location.protocol + '//' + window.location.hostname + `/api${processURL}`,
                {
                  headers: {
                    Authorization: token
                  },
                  data: source
                }
              );
              load();
            } catch (err) {
              console.error('remove', err);
              error('oh my goodness');
            }
          },
          load: {
            url: picturesURL,
            method: 'GET',
            withCredentials: false,
            headers: { Authorization: token },
            timeout: 7000,
            onload: response => {
              // console.log('onload', response);
              return response;
            },
            onerror: response => console.log('load onerror', response),
            ondata: formData => {
              // console.log('ondata', formData);
              return formData;
            }
          }
        }}
        onprocessfile={onDrop}
        onerror={(err) => console.log('ERROR', err)}
        // onupdatefiles={onDrop}
        labelIdle={t(
          'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
        )}
      />
    );
  }
};

export default withTranslation()(UploadImage);
