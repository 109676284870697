import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';

import { capitalizeFirstWord, capitalizeFirstLetter } from '../../../../utils/helpers';
import { getCFH } from '../../../../utils/maths';
import { findParabolicAandC } from '../../../../utils/goldenSectionSearch';

const Second = ({ site, t }) => {

  const equipmentResume = site.equipments.map(eq => {
    let min;
    eq.zones.forEach(zone => {
      let CFH
      if(zone && zone.impacts && zone.impacts.length > 0){
        const HICpoints = zone.impacts.map(({ height, hic }) => [height / 100, hic])
        const gMaxPoints = zone.impacts.map(({ height, gmax }) => [height / 100, gmax])
        const HICResult = findParabolicAandC(HICpoints);
        const GmaxResult = findParabolicAandC(gMaxPoints);
        CFH = (getCFH(HICResult, GmaxResult)[1]).toFixed(2);
        if(!min || CFH < min){
          min = CFH
        }
      }
    })
    
    return {
      zone: eq.equipment,
      CFH: min
    }
  })

  const columns = ['zone', 'CFH'].map(el => ({
    Header: capitalizeFirstWord(
      el === 'zone' ? t('Name / Reference') : t('CFH in m')
    ),
    accessor: el,
    minWidth: el === 'zone' ? 200 : el === 'result' ? 120 : 60,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-1">{row.value}</div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="mt-8mm text-xl">{capitalizeFirstLetter(t('results summary'))}</div>
      
      <div className="mt-4mm text-sm">
        <ReactTable
          data={equipmentResume}
          columns={columns}
          minRows={1}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('no data found')}
          pageText={t('page')}
          ofText={t('ofText')}
          rowsText={t('rows')}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>

      <div className="mt-8mm text text-black">
        {t('m1lab_second_info')}
      </div>
    </article>
  );
};

export default withTranslation()(Second);
