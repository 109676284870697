import { includes } from 'lodash';
import { getPolyResults, getCFH } from './maths';
import { findParabolicAandC } from '../utils/goldenSectionSearch'

export const capitalizeFirstWord = txt => {
  if (typeof txt !== 'string') {
    return (
      txt
        .join('')
        .charAt(0)
        .toUpperCase() + txt.join('').slice(1)
    );
  }
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};

export const capitalizeFirstLetter = word => word.charAt(0).toUpperCase() + word.slice(1)

export const getEquipmentResult = (equipment, method, isLabTest) => {
  if(method === 1 && isLabTest){
    let cfhList = equipment.zones
      .map(z => {
        const {impacts} = z
        if(!impacts || impacts.length === 0){
          return null
        }
        const HICResult = findParabolicAandC(
          impacts.map(({ height, hic }) => [height/100, hic])
        );
        const GmaxResult = findParabolicAandC(
          impacts.map(({ height, gmax }) => [height/100, gmax])
        );
        return getCFH(HICResult, GmaxResult)[1];
      })
      .filter(cfh => cfh !== null && cfh !== undefined)

    return Math.min(...cfhList) * 100
  }
  else {
    if (equipment.zones.length === 0) return null;
    const zonesResult = equipment.zones.map(({ impacts, ffh }) => {
      if (!ffh || !impacts || impacts.length === 0) return null;
      impacts = impacts.filter(i => !i.deleted_at && !i.deletedAt)
      impacts = impacts.map( ({height, hic, ...rest}) => {
        return {
          ...rest,
          HIC: hic,
          height: height
        }
      })
      const { result } = getPolyResults(impacts, ffh, method);
      console.log('m2 equipment result', impacts, ffh, method, result)
      return result;
    });
    if (includes(zonesResult, 'Not Adequate')) return 'Not Adequate';
    if (includes(zonesResult, 'To Review')) return 'To Review';
    if (zonesResult.filter(r => r === null || r === undefined).length === zonesResult.length) {
      return null;
    }
    return 'Adequate';
  }
};

export const getResultColor = (result) => {
  switch (result) {
    case 'To Review':
      return '#FF7A00'
    case 'Adequate':
      return '#199D5E'
    case 'Not Adequate':
      return 'red'
    default:
      return 'inherit'
  }
}
