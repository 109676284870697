import React, { Fragment } from 'react';

import Page from './Page';
import { First, CustomPage, Annex } from './commons';
import { Second, Equipment, Zone, EquipmentSummary } from './pages/Method1Lab';

export default ({ report, user, site }) => {
  let i = 0,
    j = 0;
  let pageCount = 0

  // Total pages
  let totalPages = 0
  // First + Second + nbEquipement * 2 + nbZone for each Equipment + Annex ? + Custom page ?
  if(site){
    totalPages += 2 // First + Second
    if(site.equipments && site.equipments.length > 0){
      totalPages += site.equipments.length * 2
      totalPages += site.equipments.reduce( (nbZone, e) => nbZone += e.zones ? e.zones.length : 0, 0)
    }
    if (site.annexes && site.annexes.length > 0) totalPages++;
  }

  if (report.htmlCustomPage && report.htmlCustomPage.length > 10) {
    totalPages++;
  }
  
  return (
    <>
      <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
        <First user={user} site={site} isLabTest />
      </Page>
      {report.htmlCustomPage && report.htmlCustomPage.length > 10 && (
        <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
          <CustomPage report={report} />
        </Page>
      )}
      <Page
        report={report}
        pageNumber={++pageCount}
        totalPages={totalPages}
      >
        <Second site={site} />
      </Page>
      {site.equipments &&
        site.equipments.map(equipment => {
          i += 1;
          return (
            <Fragment key={equipment.id}>
              <Page
                report={report}
                pageNumber={++pageCount}
                totalPages={totalPages}
              >
                <Equipment i={i} equipment={equipment} />
              </Page>
              <Page
                report={report}
                pageNumber={++pageCount}
                totalPages={totalPages}
              >
                <EquipmentSummary i={i} equipment={equipment} />
              </Page>
              {equipment.zones &&
                equipment.zones.map(zone => {
                  j += 1;
                  return (
                    <Page
                      key={zone.id}
                      report={report}
                      pageNumber={++pageCount}
                      totalPages={totalPages}
                    >
                      <Zone i={i} j={j} zone={zone} equipment={equipment} />
                    </Page>
                  );
                })}
            </Fragment>
          );
        })}
      {site.annexes && site.annexes.length > 0 && (
        <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
          <Annex site={site} />
        </Page>
      )}
    </>
  );
};
