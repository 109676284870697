import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { withTranslation } from 'react-i18next';

class ImpactChart extends PureComponent {
  render() {
    let { height, points, t } = this.props;
    try {
      // Parse
      points = JSON.parse(points)
    }
    catch(e){
      console.log('error parsing points', e)
      points = []
    }
      // transform points to ms with fixed framerate
      points = points.map(p => {
        p.t /= 20 // 20 = 20000 (frequency in point by seconds) / 1000 (nb ms in second) 
        return p
      })

    return (
      <ResponsiveContainer height={height || 350} width="100%">
        <LineChart
          data={points}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="t" padding={{ left: 30, right: 30 }}>
            <Label
              value={`${t('time')} (ms)`}
              offset={-10}
              position="insideBottom"
              style={{
                textAnchor: 'middle',
                fontSize: '90%',
                fill: '#606f7b'
              }}
            />
          </XAxis>
          <YAxis yAxisId="left" dataKey="q" padding={{ top: 30, bottom: 30 }}>
            <Label
              value={`${t('Acceleration')} (g)`}
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
                fontSize: '90%',
                fill: '#606f7b'
              }}
            />
          </YAxis>
          <ReferenceLine yAxisId="left" x={488} stroke="grey" />
          <ReferenceLine yAxisId="left" x={493} stroke="grey" />
          <Tooltip />
          <Line
            yAxisId="left"
            type="basis"
            dataKey="q"
            stroke="#8884d8"
            dot={false}
          />
          <Line
            yAxisId="left"
            type="basis"
            dataKey="x"
            stroke="#880000"
            dot={false}
          />
          <Line
            yAxisId="left"
            type="basis"
            dataKey="y"
            stroke="#008800"
            dot={false}
          />
          <Line
            yAxisId="left"
            type="basis"
            dataKey="z"
            stroke="#000088"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default withTranslation()(ImpactChart);
