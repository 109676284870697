import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { findParabolicAandC } from   '../../../../utils/goldenSectionSearch'
import { maxBy } from 'lodash';

import { capitalizeFirstWord, capitalizeFirstLetter, getResultColor } from '../../../../utils/helpers';
import MethodOneChart from '../../../../components/commons/MethodOneChart';
import ImpactChart from '../../../Impact/ImpactChart';
import defaultImg from '../../commons/default.jpg';
import { getCFH, getPolyResults } from '../../../../utils/maths';

moment.locale('fr');

const Zone = ({ j, zone: zone_data, equipment, t }) => {
  let impacts = [];
  let zone = {
    ...zone_data,
    FFH: zone_data.ffh ? zone_data.ffh / 100 : null
  };

  if (zone.impacts && zone.impacts.length > 0) {
    impacts = zone.impacts.filter(i => !i.deleted_at && !i.deletedAt)
      .map(({ hic, height, ...rest }) => ({
        ...rest,
        HIC: hic,
        height: height / 100
      }))
  }

  let resultColor;
  
  if (zone.FFH && impacts && impacts.length > 0) {
    const HICResult = findParabolicAandC(
      impacts.map(({ height, HIC }) => [height, HIC])
    );
    const GmaxResult = findParabolicAandC(
      impacts.map(({ height, gmax }) => [height, gmax])
    );
    const {result} = getPolyResults(impacts, zone_data.ffh / 100, 1);
    resultColor = getResultColor(result)
    const CFH = getCFH(HICResult, GmaxResult);
    zone = { ...zone, result, CFH: CFH ? CFH[1] : null };
  }

  const columns = ['height', 'HIC', 'gmax'].map(el => ({
    Header: capitalizeFirstWord(t(el)),
    accessor: el,
    minWidth: 40,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-0 text-sm">
            {el === 'height' ? `${row.value} m` : row.value}
          </div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="text-2xl">{`${equipment.equipment} - ${zone.zone}`}</div>
      <div className="mt-4mm flex justify-between">
        <div className="flex-39 pr-2mm">
          <ul className="list-reset text-xl text-left font-bold text-black">
            {['FFH', 'CFH', 'result'].map(
              item =>
                zone[item] && (
                  <li key={item} className="mt-2mm">
                    {capitalizeFirstWord(t(item))} :{' '}
                    <span
                      className={`text-primary`}
                      style={{color: item === 'result' ? resultColor: 'inherit'}}
                    >
                      {item !== 'result'
                        ? `${zone[item].toFixed(2)} m`
                        : zone[item]}
                    </span>
                  </li>
                )
            )}
          </ul>
          <div className="mt-4mm mr-2mm text-sm">
            <ReactTable
              data={impacts}
              columns={columns}
              minRows={1}
              previousText={t('previous')}
              nextText={t('next')}
              loadingText={t('loading')}
              noDataText={t('no data found')}
              pageText={t('page')}
              ofText={t('ofText')}
              rowsText={t('rows')}
              defaultPageSize={12}
              showPagination={false}
              className="-striped -highlight"
              defaultSorted={[
                {
                  id: 'height'
                }
              ]}
            />
          </div>
        </div>
        <div className="flex-59 overflow-hidden">
          <img
            className="max-h-80mm object-contain border-2 border-primary-dark"
            src={
              zone.picture
                ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/zones/${zone.id}/${zone.picture}`
                : defaultImg
            }
            alt="zone"
          />
        </div>
      </div>
      <div className="mt-2mm text-sm text-left font-normal text-black text-center">
        <p className="font-bold">
          {capitalizeFirstWord(t('temperature'))} :{' '}
          <span className="text-primary-dark">{zone['temp']} °C</span>
          <span className="mx-4mm">/</span>
          {capitalizeFirstWord(t('hygrometry'))} :{' '}
          <span className="text-primary-dark">{zone['hygro']} %</span>
        </p>
      </div>
      <div className="mt-8mm text-sm font-normal text-black">
      {zone.comments && <p>
          <span className='font-bold'>{t('Comment') + ' : '}</span> 
          <span>{zone.comments}</span>
        </p>
      }
      </div>
      <div className="mt-8mm -mx-8mm flex text-sm">
      <div className="w-2/5">
          <div>
            <ImpactChart points={maxBy(impacts, 'gmax').points} height={240} />
          </div>
          <div className="mt-4mm text-black text-lg">
            {capitalizeFirstLetter(t('time/acceleration curve of the highest value measured'))}
          </div>
        </div>
        <div className="w-3/5">
          <div>
            <MethodOneChart impacts={impacts} height={240} />
          </div>
          <div className="mt-4mm text-black text-lg">
            {capitalizeFirstLetter(t('curves of HIC and gmax vs. drop height'))}
          </div>
        </div>
      </div>
      <div className="mt-8mm text-xs text-black">
        {capitalizeFirstLetter(t('m1_zone_result_information'))}
      </div>
    </article>
  );
};

export default withTranslation()(Zone);
