import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { Container } from '../components';
import { Title } from '../components/commons';
import { capitalizeFirstWord } from '../utils/helpers';

export default withTranslation()(({ t }) => (
  <Container isPrivate={true}>
    <Helmet>
      <title>{t('page not found')} - Lugdosphère</title>
    </Helmet>
    <div className="w-full max-w-md mx-auto my-8">
      <Title>404 - {t('Page not found')}</Title>
      <h2 className="mt-2 text-xs tracking-wide text-grey-dark uppercase">
        <Link to="/" className="">
          {capitalizeFirstWord(t('Go back to the Home page'))}
        </Link>
      </h2>
    </div>
  </Container>
));
