import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

import {Input, DisabledInput} from '../../components/commons'

class VersionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newReportVersion: this.props.lastReportVersion || '',
    };
  }

  render() {
    const {t,
    lastReportVersion,
    onVersionUpdate,
    isLoading
  } = this.props;
  const {newReportVersion} = this.state
return (
  <div
    className="modal fixed z-50 pin overflow-auto flex items-center p-8 justify-center"
  >
    <div
      className="fixed pin overflow-auto bg-primary opacity-50"
    />
    <div className="animated fadeInUp max-w-sm relative pin-b pin-x align-top m-auto justify-end justify-center p-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
  <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-8">
                <span className="border-b-6 border-grey pr-16">
                  {t('report version')}
                </span>
              </div>
  <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <DisabledInput
                fieldName="last version"
                label={t('last version')}
                value={lastReportVersion}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <Input
                fieldName="new version"
                label={t('new version')}
                type="text"
                value={newReportVersion}
                onChangeField={(field, e) => this.setState({
                  newReportVersion: e.target.value,
                })}
                defaultHint={t('Edit if necessary')}
              />
            </div>
          </div>
      <div className="text-right">
        <button
          className={`${isLoading ? 'spinner ' : ''}bg-primary hover:bg-primary-dark text-white font-bold py-4 px-16 tracking-wide rounded focus:outline-none focus:shadow-outline uppercase`}
          type="button"
          onClick={() => onVersionUpdate(newReportVersion)}
          disabled={isLoading}
        >
          {t('Validate')}
        </button>
      </div>
    </div>
  </div>
);
  }
}

export default withTranslation()(VersionModal);
