import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import {
  Header,
  BottomActions,
  Label,
  UploadImage,
  ErrorAlert,
  Spinner
} from '../../components/commons';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footnote: null,
      logos: [],
      htmlCustomPage: null,
      isLoading: false,
      isSuccess: false,
      warnings: {
        footnote: null
      },
      errors: {
        footnote: null
      },
      error: null,
      screenIsLoading: true
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api/report`, {
        headers: {
          Authorization: token
        }
      })
        .then(res => {
          this.setState({
            ...res.data,
            logos: res.data.logo
              ? [
                  {
                    source: res.data.logo,
                    options: {
                      type: 'local'
                    }
                  }
                ]
              : [],
            htmlCustomPage: res.data.htmlCustomPage
              ? JSON.parse(res.data.htmlCustomPage)
              : null,
            error: null,
            screenIsLoading: false
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            error: JSON.stringify(err.response),
            screenIsLoading: false
          });
        });
    }
  }

  render() {
    const { t } = this.props;
    const {
      logos,
      footnote,
      isLoading,
      isSuccess,
      screenIsLoading,
      htmlCustomPage,
      // errors,
      // warnings,
      error
    } = this.state;
    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;
    return (
      <div>
        <ErrorAlert error={error} />
        <Header title={t('report')} subtitle={t('personalise your reports')} />
        <div className="w-full max-w-md mb-10">
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="pics">{t('Logo of your company')}</Label>
              <UploadImage
                pictures={logos}
                onDrop={this.onDrop}
                processURL={`/report/logo`}
                picturesURL={`/uploads/logo/`}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="footnote">{t('footnote')}</Label>
              <textarea
                name="footnote"
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white border-grey-lighter focus:border-grey"
                cols="40"
                rows="2"
                value={footnote || ''}
                onChange={e => this.handleChangeField('footnote', e)}
              />
            </div>
          </div>
          <div className="flex mb-10">
            <div className="w-full">
              <Label htmlFor="secondPage">
                {t('Personnalized second page')}
              </Label>
              <ReactQuill
                theme="snow"
                formats={['header']}
                modules={{
                  toolbar: [
                    [{ header: '2' }]
                    // [{ size: [] }],
                    // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    // [{ list: 'ordered' }, { list: 'bullet' }],
                    // ['link', 'image'],
                    // ['clean']
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false
                  }
                }}
                value={htmlCustomPage}
                onChange={html =>
                  this.setState({
                    htmlCustomPage: html,
                    isSuccess: false,
                    error: null
                  })
                }
                placeholder={t('Here you can add personnalized content for a second page of the reports...')}
              />
            </div>
          </div>
          <BottomActions
            primaryAction={this.handleSubmit}
            primaryTxt={t('save modifications')}
            primarySuccessTxt={t('modifications saved')}
            serverErr={error}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    );
  }

  handleSubmit = () => {
    const { footnote, htmlCustomPage, warnings } = this.state;
    const { t } = this.props;
    let errors = null;
    Object.entries(warnings).forEach(warning => {
      if (warning[1]) {
        errors = { ...errors, [warning[0]]: warning[1] };
      }
    });
    if (errors) {
      this.setState({
        errors,
        error: t('Please correct form errors')
      });
    } else if (footnote || htmlCustomPage) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api/report`,
          {
            footnote,
            htmlCustomPage: htmlCustomPage
              ? JSON.stringify(htmlCustomPage)
              : null
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            isLoading: false,
            isSuccess: true,
            error: null
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isLoading: false,
            error: JSON.stringify(err.response)
          });
        });
    }
  };


  onDrop = (error, file) => {
    if (file){
      this.setState({
        logos: file
          ? [
              {
                source: file.file,
                options: {
                  type: 'local'
                }
              }
            ]
          : []
      });}
  };

  handleChangeField = (key, event) => {
    const value = event.target.value;
    // const { t } = this.props;
    this.setState({
      [key]: value,
      isSuccess: false,
      error: null
    });
    // if (value.length > 0 && value.length < 6 && key !== 'footnote') {
    //   this.setState(prevState => ({
    //     warnings: { ...prevState.warnings, [key]: t('Minimum 6 characters') }
    //   }));
    // } else if (value.length > 42) {
    //   this.setState(prevState => ({
    //     warnings: {
    //       ...prevState.warnings,
    //       [key]: t('Maximum 42 characters')
    //     }
    //   }));
    // } else {
    //   this.setState(prevState => ({
    //     warnings: { ...prevState.warnings, [key]: null },
    //     errors: { ...prevState.errors, [key]: null }
    //   }));
    // }
  };
}

export default withTranslation()(Report);
