import React from 'react';
import { withTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../utils/helpers';

const EquipmentTempHygro = ({ equipment, t }) => {
    console.log('equipment', equipment)

    let temp, minTemp, maxTemp,
    specimenTemp, specimenMaxTemp, specimenMinTemp, 
    hygro, minHygro, maxHygro, 
    specimenHygro, specimenMaxHygro, specimenMinHygro;

    const tempNotNull = equipment.zones.map(z => z.temp).filter(temp => !!temp)
    if(tempNotNull.length > 0){
        minTemp = Math.min(...tempNotNull)
        maxTemp = Math.max(...tempNotNull)
        temp = tempNotNull.reduce(
        (acc, temp) => acc + temp,
        0
        ) / tempNotNull.length;
    }

    const hygroNotNull = equipment.zones.map(z => z.hygro).filter(hygro => !!hygro)
    if(hygroNotNull.length > 0){
        minHygro = Math.min(...hygroNotNull)
        maxHygro = Math.max(...hygroNotNull)
        hygro = hygroNotNull.reduce(
        (acc, hygro) => acc + hygro
        , 0
        ) / hygroNotNull.length;
    }

    const specimenTempNotNull = equipment.zones.map(z => z.specimen_temp).filter(temp => !!temp)
    if(specimenTempNotNull.length > 0){
        specimenMinTemp = Math.min(...specimenTempNotNull)
        specimenMaxTemp = Math.max(...specimenTempNotNull)
        specimenTemp = specimenTempNotNull.reduce(
        (acc, temp) => acc + temp,
        0
        ) / specimenTempNotNull.length;
    }
    
    const specimenHygroNotNull = equipment.zones.map(z => z.specimen_hygro).filter(hygro => !!hygro)
    if(specimenHygroNotNull.length > 0){
        specimenMinHygro = Math.min(...specimenHygroNotNull)
        specimenMaxHygro = Math.max(...specimenHygroNotNull)
        specimenHygro = specimenHygroNotNull.reduce(
        (acc, hygro) => acc + hygro
        , 0
        ) / specimenHygroNotNull.length;
    }

    return (
        <div>
            <div className="mt-4mm flex space-between text-md text-black font-bold text-center">
                <div className="w-1/2">{capitalizeFirstLetter(t('temperature')) + ` : ${temp.toFixed(1)} °C`}</div>
                <div className="w-1/2">{capitalizeFirstLetter(t('hygrometry')) + ` : ${Math.round(hygro)} %`}</div>      
            </div>
            <div className="mt-4mm flex justify-between text-md text-black">
                <div>{capitalizeFirstLetter(t('min_temperature')) + ` : ${minTemp.toFixed(1)} °C`}</div>
                <div>{capitalizeFirstLetter(t('max_temperature')) + ` : ${maxTemp.toFixed(1)} °C`}</div>
                <div>{capitalizeFirstLetter(t('min_hygro')) + ` : ${Math.round(minHygro)} %`}</div>
                <div>{capitalizeFirstLetter(t('max_hygro')) + ` : ${Math.round(maxHygro)} %`}</div>      
            </div>

            {(specimenTemp || specimenHygro) && <React.Fragment> 
                <div className="mt-4mm flex space-between text-md text-black font-bold text-center">
                    {specimenTemp && <div className="w-1/2">{capitalizeFirstLetter(t('specimen_temp')) + ` : ${specimenTemp.toFixed(1)} °C`}</div>}
                    {specimenHygro && <div className="w-1/2">{capitalizeFirstLetter(t('specimen_hygro')) + ` : ${Math.round(specimenHygro)} %`}</div>}
                </div>
                <div className="mt-4mm flex justify-between text-md text-black">
                    <div>{capitalizeFirstLetter(t('min_temperature')) + ` : ${specimenMinTemp.toFixed(1)} °C`}</div>
                    <div>{capitalizeFirstLetter(t('max_temperature')) + ` : ${specimenMaxTemp.toFixed(1)} °C`}</div>
                    <div>{capitalizeFirstLetter(t('min_hygro')) + ` : ${Math.round(specimenMinHygro)} %`}</div>
                    <div>{capitalizeFirstLetter(t('max_hygro')) + ` : ${Math.round(specimenMaxHygro)} %`}</div>      
                </div>
            </React.Fragment>
            } 
        </div>
    );
};

export default withTranslation()(EquipmentTempHygro);