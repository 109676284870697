import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './utils/i18n';
import './index.css';
import 'react-table/react-table.css';
import 'react-quill/dist/quill.snow.css';
import { App } from './components';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Suspense fallback={<div className="spinner h-screen" />}>
    <Router>
        <App />
    </Router>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
