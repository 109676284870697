import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGlobeEurope,
  faArrowRight,
  faTrashAlt,
  faArrowLeft,
  faChevronLeft,
  faCircle,
  faPlus,
  faMapMarkedAlt,
  faUserCircle,
  faHeadset,
  faVial,
  faFlask,
  faDownload,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faThermometerThreeQuarters,
  faWifi,
  faTint,
  faPowerOff,
  faClone,
  faArrowsAltV,
  faWeightHanging
} from '@fortawesome/free-solid-svg-icons';

import PrivateRoute from './PrivateRoute';
import {
  Login,
  Home,
  Settings,
  NotFound,
  Site,
  Equipment,
  Zone,
  Impact,
  Report
} from '../../pages';
import { Spinner, ErrorAlert } from '../commons';
import { NavBar } from '..';

library.add(
  faGlobeEurope,
  faArrowRight,
  faTrashAlt,
  faArrowLeft,
  faChevronLeft,
  faCircle,
  faPlus,
  faMapMarkedAlt,
  faUserCircle,
  faHeadset,
  faVial,
  faFlask,
  faDownload,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faThermometerThreeQuarters,
  faTint,
  faPowerOff,
  faClone,
  faArrowsAltV,
  faWifi,
  faWeightHanging
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      serverErr: null,
      metrics: null,
      firmwareInfos: null
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token && token !== 'undefined') {
      this.setState({ isLoading: true });
      axios
        .post(window.location.protocol + '//' + window.location.hostname + `/api/verify/jwt`, { token })
        .then(res => {
          localStorage.setItem('is-admin', res.data.is_admin);
          localStorage.setItem('username', res.data.username);
          this.setState({ isLoading: false });
        })
        .catch(err => {
          console.log('err', err && err.response);
          this.setState({
            isLoading: false,
            serverErr: err && JSON.stringify(err.response)
          });
          if (err && err.response && err.response.status === 401) {
            localStorage.removeItem('x-auth');
            localStorage.removeItem('is-admin');
            this.props.history.push('/login');
          }
        });
    }
    this.setState({ isLoading: false });

  }

  render() {
    const { isLoading, serverErr, metrics, firmwareInfos } = this.state;

    if (isLoading) return <Spinner loading={isLoading} />;
    return (
      <>
        <Helmet>
          <title>Lugdosphère</title>
          <meta property="og:url" content="https://www.app.lugdosphere.fr/" />
        </Helmet>
        <ErrorAlert error={serverErr} />
        <NavBar 
          metrics={metrics} 
          connectivity={{ 
            hic: firmwareInfos && firmwareInfos.hicConnected,
            internet: firmwareInfos && firmwareInfos.internet
          }}
        />
        <Switch>
          <Route exact path="/login" render={props => <Login {...props} />} />
          <Route exact path="/settings/wifi" render={props => <Settings {...props} wifiConfigOffline />} />
          <PrivateRoute
            exact
            path="/"
            component={Home}
            handleLogout={this.handleLogout}
          />
          <PrivateRoute
            path={`/settings/:settingsSection`}
            component={Settings}
            handleLogout={this.handleLogout}
          />
          <PrivateRoute
            path="/settings"
            component={Settings}
            handleLogout={this.handleLogout}
          />
          <PrivateRoute exact path="/sites/:id" component={Site} />
          <PrivateRoute exact path="/sites/:id/report" component={Report} />
          <PrivateRoute exact path="/equipments/:id" component={Equipment} />
          <PrivateRoute
            exact
            path="/equipments/:id/zones/:id"
            component={Zone}
          />
          <PrivateRoute
            exact
            path="/zones/:id/impacts/:id"
            component={Impact}
          />
          <Route component={NotFound} />
        </Switch>
      </>
    );
  }

  handleLogout = () => {
    localStorage.removeItem('x-auth');
    localStorage.removeItem('is-admin');
    this.props.history.push('/login');
  };
}

export default withRouter(App);