import React from 'react';
import { withTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../utils/helpers';

const SecondTempHygro = ({ site, t }) => {
    console.log('site', site)

    let temp, minTemp, maxTemp,
    hygro, minHygro, maxHygro

    const tempNotNull = site.equipments.flatMap(e => e.zones.map(z => z.temp).filter(temp => !!temp))
    if(tempNotNull.length > 0){
        minTemp = Math.min(...tempNotNull)
        maxTemp = Math.max(...tempNotNull)
        temp = tempNotNull.reduce(
        (acc, temp) => acc + temp,
        0
        ) / tempNotNull.length;
    }

    const hygroNotNull = site.equipments.flatMap(e => e.zones.map(z => z.hygro).filter(hygro => !!hygro))
    if(hygroNotNull.length > 0){
        minHygro = Math.min(...hygroNotNull)
        maxHygro = Math.max(...hygroNotNull)
        hygro = hygroNotNull.reduce(
        (acc, hygro) => acc + hygro
        , 0
        ) / hygroNotNull.length;
    }

    return (
        <div>
            {temp && hygro && 
                <div className="mt-4mm flex space-between text-md text-black font-bold text-center">
                    <div className="w-1/2">{capitalizeFirstLetter(t('temperature')) + ` : ${temp.toFixed(1)} °C`}</div>
                    <div className="w-1/2">{capitalizeFirstLetter(t('hygrometry')) + ` : ${Math.round(hygro)} %`}</div>      
                </div>
            }
            {minTemp && maxTemp && minHygro && maxHygro &&
                <div className="mt-4mm flex space-between font-normal text-md text-black text-center">
                    <div className="w-1/4">{capitalizeFirstLetter(t('min_temperature')) + ` : ${minTemp.toFixed(1)} °C`}</div>
                    <div className="w-1/4">{capitalizeFirstLetter(t('max_temperature')) + ` : ${maxTemp.toFixed(1)} °C`}</div>
                    <div className="w-1/4">{capitalizeFirstLetter(t('min_hygro')) + ` : ${Math.round(minHygro)} %`}</div>
                    <div className="w-1/4">{capitalizeFirstLetter(t('max_hygro')) + ` : ${Math.round(maxHygro)} %`}</div>      
                </div>
            }
        </div>
    );
};

export default withTranslation()(SecondTempHygro);