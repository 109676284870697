import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Geolocation from 'react-geolocation';
import { debounce } from 'lodash';

import { Container } from '../../components';
import {
  Label,
  Header,
  UploadImage,
  BottomActions,
  ConfirmDelete,
  ErrorAlert,
  Spinner,
  Modal
} from '../../components/commons';
import { capitalizeFirstWord, getEquipmentResult, getResultColor } from '../../utils/helpers';
import { shallowCompare } from '../../utils/react';
import DownloadDataForm from './DownloadDataForm';

const handleKeyPress = (e, onSubmit) => {
  if (e.key === 'Enter') {
    onSubmit();
  }
};

const openInNewTab = url => {
  const win = window.open(url, '_blank');
  win.focus();
};

class Site extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      site: null,
      address: null,
      newEquipment: null,
      sitePicture: [],
      warnings: null,
      formErrors: {},
      error: null,
      isSuccess: null,
      isLoading: null,
      isReportLoading: null,
      isReportSuccess: null,
      newEquipIsLoading: null,
      isDeleteModalActive: null,
      isDeleteSiteLoading: null,
      isDeleteSiteSuccess: null,
      missingInfos: null,
      toggleMissingReportInfos: false,
      screenIsLoading: true,
      geolocation: null,
      geoError: null,
      isDownloadModalActive: false,
      annexes: [{ id: 0, picture: [] }]
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState) || null
  }
  
  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api/sites/${
          this.props.location.pathname.split('sites/')[1]
        }?with=all`,
        {
          headers: {
            Authorization: token
          }
        }
      )
        .then(res => {
          this.setState({
            ...res.data,
            site: res.data,
            sitePicture: res.data.picture
              ? [
                  {
                    source: res.data.picture,
                    options: {
                      type: 'local'
                    }
                  }
                ]
              : [],
            annexes:
              res.data.annexes && res.data.annexes.length > 0
                ? res.data.annexes
                    .map(annex => ({
                      ...annex,
                      picture: annex.picture
                        ? [
                            {
                              source: annex.picture,
                              options: {
                                type: 'local'
                              }
                            }
                          ]
                        : [],
                      isUploaded: true
                    }))
                : [{ id: 0, picture: [] }],
            error: null,
            screenIsLoading: false
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            error: JSON.stringify(err.response),
            screenIsLoading: false
          });
        });
    }
  }

  render() {
    const { t } = this.props;
    const {
      site,
      address,
      newEquipment,
      sitePicture,
      error,
      annexes,
      isSuccess,
      isLoading,
      isReportLoading,
      isReportSuccess,
      isDeleteModalActive,
      isDeleteSiteLoading,
      isDeleteSiteSuccess,
      newEquipIsLoading,
      missingInfos,
      toggleMissingReportInfos,
      screenIsLoading,
      geoError,
      geolocation,
      isDownloadModalActive
    } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    console.log('site', site)

    const columns = ['equipment', 'result'].map(el => ({
      Header: capitalizeFirstWord(t(el)),
      accessor: el,
      minWidth: el === 'equipment' ? 200 : el === 'result' ? 120 : 60,
      Cell: row => {
        let value = row.value;
        if (el === 'result') {
          value = getEquipmentResult(row.original, site.method, site.is_lab_test);
        }
        return (
          <Link
            to={{
              pathname: `/equipments/${row.original.id}`
            }}
            className="w-full"
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: getResultColor(value),
              textAlign: 'center'
            }}
          >
            <div className="w-full p-2">{typeof value === 'number' ? `${(value/100).toFixed(2)} m` : value}</div>
          </Link>
        );
      }
    }));

    const breadcrumbs = [{ path: `/`, name: t('Home') }, { name: site.site }];
    const testsHaveBeenDone =
      site.equipments &&
      site.equipments.reduce(
        (acc, equipment) =>
          acc === true ||
          (equipment.zones &&
            equipment.zones.reduce(
              (acc, zone) =>
                acc === true || (zone.impacts && zone.impacts.length > 0),
              false
            )),
        false
      );
    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>
            {capitalizeFirstWord(t('mission'))}{' '}
            {capitalizeFirstWord(site.mission)} - Lugdosphère
          </title>
        </Helmet>
        <ErrorAlert error={error} />
        <ConfirmDelete
          isActive={isDeleteModalActive}
          title={site.site}
          onDelete={this.handleDeleteSite}
          isDeleteLoading={isDeleteSiteLoading}
          isDeleteSuccess={isDeleteSiteSuccess}
          toggleModal={() => this.setState({ isDeleteModalActive: false })}
        />
        {isDownloadModalActive && (
          <Modal
            isActive={isDownloadModalActive}
            onToggle={() => this.setState({ isDownloadModalActive: false })}
          >
            <DownloadDataForm
              onToggle={() => this.setState({ isDownloadModalActive: false })}
            />
          </Modal>
        )}
        {toggleMissingReportInfos && (
          <div className="modal fixed z-50 pin overflow-auto flex items-center p-8 justify-center">
            <div
              className="fixed pin overflow-auto bg-primary opacity-50 cursor-pointer"
              onClick={() => this.setState({ toggleMissingReportInfos: false })}
            />
            <div className="animated fadeInUp max-w-md relative pin-b pin-x align-top m-auto justify-end justify-center px-6 md:px-12 py-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
              <div className="text-center mb-12 text-4xl text-red-light font-bold">
                <p>{`${capitalizeFirstWord(t('missing infos'))} : `}</p>
                <ul className="list-reset mt-6 text-left text-xl font-normal text-black">
                  {missingInfos.map((infos, i) => (
                    <li key={i} className={`${i > 0 ? 'mt-4' : ''}`}>
                      <span className="text-red-light font-bold">{`${t(
                        infos[0]
                      )} : `}</span>
                      {infos[1] && infos[1].join(', ')}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-baseline -mx-3 justify-end">
                <div className="px-3">
                  <button
                    className="inline-block align-baseline font-bold text-sm text-grey-darker hover:text-black capitalize"
                    onClick={() =>
                      this.setState({ toggleMissingReportInfos: false })
                    }
                  >
                    {t('Go back')}
                  </button>
                </div>
                <div className="px-3">
                  <button
                    className={`bg-red-light hover:bg-red text-white font-bold py-4 px-6 md:px-16 tracking-wide mb-3 rounded focus:outline-none focus:shadow-outline uppercase`}
                    type="button"
                    onClick={event => {
                      event.preventDefault();
                      openInNewTab(`${this.props.location.pathname}/report`);
                    }}
                  >
                    {t('Still Generate Report')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-full max-w-md mx-auto my-8">
          <Header
            title={site.site}
            subtitle={t('site to test')}
            method={site.method}
            isLabTest={site.is_lab_test}
            breadcrumbs={breadcrumbs}
          />
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="table">{t('equipments tested')}</Label>
              <ReactTable
                data={site.equipments}
                columns={columns}
                minRows={site.equipments.length === 0 ? 3 : 1}
                previousText={t('previous')}
                nextText={t('next')}
                loadingText={t('loading')}
                noDataText={t('no data found')}
                pageText={t('page')}
                ofText={t('ofText')}
                rowsText={t('rows')}
                defaultPageSize={10}
                showPagination={site.equipments.length > 10 ? true : false}
                className="-striped -highlight"
                getTrProps={(state, rowInfo, instance) =>
                  rowInfo && rowInfo.original.deleted_at
                    ? {
                        style: {
                          background: '#fff9c2'
                        }
                      }
                    : {}
                }
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-8">
            <div className="w-full px-3">
              <Label>{t(site.is_lab_test ? 'set up picture' : 'picture of the site')}</Label>
              <UploadImage
                pictures={sitePicture}
                onDrop={this.onDrop}
                processURL={`/pictures?type=site&id=${
                  this.props.location.pathname.split('sites/')[1]
                }`}
                picturesURL={`/uploads/sites/${
                  this.props.location.pathname.split('sites/')[1]
                }/`}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="address">{t('address')}</Label>
              <div className={`flex mb-2`}>
                <input
                  id="address"
                  className={`flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border border-grey-lighter focus:border-primary-lighter`}
                  type="text"
                  onChange={ev => this.handleChangeField('address', ev)}
                  value={address || ''}
                />
                {window.location.protocol.startsWith('https') &&
                  <Geolocation
                  lazy={!!geolocation}
                  enableHighAccuracy={true}
                  onSuccess={position => this.updateGeolocation(position)}
                  onError={error => this.setState({ geoError: error.message })}
                  render={({
                      fetchingPosition,
                      // position: { coords: { latitude, longitude } = {} } = {},
                      error,
                      getCurrentPosition
                    }) => (
                      <button
                        className={`${
                          fetchingPosition ? 'spinner ' : ''
                        }ml-2 flex bg-${
                          error ? 'red' : geolocation ? 'green' : 'primary'
                        }-light hover:bg-${
                          error ? 'red' : geolocation ? 'green' : 'primary'
                        } py-3 px-4 rounded focus:outline-none`}
                        onClick={getCurrentPosition}
                        type="button"
                        >
                        <div
                          className={`${
                            fetchingPosition ? 'invisible ' : ''
                          }text-center self-center`}
                        >
                          <FontAwesomeIcon
                            icon="map-marked-alt"
                            size="lg"
                            color="#fff"
                            />
                        </div>
                      </button>
                    )}
                  />
                }
              </div>
              {geoError && (
                <p className="mt-2 text-right text-red italic text-xs">
                  {geoError}
                </p>
              )}
            </div>
          </div>
          <div className="mt-4 mb-16">
            <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
              <span className="border-b-6 border-grey pr-16">
                {t('annexes')}
              </span>
            </div>
            <p className="text-grey text-xs mt-4 italic">
              {t('This data will appear on the last page of the report')}
            </p>
            {annexes.length > 0 &&
              annexes.map(
                ({ id, title, picture, isLoading, isUploaded }, index) => (
                  <div
                    key={id}
                    className={`${index > 0 ? 'mt-8' : 'mt-6'} -mx-2`}
                  >
                    <div className="px-2">
                      <div className="flex justify-between mb-2 font-bold text-xs">
                        <label className="text-grey-darker">
                          {`${t('Annex')} #${index + 1}`}
                        </label>
                        {isUploaded && (
                          <span
                            className="text-red-light hover:text-red cursor-pointer"
                            onClick={() => this.handleDeleteAnnex(id)}
                          >
                            {t('Delete')}
                          </span>
                        )}
                      </div>
                      {isUploaded ? (
                        <div className={`flex mb-2`}>
                          <div
                            className={`flex-1 appearance-none block w-full bg-grey-light text-grey-darker rounded py-3 px-4 leading-tight border border-grey-light`}
                          >
                            {title || ''}
                          </div>
                        </div>
                      ) : (
                        <input
                          className={`mt-2 flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
                            typeof value === 'number' ? 'text-right' : ''
                          } border border-grey-lighter focus:border-primary-lighter`}
                          type="text"
                          onChange={ev =>
                            this.handleChangeAnnex(id, 'title', ev)
                          }
                          value={title || ''}
                          placeholder={t('Granulometry')}
                        />
                      )}
                    </div>
                    <div className="mt-2 px-2">
                      {!isUploaded && (
                        <button
                          className={`${isLoading ? 'spinner ' : ''}bg-${
                            !title || title.length === 0
                              ? 'grey'
                              : 'primary-lighter'
                          } ${
                            !title || title.length === 0
                              ? ''
                              : 'hover:bg-primary-light'
                          } text-white font-bold py-4 px-12 tracking-wide rounded focus:outline-none focus:shadow-outline uppercase`}
                          type="button"
                          onClick={this.createAnnex}
                          disabled={!title || title.length === 0}
                        >
                          {t('Add Picture')}
                        </button>
                      )}
                      {isUploaded && (
                        <UploadImage
                          pictures={picture}
                          onDrop={(error, file) => this.onDrop(error, file, id)}
                          processURL={`/pictures?type=annex&id=${id}`}
                          picturesURL={`/uploads/annexes/${id}/`}
                        />
                      )}
                    </div>
                  </div>
                )
              )}
            {annexes &&
              annexes.length > 0 &&
              annexes[annexes.length - 1].isUploaded &&
              annexes.length < 4 && (
                <div className="flex mt-4">
                  <div className="w-full md:w-auto pr-2">
                    <button
                      className={`flex bg-grey hover:bg-grey-dark text-white font-bold py-4 pl-8 pr-10 tracking-wide mb-3 rounded focus:outline-none uppercase w-full justify-center md:justify-start`}
                      type="button"
                      onClick={this.handleAddAnnex}
                    >
                      <div className="text-center self-center">
                        <FontAwesomeIcon icon="plus" size="1x" color="#fff" />
                      </div>
                      <div className="ml-4 self-center">{t('New row')}</div>
                    </button>
                  </div>
                </div>
              )}
          </div>
          <BottomActions
            primaryAction={this.handleUpdate}
            primaryTxt={t('Update Site')}
            primarySuccessTxt={t('Updated')}
            serverErr={error}
            secondaryAction={() => this.setState({ isDeleteModalActive: true })}
            secondaryTxt={t('Delete Site')}
            isSecondaryNegative={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
          {testsHaveBeenDone && (
            <>
              <div className="mt-8 mb-10 mx-2 border border-grey" />
              <BottomActions
                primaryAction={this.generateReport}
                primaryTxt={t('Generate Report')}
                primarySuccessTxt={t('Report Generated')}
                serverErr={error}
                secondaryAction={() =>
                  this.setState({ isDownloadModalActive: true })
                }
                secondaryTxt={`${site.site}.csv`}
                isSecondaryNegative={false}
                isLoading={isReportLoading}
                isSuccess={isReportSuccess}
              />
            </>
          )}
        </div>
      </Container>
    );
  }

  createAnnex = event => {
    event.preventDefault();
    const token = localStorage.getItem('x-auth');
    const { annexes } = this.state;
    const { title, id } = annexes[annexes.length - 1];
    this.setState(prevState => ({
      annexes: prevState.annexes.map(annex =>
        annex.id === id
          ? {
              ...annex,
              isLoading: true
            }
          : annex
      )
    }));
    return axios
      .post(window.location.protocol + '//' + window.location.hostname + `/api/sites/${
          this.props.location.pathname.split('sites/')[1]
        }/annexes`,
        {
          title
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState(prevState => ({
          annexes: prevState.annexes.map(annex =>
            annex.id === id
              ? {
                  ...annex,
                  id: res.data.id,
                  isLoading: false,
                  isUploaded: true
                }
              : annex
          ),
          error: null
        }));
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          error: JSON.stringify(err.response)
        });
      });
  };

  handleAddAnnex = () => {
    this.setState(prevState => ({
      annexes: [
        ...prevState.annexes,
        {
          id: -42,
          title: '',
          picture: []
        }
      ]
    }));
  };

  handleChangeAnnex = (id, field, ev) => {
    const value = ev.target.value;
    this.setState(prevState => ({
      annexes: prevState.annexes.map(item =>
        item.id === id
          ? {
              ...item,
              [field]: value
            }
          : item
      )
    }));
  };

  handleDeleteAnnex = id => {
    const token = localStorage.getItem('x-auth');
    axios
      .delete(window.location.protocol + '//' + window.location.hostname + `/api/annexes/${id}`, {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        this.setState(prevState => ({
          annexes:
            prevState.annexes.length > 1
              ? prevState.annexes.filter(item => item.id !== id)
              : [{ id: prevState.annexes[0].id, title: '', picture: [] }],
          error: null
        }));
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          error: JSON.stringify(err.response)
        });
      });
  };

  generateReport = async event => {
    try {
      event.preventDefault();
      const { t } = this.props;
      const { site } = this.state;
      const token = localStorage.getItem('x-auth');
      const user = await axios(window.location.protocol + '//' + window.location.hostname + `/api/me`, {
        headers: {
          Authorization: token
        }
      });
      const report = await axios(window.location.protocol + '//' + window.location.hostname + `/api/report`, {
        headers: {
          Authorization: token
        }
      });
      let missingInfos = [];
      const del = ['deleted_at', 'deletedAt'];
      missingInfos.push([
        `${capitalizeFirstWord(t('user'))}`,
        Object.entries(user.data)
          .filter(item => !['is_admin'].includes(item[0]))
          .reduce((acc, curr) => (!curr[1] ? [...acc, curr[0]] : acc), [])
      ]);
      missingInfos.push([
        `${capitalizeFirstWord(t('report'))}`,
        Object.entries(report.data).reduce(
          (acc, curr) => (!curr[1] ? [...acc, curr[0]] : acc),
          []
        )
      ]);
      missingInfos.push([
        `${capitalizeFirstWord(t('site'))} "${site.site}"`,
        Object.entries(site)
          .filter(item => ![...del, 'is_lab_test'].includes(item[0]))
          .reduce((acc, curr) => (!curr[1] ? [...acc, curr[0]] : acc), [])
      ]);
      const equipmentFilters = site.is_lab_test
        ? [
            'installed_at',
            'manufacturer',
            'ref',
            'ffh_max',
            'floor_condition',
            'floor_installed_at',
            'floor_manufacturer',
            'floor_kind',
            'floor_ref',
            'floor_substrat'
          ]
        : ['specimen_data'];
      site.equipments.forEach(equipment => {
        missingInfos.push([
          `${capitalizeFirstWord(t('equipment'))} "${equipment.equipment}"`,
          Object.entries(equipment)
            .filter(item => ![...del, ...equipmentFilters].includes(item[0]))
            .reduce((acc, curr) => (!curr[1] ? [...acc, curr[0]] : acc), [])
        ]);
        equipment.zones.forEach(zone => {
          let toFilter = [...del, 'hygro', 'temp', 'comments']
          if(site.is_lab_test){
            toFilter.push('ffh')
          }
          else {
            toFilter.push(...['specimen_temp', 'specimen_hygro'])
          }
          missingInfos.push([
            `${capitalizeFirstWord(t('zone'))} "${zone.zone}"`,
            Object.entries(zone)
              .filter(
                item => !toFilter.includes(item[0])
              )
              .reduce((acc, curr) => (!curr[1] ? [...acc, curr[0]] : acc), [])
          ]);
        });
      });
      if (missingInfos.filter(item => item[1].length > 0).length > 0) {
        return this.setState(prevState => ({
          toggleMissingReportInfos: !prevState.toggleMissingReportInfos,
          missingInfos: missingInfos.filter(item => item[1].length > 0)
        }));
      }
      openInNewTab(`${this.props.location.pathname}/report`);
    } catch (err) {
      console.error(err);
      this.setState({
        error: JSON.stringify(err.response)
      });
    }
  };

  updateGeolocation = ({ coords: { latitude, longitude, accuracy } }) => {
    const token = localStorage.getItem('x-auth');
    const geolocation = `${latitude};${longitude};${accuracy}`;
    return axios
      .patch(window.location.protocol + '//' + window.location.hostname + `/api/sites/${
          this.props.location.pathname.split('sites/')[1]
        }`,
        {
          geolocation
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState({
          geolocation,
          error: null
        });
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          error: JSON.stringify(err.response)
        });
      });
  };

  handleSubmit = () => {
    const { newEquipment } = this.state;
    if (newEquipment) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        newEquipIsLoading: true
      });
      return axios
        .post(window.location.protocol + '//' + window.location.hostname + `/api/sites/${
            this.props.location.pathname.split('sites/')[1]
          }/equipments`,
          {
            equipment: newEquipment
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            newEquipIsLoading: false,
            error: null
          });
          this.props.history.push(`/equipments/${res.data.id}`);
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            newEquipIsLoading: false,
            error: JSON.stringify(err.response)
          });
        });
    }
  };

  handleUpdate = () => {
    const { address } = this.state;
    if (address) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api/sites/${
            this.props.location.pathname.split('sites/')[1]
          }`,
          {
            address
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            isLoading: false,
            isSuccess: true,
            error: null
          });
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            isLoading: false,
            error: JSON.stringify(err.response)
          });
        });
    }
  };

  debouncedSave = debounce(() => {
    this.handleUpdate()
  }, 2000);

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: { ...prevState.formErrors, [key]: null },
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
    this.debouncedSave();
  };

  handleDeleteSite = () => {
    const token = localStorage.getItem('x-auth');
    this.setState({ isDeleteSiteLoading: true });
    axios
      .delete(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState({
          isDeleteSiteLoading: false,
          isDeleteSiteSuccess: true,
          error: null
        });
        this.props.history.push('/');
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          error: JSON.stringify(err.response)
        });
      });
  };

  onDrop = (error, file, id) => {
    const { sitePicture } = this.state;
    if (file) {
      if (id) {
        // const annex = find(annexes, ['id', id]);
        this.setState(prevState => ({
          annexes: prevState.annexes.map(annex =>
            annex.id === id
              ? {
                  ...annex,
                  picture: file
                    ? [
                        {
                          source: file.file,
                          options: {
                            type: 'local'
                          }
                        }
                      ]
                    : []
                }
              : annex
          )
        }));
        // }
      } else if (
        sitePicture && sitePicture[0] &&
        sitePicture[0].source &&
        sitePicture[0].source.name !== file.file.name
      ) {
        this.setState({
          sitePicture: file
            ? [
                {
                  source: file.file,
                  options: {
                    type: 'local'
                  }
                }
              ]
            : []
        });
      }
    }
  };
}

export default withTranslation()(Site);