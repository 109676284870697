import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import logo from '../text-logo.svg';  
class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langs: [],
      toggleMenu: false,
      toggleLang: false,
      togglePowerModal: false,
      loading: true,
      isSwitchOffLoading: false,
      isOff: false,
      error: null,
      hasNewUpdate: false,
      updating: false,
      updated: false
    };
  }

  async componentDidMount() {
    try {
      if (
        this.props.location.pathname !== '/login' &&
        !this.props.location.pathname.match(/(\/sites\/\d+\/report)/i)
      ) {
        this.setState({ loading: true });
        const translationsRes = await axios(window.location.protocol + '//' + window.location.hostname + `/api/translations/unique`);
        this.setState({
          loading: false,
          langs: translationsRes.data
        });
      } else this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }

    // Asynchronously fetch sites
    const token = localStorage.getItem('x-auth');
    axios(window.location.protocol + '//' + window.location.hostname + `/api/sites/fetch`, {
      headers: {
        Authorization: token
      }
    }).then(res => {
      console.log("Sites fetching response:", res.statusText)
    }).catch(err => {
      console.log('Sites fetching error:', err)

    });
    // END Asynchronously fetch sites

  }
  render() {
    const { t, location, metrics } = this.props;
    const {
      langs,
      toggleMenu,
      toggleLang,
      loading    } = this.state;
    if (
      loading ||
      location.pathname === '/login' ||
      location.pathname.match(/(\/sites\/\w+\/report)/i)
    )
      return null;
    return (
      <nav
        id="header"
        className="fixed w-full z-30 top-0 text-white bg-white shadow sm:shadow-none"
      >
        <div className="w-full mx-auto flex flex-wrap items-center justify-between mt-0 py-2 px-8 max-w-xl">
          <div className="py-4 block">
            <Link
              className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl w-40 sm:w-auto"
              to="/"
            >
              <img src={logo} className="block mx-auto" alt="logo" />
            </Link>
            <div className='font-bold text-center text-black'>online V 2.0</div>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={this.toggleMenu}
              className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div
            className={`${
              toggleMenu ? '' : 'hidden'
            } w-full lg:w-auto lg:block lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20 h-screen lg:h-auto`}
          >
            <ul className="list-reset flex flex-col lg:flex-row justify-start lg:justify-end flex-1 items-center h-full text-2xl lg:text-base font-bold lg:font-normal">
              <li
                className="lg:mr-3 w-full lg:w-auto text-center"
                onClick={this.toggleMenu}
              >
                <Link
                  className={`${
                    location.pathname === '/' ? 'text-primary' : 'text-black'
                  } inline-block w-full lg:w-auto py-6 lg:py-2 px-4 no-underline capitalize hover:no-underline hover:text-primary hover:bg-primary-lightest lg:hover:bg-white rounded`}
                  to="/"
                >
                  {t('home')}
                </Link>
              </li>
              <li
                className="mt-4 lg:mt-0 lg:mr-3 w-full lg:w-auto text-center"
                onClick={this.toggleMenu}
              >
                <Link
                  className={`${
                    location.pathname.includes('settings')
                      ? 'text-primary'
                      : 'text-black'
                  } inline-block w-full lg:w-auto py-6 lg:py-2 px-4 no-underline capitalize hover:no-underline hover:text-primary hover:bg-primary-lightest lg:hover:bg-white rounded`}
                  to="/settings"
                >
                  {t('settings')}
                </Link>
              </li>
              <li className="mt-4 lg:mt-0">
                <div
                  className="px-4 py-6 lg:py-2 group"
                  onClick={() =>
                    this.setState(prevState => ({
                      toggleLang: !prevState.toggleLang
                    }))
                  }
                >
                  <div className="flex items-center cursor-pointer">
                    <FontAwesomeIcon
                      icon="globe-europe"
                      size="lg"
                      color="#003280"
                    />
                  </div>
                  <div
                    className={`items-center absolute border rounded-lg p-1 bg-white p-2 ${
                      toggleLang ? 'visible' : 'invisible'
                    } group-hover:visible`}
                  >
                    {langs.map(lang => (
                      <button
                        type="button"
                        className="px-4 py-2 block text-black hover:bg-grey-lighter"
                        onClick={() => this.handleLangchange(lang)}
                        key={lang}
                      >
                        {lang}
                      </button>
                    ))}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      toggleMenu: !prevState.toggleMenu
    }));
  };

  handleLangchange = lang =>
    i18n
      .changeLanguage(lang)
      .then(() => {
        localStorage.setItem('lang', lang);
        this.setState(prevState => ({
          toggleLang: !prevState.toggleLang
        }));
      })
      .catch(err => console.log('t err', err));

  
  handleUpdate = () => {
    this.setState({ updating: true })
    return axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/update/perform')
      .then(() => {
        this.setState({ updating: false, updated: true }, () => {
          axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/docker-compose/restart')
            .then( () => {
              this.setState({ hasNewUpdate: false })
            })
        })
      })
      .catch(err => {
        console.log('error beta updating', err);
        this.setState({
          updating: false,
          error: JSON.stringify(err && err.response)
        });
      })
  }
}

export default withRouter(withTranslation()(NavBar));
