import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/fr';

import { capitalizeFirstWord, capitalizeFirstLetter } from '../../utils/helpers';

moment.locale('fr');

const openInNewTab = url => {
  const win = window.open(url, '_blank');
  win.focus();
};

const SitesTable = ({ sites, handleClone, t }) => {
  const columns = [
    'geolocation',
    'created_at',
    'mission',
    'site',
    'client',
    'method'
  ].map(el => ({
    Header: capitalizeFirstWord(
      t(el === 'created_at' ? 'date' : el === 'geolocation' ? 'GPS' : el)
    ),
    accessor: el,
    filterable: el !== 'geolocation',
    minWidth: el === 'method' ? 70 : el === 'geolocation' ? 60 : 150,
    Cell: row => {
      let value = row.value;
      if (el === 'geolocation') {
        return (
          <button
            className="w-full cursor-pointer text-center"
            type="button"
            onClick={event => {
              event.preventDefault();
              if (!!row.value) {
                openInNewTab(row.value);
              }
            }}
          >
            <div className="w-full p-2">
              <FontAwesomeIcon
                icon="map-marked-alt"
                size="lg"
                color={!!row.value ? '#51d88a' : '#ef5753'}
              />
            </div>
          </button>
        );
      }
      if (el === 'created_at') {
        const minus24hours = moment().subtract(24, 'hours');
        value = moment(value).isSameOrAfter(minus24hours)
          ? capitalizeFirstWord(moment(value).fromNow())
          : moment(value).format('DD/MM/YYYY');
      }

      if (el === 'method'){
        if(value === 1 && row.original.is_lab_test){
          value = '1 Lab'
        }
      }
      return (
        <Link
          to={{
            pathname: `/sites/${row.original.id}`
          }}
          className="w-full text-center cursor-pointer"
          style={{
            textDecoration: 'none'
          }}
        >
          <div className="w-full p-2">{value}</div>
        </Link>
      );
    }
  }));

  columns.push({
    Header: capitalizeFirstLetter(t('report')),
    accessor: 'report',
    minWidth: 50,
    Cell: row => (
      <button
        className="w-full cursor-pointer text-center"
        type="button"
        onClick={event => {
          event.preventDefault();
          if (!!row.value) {
            openInNewTab(row.value);
          }
        }}
      >
        <div className="w-full p-2">
          <FontAwesomeIcon icon="download" size="lg" color="#8795a1" />
        </div>
      </button>
    ),
    sortable: false,
    filterable: false
  });

  columns.push({
    Header: capitalizeFirstLetter(t('duplicate')),
    accessor: 'clone',
    minWidth: 50,
    Cell: row => (
      <button
        className="w-full cursor-pointer text-center"
        type="button"
        onClick={e => handleClone(e, row.original)}
      >
        <div className="w-full p-2">
          <FontAwesomeIcon icon="clone" size="lg" color="#80b2ff" />
        </div>
      </button>
    ),
    sortable: false,
    filterable: false
  });

  const isAdmin = localStorage.getItem('is-admin') || false;

  return (
    <ReactTable
      data={isAdmin === 'true' ? sites : sites.filter(site => !site.deleted_at)}
      columns={columns}
      minRows={sites.length === 0 ? 5 : 1}
      previousText={t('previous')}
      nextText={t('next')}
      loadingText={t('loading')}
      noDataText={t('no data found')}
      pageText={t('page')}
      ofText={t('ofText')}
      rowsText={t('rows')}
      defaultPageSize={20}
      showPagination={sites.length > 20 ? true : false}
      className="-striped -highlight"
      getTrProps={(state, rowInfo, instance) =>
        rowInfo && rowInfo.original.deleted_at
          ? {
              style: {
                background: '#fff9c2'
              }
            }
          : {}
      }
      defaultSorted={[
        {
          id: 'created_at',
          desc: true
        }
      ]}
      filterable
      defaultFilterMethod={(filter, row) =>
        String(row[filter.id])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
      }
      // TheadComponent={props => {
      //   console.log(props);
      //   return null;
      // }}
    />
  );
};

export default withTranslation()(SitesTable);
