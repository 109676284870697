import React from 'react';
import { withTranslation } from 'react-i18next';

const ConfirmDelete = ({
  t,
  isActive,
  title,
  onDelete,
  isDeleteLoading,
  isDeleteSuccess,
  toggleModal
}) => (
  <div
    className={`modal fixed z-50 pin overflow-auto flex items-center p-8 justify-center${
      isActive ? ' visible' : ' invisible'
    }`}
  >
    <div
      className="fixed pin overflow-auto bg-primary opacity-50 cursor-pointer"
      onClick={toggleModal}
    />
    <div className="animated fadeInUp max-w-sm relative pin-b pin-x align-top m-auto justify-end justify-center p-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
      <div className="text-center mb-10 text-2xl text-red-dark">
        {t('Are you sure you want to delete')}{' '}
        <p className="font-bold">"{title}" ?</p>
      </div>
      <div className="flex items-baseline -mx-3 justify-center">
        <div className="px-3">
          <button
            className="inline-block align-baseline font-bold text-sm text-grey-darker hover:text-black capitalize"
            onClick={toggleModal}
          >
            {t('No, Go back')}
          </button>
        </div>
        <div className="px-3">
          <button
            className={`${isDeleteLoading ? 'spinner ' : ''}bg-${
              isDeleteSuccess ? 'red-light' : 'red'
            } hover:bg-${
              isDeleteSuccess ? 'red-light' : 'red'
            }-dark text-white font-bold py-4 px-16 tracking-wide mb-3 rounded focus:outline-none focus:shadow-outline uppercase`}
            type="button"
            onClick={onDelete}
            disabled={isDeleteLoading || isDeleteSuccess}
          >
            {isDeleteSuccess ? t('deleted') : t('Yes, Delete')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation()(ConfirmDelete);
