import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { get, indexOf } from 'lodash';

import { Container } from '../../components';
import {
  Label,
  DisabledInput,
  Header,
  ErrorAlert,
  Spinner
} from '../../components/commons';
import ImpactChart from './ImpactChart';
import ImpactsTable from './ImpactsTable';
import { capitalizeFirstWord } from '../../utils/helpers';
import { getPolyResults } from '../../utils/maths';

class Impact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: null,
      mission: null,
      site: null,
      equipment: null,
      site_id: null,
      equipment_id: null,
      zone: null,
      ffh: null,
      id: null,
      warnings: null,
      errors: null,
      isReleaseLoading: false,
      impacts: [],
      error: null,
      screenIsLoading: true,
      releaseError: null
    };
  }

  update = () => {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api${
          this.props.location.pathname.split('/impacts')[0]
        }`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        console.log('res', res)
        this.setState({
          ...res.data,
          ffh: res.data.ffh / 100,
          impacts: res.data.impacts.map(impact => {
            const { hic, gmax, height, ...rest } = impact
            impact.HIC = hic
            const result = res.data.method === 2 
              ? getPolyResults([impact], res.data.ffh, res.data.method).result 
              : (hic > 1000 || gmax > 200) 
                ? 'Not Adequate' 
                : 'Adequate'
            return {...rest,
              gmax,
              HIC: hic,
              height: height / 100,
              result
            }
          }),
          screenIsLoading: false
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: JSON.stringify(err.response),
          screenIsLoading: false
        });
      });
    }
  }

  componentDidMount() {
    this.update()
  }

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.update()
    }
  };

  render() {
    const { t } = this.props;
    const {
      method,
      site,
      equipment,
      site_id,
      equipment_id,
      zone,
      ffh,
      id,
      impacts,
      error,
      screenIsLoading
    } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    const impactId = this.props.location.pathname.split('impacts/')[1];
    const impact = impacts.find(({ id }) => id === impactId);
    const impactIndex = indexOf(impacts, impact) + 1;
    const breadcrumbs = [
      { path: `/`, name: t('Home') },
      { path: `/sites/${site_id}`, name: site },
      {
        path: `/equipments/${equipment_id}`,
        name: equipment
      },
      {
        path: `/equipments/${equipment_id}/zones/${id}`,
        name: zone
      },
      { name: `${t('impact')} #${impactIndex}` }
    ];
    let heightTarget = impact ? parseFloat(impact.height) + 0.2 : null;
    if (method === 2) heightTarget = parseFloat(ffh);
    const acquisitionSpeed = 20000;
    const msInSecond = 1000;

    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>{capitalizeFirstWord(equipment)} - Lugdosphère</title>
        </Helmet>
        <ErrorAlert error={error} />
        <div className="w-full max-w-md mx-auto my-8">
          <Header
            title={`${t('impact')} #${impactIndex}`}
            subtitle={t('impact results')}
            method={method}
            isLabTest={this.state.is_lab_test}
            breadcrumbs={breadcrumbs}
          />
          <div className="relative w-full my-4 mb-8">
            {impact && <ImpactChart points={impact.points} />}
          </div>
          <div className="flex mx-auto mb-8 text-center md:text-left">
            <div className="flex flex-col justify-end w-full sm:w-1/4 px-3">
              <DisabledInput
                fieldName="height"
                label={t('height')}
                value={impact ? impact.height : '-'}
                unit="m"
              />
            </div>
            <div className="flex flex-col justify-end w-full sm:w-1/4 px-3">
              <DisabledInput
                fieldName="gmax"
                label={t('Gmax')}
                value={impact ? impact.gmax : '-'}
                warning={
                  method === 2 &&
                  impact &&
                  impact.gmax >= 195 &&
                  impact.gmax <= 205
                }
              />
            </div>
            <div className="flex flex-col justify-end w-full sm:w-1/4 px-3">
              <DisabledInput
                fieldName="HIC"
                label={t('HIC')}
                value={impact ? impact.HIC : '-'}
                warning={
                  method === 2 &&
                  impact &&
                  impact.HIC >= 950 &&
                  impact.HIC <= 1050
                }
              />
            </div>
            <div className="flex flex-col justify-end w-full sm:w-1/4 px-3">
              <DisabledInput
                fieldName="T2-T1"
                label={t('T2 - T1')}
                value={impact ? Math.round(JSON.parse(impact.points).length / acquisitionSpeed * msInSecond) : '-'}
                unit="ms"
              />
            </div>
          </div>
          {method === 2 &&
            impact &&
            ((impact.HIC >= 950 && impact.HIC <= 1050 && impact.gmax <= 205) ||
              (impact.gmax >= 195 && impact.gmax <= 205 && impact.HIC <= 1050)) && (
              <div className="flex rounded bg-orange-lighter text-sm italic text-orange-darker p-4 mb-8">
                {(impact.HIC >= 950 && impact.HIC <= 1050) ? t('method2.advice.hic') : t('method2.advice.gmax')}
              </div>
            )}
          <div className="flex -mx-3 mb-12">
            <div className="w-full px-3">
              <Label htmlFor="table">{t('impacts table')}</Label>
              <ImpactsTable
                impacts={impacts}
                onDeleteImpact={this.handleDeleteImpact}
              />
            </div>
          </div>
          <div className="flex items-baseline -mx-3 justify-end mb-12">
            <div className="px-3">
              <Link
                to={`/equipments/${equipment_id}${
                  this.props.location.pathname.split('/impacts/')[0]
                }`}
                className="hover:no-underline bg-grey-darkest hover:bg-black text-white font-bold py-4 px-16 tracking-wide mb-3 rounded focus:outline-none focus:shadow-outline uppercase"
              >
                {t('finish')}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    );
  }
  
  handleDeleteImpact = (e, row) => {
    if (row && row._original) {
      const token = localStorage.getItem('x-auth');
      axios
        .delete(window.location.protocol + '//' + window.location.hostname + `/api/zones/${this.state.id}/impacts/${row._original.id}`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState(({ impacts }) => ({
            impacts: impacts.filter(({ id }) => id !== row._original.id),
            error: null
          }));
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            error: JSON.stringify(err.response)
          });
        });
    }
  };

  handleNewImpact = (impact, err) => {
    const token = localStorage.getItem('x-auth');
    console.log('firmware-new_record Impact', impact, err)
    if(err){
      console.log('error on new record', err)
      this.setState({
        isReleaseLoading: false,
        releaseError: err
      })
    }
    else {
      axios
      .post(window.location.protocol + '//' + window.location.hostname + `/api/zones/${this.state.id}/impacts`,
        {impact},
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState(prevState => ({
          impacts: [...prevState.impacts, res.data],
          isReleaseLoading: false,
          error: null,
          releaseError: null
        }), () => {
          this.props.history.push(
            `/zones/${this.state.id}/impacts/${res.data.id}`
          );
        });
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          isReleaseLoading: false,
          error:
            get(err, 'response.status') === 408
              ? null
              : JSON.stringify(err.response),
          releaseError: get(err, 'response.statusText')
        });
      });
    };
  }

  handleReleaseHIC = () => {
    this.setState({
      isReleaseLoading: true
    });
  }

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState({
      [key]: value,
      error: null
    });
  };
}

export default withTranslation()(Impact);
