import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { findParabolicAandC } from '../../../../utils/goldenSectionSearch'

import { capitalizeFirstWord } from '../../../../utils/helpers';
import { getCFH, getQuadraRes } from '../../../../utils/maths';

moment.locale('fr');

const EquipmentSummary = ({ equipment, t }) => {
  let zones = [];

  if (equipment.zones && equipment.zones.length > 0) {
    zones = equipment.zones
      .map(({ ffh, impacts, ...rest }, i) => {
        if (!impacts || impacts.length === 0) {
          return {
            ...rest
          };
        }

        const HICpoints = impacts.map(({ height, hic }) => [height / 100, hic])
        const gMaxPoints = impacts.map(({ height, gmax }) => [height / 100, gmax])
        const HICResult = findParabolicAandC(HICpoints);
        const GmaxResult = findParabolicAandC(gMaxPoints);
        const [, CFH] = getCFH(HICResult, GmaxResult);
        const HICCFH = getQuadraRes(HICResult, 1000);
        const GmaxCFH = getQuadraRes(GmaxResult, 200);
        
        return {
          ...rest,
          HIC_Theorical_CFH: HICCFH,
          GMAX_theorical_CFH: GmaxCFH,
          CFH_to_take_into_account: CFH
        }
      })
  }

  const columns = ['zone', 'HIC_Theorical_CFH', 'GMAX_theorical_CFH', 'CFH_to_take_into_account'].map(el => ({
    Header: capitalizeFirstWord(t(el).replace(/_/g, ' ')),
    accessor: el,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'inherit',
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-1">{['FFH', 'HIC_Theorical_CFH', 'GMAX_theorical_CFH', 'CFH_to_take_into_account'].includes(el) && row.value
                ? `${parseFloat(row.value).toFixed(2)} m`
                : row.value}</div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker">
      <div className="mt-10mm text-2xl">{t('Specimen results summary')}</div>
      <div className="mt-4mm text-xs">
        <ReactTable
          data={zones}
          columns={columns}
          minRows={1}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('no data found')}
          pageText={t('page')}
          ofText={t('ofText')}
          rowsText={t('rows')}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    </article>
  );
};

export default withTranslation()(EquipmentSummary);
