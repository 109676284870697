import React, { Component } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { ErrorAlert, Spinner } from '../../components/commons';
import Method1Site from './Method1Site';
import Method2Site from './Method2Site';
import Method1Lab from './Method1Lab';
import VersionModal from './VersionModal'
import '../../styles/paper.css';

moment.locale('fr');

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: null,
      error: null,
      screenIsLoading: true,
      isVersionModalOpen: true,
      isVersionUpdateLoading: false,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    try {
      const token = localStorage.getItem('x-auth');
      const path = this.props.location.pathname;
      document.body.style.backgroundColor = '#e0e0e0';
      if (token) {
        this.setState({ screenIsLoading: true });
        const user = await axios(window.location.protocol + '//' + window.location.hostname + `/api/me`, {
          headers: {
            Authorization: token
          }
        });
        const site = await axios(window.location.protocol + '//' + window.location.hostname + `/api${path.replace(
            '/report',
            ''
          )}?with=all`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const report = await axios(window.location.protocol + '//' + window.location.hostname + `/api/report`, {
          headers: {
            Authorization: token
          }
        });
        this.setState({
          user: user.data,
          site: site.data,
          report: {
            ref: `${t('Report Réf.')} ${site.data.mission.replace(
              ' ',
              '_'
            )}-${site.data.site.replace(' ', '_')}`,
            date: moment().format('DD/MM/YYYY'),
            version: site.data.report_version,
            ...report.data
          },
          error: null,
          screenIsLoading: false
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        error: JSON.stringify(err.response),
        screenIsLoading: false
      });
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = 'white';
  }

  render() {
    const { t } = this.props;
    const { user, site, report, error, screenIsLoading, isVersionModalOpen, isVersionUpdateLoading } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    return (
      <>
        <Helmet>
          <title>{t('Report Preview')} - Lugdosphère</title>
        </Helmet>
        <ErrorAlert error={error} />
        {isVersionModalOpen && <VersionModal lastReportVersion={site.report_version} onVersionUpdate={this.handleVersionUpdate} isLoading={isVersionUpdateLoading}/>}
        <div id="pdf" className="A4 text-grey-darkest bg-white">
          {site.method === 1 ? (
            site.is_lab_test ? (
              <Method1Lab
                report={report}
                user={user}
                site={site}
              />
            ) : (
              <Method1Site
                report={report}
                user={user}
                site={site}
              />
            )
          ) : (
            <Method2Site
              report={report}
              user={user}
              site={site}
            />
          )}
        </div>
        <div className="fixed pin-b pin-x z-10 text-center sm:mb-12 print:hidden">
          <button
            type="button"
            className="z-20 relative text-center bg-primary hover:bg-primary-dark text-white font-bold text-xl py-6 px-16 shadow-lg tracking-wide sm:rounded focus:outline-none focus:shadow-outline uppercase w-full sm:w-auto"
            onClick={() => window.print()}
          >
            {t('Download Report')}
          </button>
          <div className="z-10 absolute pin-x pin-b mx-auto py-16 -mb-8 bg-primary-light opacity-25 max-w-md rounded" />
        </div>
      </>
    );
  }

  handleVersionUpdate = (newReportVersion) => {
    if (newReportVersion) {
      if (newReportVersion === this.state.site.report_version) {
        this.setState({
          isVersionModalOpen: false
        });
      } else {
        const token = localStorage.getItem('x-auth');
          this.setState({
            isVersionUpdateLoading: true
          });
          return axios
            .patch(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname.split('/report')[0]}`,
              {
                report_version: newReportVersion,
              },
              {
                headers: {
                  Authorization: token
                }
              }
            )
            .then(res => {
              this.setState({
                isVersionUpdateLoading: false,
                isVersionModalOpen: false,
                report: {...this.state.report, version: newReportVersion},
                error: null
              });
            })
            .catch(err => {
              if (err && err.response) {
                console.error(err.response);
              } else console.error(err);
              this.setState({
                isVersionUpdateLoading: false,
                error: JSON.stringify(err.response)
              });
            });
      }
    }
  }
}

export default withTranslation()(Report);
