export function shallowEqual(objA, objB) {
    if (objA === objB) {
      return true;
    }
  
    if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
      return false;
    }
  
    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);
  
    if (keysA.length !== keysB.length) {
      return false;
    }
  
    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (var i = 0; i < keysA.length; i++) {
      if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }
  
    return true;
  }
  
// To use:
// shouldComponentUpdate (nextProps, nextState) {
//     return shallowCompare(this, nextProps, nextState) || null
// }
// NB return true if shallowCompare found difference else return null to skip rendering
export function shallowCompare(instance, nextProps, nextState) {
    return (
      !shallowEqual(instance.state, nextState)
    );
  }