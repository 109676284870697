import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { capitalizeFirstWord } from '../../../../utils/helpers';
import defaultImg from '../../commons/default.jpg';

import EquipmentTempHygro from './EquipmentTempHygro'

moment.locale('fr');

const Equipment = ({ equipment: equipment_data, t }) => {
  const equipment = {
    ...equipment_data,
    installed_at: equipment_data.installed_at
      ? moment(equipment_data.installed_at).format('DD/MM/YYYY')
      : null,
    floor_installed_at: equipment_data.floor_installed_at
      ? moment(equipment_data.floor_installed_at).format('DD/MM/YYYY')
      : null
  };

  return (
    <article className="px-12mm text-center text-primary-darker">
      <div className="text-2xl">{`${t('Tested specimen')} : ${equipment.equipment}`}</div>
      <div className="mt-6mm flex w-full flex-wrap justify-between text-left text-md px-6mm text-grey-darkest font-normal">
        {equipment.specimen_data &&
          JSON.parse(equipment.specimen_data).map(
            ({ id, title, value }) =>
              title &&
              value && (
                <div key={id} className="w-1/2 mt-2mm px-2mm text-sm">
                  {`${capitalizeFirstWord(t(title))}:`}
                  <span className="ml-2mm text-primary-dark">{value}</span>
                </div>
              )
          )}
      </div>
      <div className="mt-6mm flex">
        <div className="w-1/2">
          <p className="italic">{t('Picture of specimen')} :</p>
          <div className="mt-2mm overflow-hidden px-2mm">
            <img
              className="max-h-80mm object-contain border-2 border-primary-dark"
              src={
                equipment.picture
                  ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/equipments/${equipment.id}/${equipment.picture}`
                  : defaultImg
              }
              alt="equipment"
            />
          </div>
        </div>
        {/* <div className="w-1/2">
          <p className="italic">{t('Picture showing all the test positions')} :</p>
          <div className="mt-2mm overflow-hidden px-2mm">
            <img
              className="max-h-80mm object-contain border-2 border-primary-dark"
              src={
                equipment.picture2
                  ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/equipments/${equipment.id}/${equipment.picture2}`
                  : defaultImg
              }
              alt="equipment"
            />
          </div>
        </div> */}
      </div>
      <EquipmentTempHygro equipment={equipment} />
    </article>
  );
};

export default withTranslation()(Equipment);
