import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';

i18n
  .use(Fetch)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: window.location.protocol + '//' + window.location.hostname + '/api/locales/{{lng}}',
        // addPath: '/locales/add/{{lng}}'
        // parse: function(data) {
        //   console.log('data', JSON.parse(data));
        //   return JSON.parse(data);
        // }
      },
      lng: localStorage.getItem('lang') || 'EN',
      fallbackLng: 'EN',
      debug: false,
      saveMissing: false, // send not translated keys to endpoint

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      },

      react: {
        wait: true
      }
    },
    err => {
      // console.warn(localStorage.getItem('lang'));
      // console.warn(i18n.store.data);
      if (err) console.error('init error', err);
    }
  );

export default i18n;
