import React from 'react';
import Switch from 'react-switch';

import { Label } from '.';

export default ({
  fieldName,
  label,
  onChange, isChecked, isDisabled
}) => (
  <>
    <Label htmlFor={fieldName}>{label}</Label>
    <div className="mt-4">
                  <Switch
                    onChange={onChange}
                    checked={isChecked}
                    disabled={isDisabled || false}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id={fieldName}
                  />
                </div>
  </>
);
