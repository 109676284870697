import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { capitalizeFirstWord, getResultColor } from '../../../../utils/helpers';
import { getPolyResults } from '../../../../utils/maths';

moment.locale('fr');

const EquipmentSummary = ({ equipment, t, i }) => {
  let zones = [];

  if (equipment.zones && equipment.zones.length > 0) {
    zones = equipment.zones
      .map(({ ffh, impacts, ...rest }) => {
        impacts = impacts.filter(i => !i.deleted_at && !i.deletedAt)
        impacts = impacts.map( ({height, hic, ...rest}) => {
          return {
            ...rest,
            HIC: hic,
            height: height
          }
        })
        
        if (!ffh || !impacts || impacts.length === 0) {
          return {
            ...rest,
            FFH: ffh
          };
        }

        return {
          ...rest,
          FFH: ffh,
          ...getPolyResults(impacts, ffh, 2),
          fall_height: impacts[0].height
        };
      })
  }

  const columns = [
    'zone',
    'ground_thickness',
    'FFH',
    'fall_height',
    'Gmax',
    'HIC',
    'result'
  ].map(el => ({
    Header: () => (
      <span
        className=""
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        }}
      >
        {capitalizeFirstWord(t(el))}
      </span>
    ),
    accessor: el,
    minWidth:
      el === 'zone' || el === 'ground_thickness'
        ? 120
        : el === 'result'
        ? 100
        : 50,
    Cell: row => {
      return (
        <div
          className="w-full whitespace-normal"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: getResultColor(row.value),
            textAlign: typeof row.value === 'number' ? 'right' : 'center',
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
        >
          <div className="w-full p-1">
            {(['FFH', 'fall_height'].includes(el) && row.value)
              ? `${parseFloat(row.value / 100).toFixed(2)} m`
              : el === 'ground_thickness' 
                ? row.value && row.value !== 0 ? `${row.value} mm` : ''
                : row.value
              }
          </div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker">
      <div className="mt-10mm text-2xl">{t('Equipment results summary') + ` n°${i}`}</div>
      <div className="mt-4mm text-sm">
        <ReactTable
          data={zones}
          columns={columns}
          minRows={1}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('no data found')}
          pageText={t('page')}
          ofText={t('ofText')}
          rowsText={t('rows')}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    </article>
  );
};

export default withTranslation()(EquipmentSummary);
