const mean = (a) => {
    if (a.length) {
      return a.reduce((acc, cur) => acc+cur, 0)/a.length
    } else {
      return null
    }
  }

// Golden Section definition
const phi = (1 + Math.sqrt(5))/2
const resphi = 2 - phi

/*
Get the Sum of Squares of an array
*/
const sumsq = (a) => {
    return a.map(e => Math.pow(e, 2)).reduce((acc, cur) => acc+cur, 0)
}

/*
Get the Sum of Squares of differences between the real points and a fitting of the form y = ax^2 + 0*b + 0*c
*/
const fittingScore = (points, a, b=0, c=0) => {
    const f = (x, a, b, c) => {
        return a*Math.pow(x, 2) + b*x + c
    }
    return sumsq(points.map(
        ([ x, y ]) => y - f(x, a, b, c)
    ))
}

/*
Perform the Golden Section Search of a minimum of function `func` located somewhere between `a` and `b`
Start the search at point `c`
Recursively iterate until the search window (a - b) is less than `absolutePresicion`
*/
const goldenSectionSearch = (func, a, c, b, absolutePrecision=1e-2) => {
    if (Math.abs(a - b) < absolutePrecision) {
        return (a + b)/2
    }
    // Create a new possible center, in the area between c and b, pushed against c
    let d = c + resphi*(b - c)
    if (func(d) < func(c)) {
        return goldenSectionSearch(func, c, d, b, absolutePrecision)
    } else {
        return goldenSectionSearch(func, d, c, a, absolutePrecision)
    }
}

const findParabolicA = (points, c=0) => {
    var a
    if (points.length === 0) {
        return {
            equation: [NaN, NaN, NaN],
            points
        }
    } else if (points.length === 1) {
        let [ x, y ] = points[0]
        a = (y-c)/Math.pow(x, 2)
    } else {
        let As = points.map(([ x, y ]) => (y-c) / Math.pow(x, 2))
        let lowBorder = Math.min(...As)
        let highBorder = Math.max(...As)

        let middle = (highBorder - lowBorder) / 2
        
        const coreFunc = a => fittingScore(points, a, 0, c)
        a = goldenSectionSearch(coreFunc, lowBorder, middle, highBorder)
    }
    return {
        equation: [a, 0, 0],
        points
    }
}

/*
Search for a & c parameters of quadratic regression by firstly finding the optimal `c` and the the optimal `a` once the c is found.
Optimal c is found by taking b=0 and approximation of a, taking a > 0
*/
const findParabolicAandC = (points) => {
    var a
    var c
    if (points.length === 0) {
        return {
            equation: [NaN, NaN, NaN],
            points
        }
    } else if (points.length === 1) {
        let [ x, y ] = points[0]
        a = y/Math.pow(x, 2)
        c = 0
    } else {

        const coreFunc = (c) => {
            let As = points.map(([x, y]) => (y-c)/Math.pow(x, 2))
            let approxA = Math.max(mean(As), 0)  // mean of a's is a good approximmation; a > 0
            return fittingScore(points, approxA, 0, c) 
        }

        let Y = points.map(([x, y]) => y)
        let lowBorder = 0
        let highBorder = Math.min(...Y)  // Search for optimal c from 0 to the lowest point
        let middle = highBorder / 2
        c = goldenSectionSearch(coreFunc, lowBorder, middle, highBorder)
        let resultA = findParabolicA(points, c)
        a = resultA.equation[0]
    }
    return {
        equation: [a, 0, c],
        points
    }
}

const multiply = (a, b) => a.map(x => T(b).map(y => dot(x, y)));
const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
const T = a => a[0].map((x, i) => a.map(y => y[i]));

// Returns the inverse of matrix `M`.
// Source: http://blog.acipo.com/matrix-inversion-in-javascript/
function inv(M){
    // I use Guassian Elimination to calculate the inverse:
    // (1) 'augment' the matrix (left) by the identity (on the right)
    // (2) Turn the matrix on the left into the identity by elemetry row ops
    // (3) The matrix on the right is the inverse (was the identity matrix)
    // There are 3 elemtary row ops: (I combine b and c in my code)
    // (a) Swap 2 rows
    // (b) Multiply a row by a scalar
    // (c) Add 2 rows
    
    //if the matrix isn't square: exit (error)
    if(M.length !== M[0].length){return;}
    
    //create the identity matrix (I), and a copy (C) of the original
    var i=0, ii=0, j=0, dim=M.length, e=0;
    var I = [], C = [];
    for(i=0; i<dim; i+=1){
        // Create the row
        I[I.length]=[];
        C[C.length]=[];
        for(j=0; j<dim; j+=1){
            
            //if we're on the diagonal, put a 1 (for identity)
            if(i===j){ I[i][j] = 1; }
            else{ I[i][j] = 0; }
            
            // Also, make the copy of the original
            C[i][j] = M[i][j];
        }
    }
    
    // Perform elementary row operations
    for(i=0; i<dim; i+=1){
        // get the element e on the diagonal
        e = C[i][i];
        
        // if we have a 0 on the diagonal (we'll need to swap with a lower row)
        if(e===0){
            //look through every row below the i'th row
            for(ii=i+1; ii<dim; ii+=1){
                //if the ii'th row has a non-0 in the i'th col
                if(C[ii][i] !== 0){
                    //it would make the diagonal have a non-0 so swap it
                    for(j=0; j<dim; j++){
                        e = C[i][j];       //temp store i'th row
                        C[i][j] = C[ii][j];//replace i'th row by ii'th
                        C[ii][j] = e;      //repace ii'th by temp
                        e = I[i][j];       //temp store i'th row
                        I[i][j] = I[ii][j];//replace i'th row by ii'th
                        I[ii][j] = e;      //repace ii'th by temp
                    }
                    //don't bother checking other rows since we've swapped
                    break;
                }
            }
            //get the new diagonal
            e = C[i][i];
            //if it's still 0, not invertable (error)
            if(e===0){return}
        }
        
        // Scale this row down by e (so we have a 1 on the diagonal)
        for(j=0; j<dim; j++){
            C[i][j] = C[i][j]/e; //apply to original matrix
            I[i][j] = I[i][j]/e; //apply to identity
        }
        
        // Subtract this row (scaled appropriately for each row) from ALL of
        // the other rows so that there will be 0's in this column in the
        // rows above and below this one
        for(ii=0; ii<dim; ii++){
            // Only apply to other rows (we want a 1 on the diagonal)
            if(ii===i){continue;}
            
            // We want to change this element to 0
            e = C[ii][i];
            
            // Subtract (the row above(or below) scaled by e) from (the
            // current row) but start at the i'th column and assume all the
            // stuff left of diagonal is 0 (which it should be if we made this
            // algorithm correctly)
            for(j=0; j<dim; j++){
                C[ii][j] -= e*C[i][j]; //apply to original matrix
                I[ii][j] -= e*I[i][j]; //apply to identity
            }
        }
    }
    
    //we've done all operations, C should be the identity
    //matrix I should be the inverse:
    return I;
}

const regression = (points, order) => {
    if (points.length === 0) {
        let equation = []
        for (let k=0;k<=order;k++) {
            equation.push(NaN)
        }
        return {
            equation,
            points
        }
    } else {
        if (points.length === 1) {
            points.splice(0, 0, [0, 0])
        }
        const Ys = points.map(([x, y]) => y)
        const Xs = points.map(([x, y]) => x)
        let X = []
        for (let k=0; k<=order; k++) {
            X.push(
                Xs.map(x => Math.pow(x, k))
            )
        }
        X = T(X)
        let Y = T([Ys])
        let equation = T(multiply(
            inv(
                multiply(T(X), X)
            ),
            multiply(T(X), Y)
        ))[0].reverse()
        return {
            equation,
            points
        }
    }
}

export {
    findParabolicA, findParabolicAandC, regression
}