import React from 'react';
import { withTranslation } from 'react-i18next';

const Footer = ({ report, pageNumber, totalPages, t }) => (
  <footer className="absolute pin-b pin-l mb-4mm text-xs w-full text-center">
    <div className="italic">{report.footnote}</div>
    <div className="mt-2mm">{`${t('page')} ${pageNumber} ${t('page_out_of')} ${totalPages}`}</div>
  </footer>
);

export default withTranslation()(Footer);
