import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { capitalizeFirstWord } from '../../../../utils/helpers';
import defaultImg from '../../commons/default.jpg';

moment.locale('fr');

const Equipment = ({ i, equipment: equipment_data, t }) => {
  const equipment = {
    ...equipment_data,
    installed_at: equipment_data.installed_at
      ? moment(equipment_data.installed_at).format('DD/MM/YYYY')
      : null,
    floor_installed_at: equipment_data.floor_installed_at
      ? moment(equipment_data.floor_installed_at).format('DD/MM/YYYY')
      : null
  };

  const hasFloorInfo = [
    'floor_kind',
    'floor_ref',
    'floor_manufacturer',
    'floor_installed_at',
    'floor_condition',
    'floor_substrat'
  ].filter(item => equipment[item]).length > 0
  
  const hasGameInfo = [
    'ref', 
    'manufacturer', 
    'installed_at', 
    'ffh_max'
  ].filter(item => equipment[item]).length > 0

  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="text-2xl">{`${t('Equipment n°')}${i}: ${equipment.equipment}`}</div>
      <div className="mt-6mm flex justify-between text-left text-lg px-6mm text-black">
        {hasGameInfo && 
          <div>
            <h3 className="uppercase text-primary-dark underline">{t('Play equipment')}</h3>
            <ul className="mt-4mm list-reset text-sm font-normal">
              {['ref', 'manufacturer', 'installed_at', 'ffh_max'].map(
                item =>
                  equipment[item] && (
                    <li key={item} className="mt-2mm font-semibold">
                      {capitalizeFirstWord(t(item))} :{' '}
                      <span className="text-primary-dark">
                        {item === 'ffh_max' ? `${parseInt(equipment[item]).toFixed(2)} m` : equipment[item]}
                      </span>
                    </li>
                  )
              )}
            </ul>
          </div>
        }
        {hasFloorInfo && 
          <div>
            <h3 className="uppercase text-primary-dark underline">{t('Surfacing')}</h3>
            <ul className="mt-4mm list-reset text-sm font-normal">
              {[
                'floor_kind',
                'floor_ref',
                'floor_manufacturer',
                'floor_installed_at',
                'floor_condition',
                'floor_substrat'
              ].map(
                item =>
                  equipment[item] && (
                    <li key={item} className="mt-2mm font-semibold">
                      {capitalizeFirstWord(t(item))} :{' '}
                      <span className="text-primary-dark">{equipment[item]}</span>
                    </li>
                  )
              )}
            </ul>
          </div>
        }
      </div>
      <div className="mt-12mm px-16mm overflow-hidden">
        <img
          className="max-h-80mm object-contain border-2 border-primary-dark"
          src={
            equipment.picture
              ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/equipments/${equipment.id}/${equipment.picture}`
              : defaultImg
          }
          alt="equipment"
        />
      </div>
    </article>
  );
};

export default withTranslation()(Equipment);
