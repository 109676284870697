import React from 'react';
import { withTranslation } from 'react-i18next';

import { capitalizeFirstWord } from '../../utils/helpers';

const BottomActions = ({
  t,
  primaryAction,
  primaryTxt,
  primarySuccessTxt,
  clientErr,
  serverErr,
  secondaryAction,
  secondaryTxt,
  isSecondaryNegative,
  isLoading,
  isSuccess
}) => (
  <div className="flex flex-col md:flex-row items-baseline -mx-3 md:justify-end">
    {secondaryAction && (
      <div className="px-3 hidden md:inline">
        <button
          className={`align-baseline font-bold text-sm text-${
            isSecondaryNegative ? 'red-light' : 'grey-dark'
          } hover:text-${isSecondaryNegative ? 'red' : 'grey-darker'}`}
          onClick={secondaryAction}
        >
          {capitalizeFirstWord(secondaryTxt)}
        </button>
      </div>
    )}
    <div className="px-3 w-full md:w-auto">
      <button
        className={`${isLoading ? 'spinner ' : ''}bg-${
          isSuccess ? 'green' : 'primary'
        } hover:bg-${
          isSuccess ? 'green' : 'primary'
        }-dark text-white font-bold py-4 px-12 tracking-wide mb-3 rounded focus:outline-none focus:shadow-outline uppercase w-full`}
        type="button"
        onClick={primaryAction}
        disabled={isLoading || isSuccess}
      >
        {capitalizeFirstWord(isSuccess ? primarySuccessTxt : primaryTxt)}
      </button>
      {(clientErr || serverErr) && (
        <p className="text-red text-xs italic">
          {clientErr || serverErr}
        </p>
      )}
    </div>
    {secondaryAction && (
      <div className="w-full text-center mt-3 px-3 block md:hidden">
        <button
          className={`align-baseline font-bold text-sm text-${
            isSecondaryNegative ? 'red-light' : 'grey-dark'
          } hover:text-${isSecondaryNegative ? 'red' : 'grey-darker'}`}
          onClick={secondaryAction}
        >
          {capitalizeFirstWord(secondaryTxt)}
        </button>
      </div>
    )}
  </div>
);

export default withTranslation()(BottomActions);
