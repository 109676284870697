import React from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/fr';

import { capitalizeFirstWord, getResultColor } from '../../utils/helpers';

moment.locale('fr');

const ImpactsTable = ({ withDate, impacts, onDeleteImpact, t }) => {
  let columns = [];

  if (withDate) {
    columns.push({
      Header: capitalizeFirstWord(t('date')),
      accessor: 'updated_at',
      Cell: row => {
        let value = row.value;
        const minus24hours = moment().subtract(24, 'hours');
        value = moment(value).isSameOrAfter(minus24hours)
          ? capitalizeFirstWord(moment(value).fromNow())
          : moment(value).format('DD/MM/YYYY');
        return (<Link
        to={{
          pathname: `/zones/${row.original.zone_id}/impacts/${row.original.id}`
        }}
        className="w-full text-center cursor-pointer"
        style={{
          textDecoration: 'none'
        }}
      >
        <div className="w-full p-2">{value}</div>
      </Link>)
      }
    });
  }

  columns = [
    ...columns,
    ...['height', 'HIC', 'gmax'].map(el => ({
      Header: capitalizeFirstWord(t(el)),
      accessor: el,
      Cell: row => 
        (<Link
          to={{
            pathname: `/zones/${row.original.zone_id}/impacts/${row.original.id}`
          }}
          className="w-full text-center cursor-pointer"
          style={{
            textDecoration: 'none'
          }}
        >
          <div className="w-full p-2 text-right">{el === 'height'
            ? `${parseFloat(row.value).toFixed(2)} m`
            : row.value}</div>
        </Link>
      )
    }))
  ];

  columns.push({
    Header: capitalizeFirstWord(t('result')),
    accessor: 'result',
    minWidth: 100,
    Cell: row => (<Link
      to={{
        pathname: `/zones/${row.original.zone_id}/impacts/${row.original.id}`
      }}
      className="w-full text-center cursor-pointer"
      style={{
        textDecoration: 'none',
        color: getResultColor(row.value)
      }}
    >
      <div className="w-full p-2 text-center">{row.value}</div>
    </Link>
  )
  });

  if (onDeleteImpact) {
    columns.push({
      Header: null,
      accessor: 'delete-impact',
      minWidth: 40,
      Cell: ({ row }) => (
        <div className="w-full p-1 text-center">
          <button
            className="text-red-light"
            onClick={e => onDeleteImpact(e, row)}
          >
            <FontAwesomeIcon icon="trash-alt" size="lg" />
          </button>
        </div>
      ),
      sortable: false
    });
  }

  return (
    <ReactTable
      data={impacts}
      columns={columns}
      minRows={impacts.length === 0 ? 3 : 1}
      previousText={t('previous')}
      nextText={t('next')}
      loadingText={t('loading')}
      noDataText={t('no impacts found')}
      pageText={t('page')}
      ofText={t('ofText')}
      rowsText={t('rows')}
      className="-striped -highlight"
      defaultPageSize={10}
      showPagination={impacts.length > 10 ? true : false}
      defaultSorted={[
        {
          id: withDate ? 'updated_at' : 'height',
          desc: !!withDate
        }
      ]}
      getTrProps={(state, rowInfo, instance) =>
        rowInfo && rowInfo.original.deleted_at
          ? {
              style: {
                background: '#fff9c2'
              }
            }
          : {}
      }
    />
  );
};

export default withTranslation()(ImpactsTable);
