import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { get } from 'lodash';

import { findParabolicAandC } from '../utils/goldenSectionSearch'

import { Container } from '../components';
import {
  Input,
  Label,
  UploadImage,
  DisabledInput,
  Header,
  ConfirmDelete,
  BottomActions,
  ErrorAlert,
  Spinner,
  MethodOneChart
} from '../components/commons';
import ImpactsTable from './Impact/ImpactsTable';
import { capitalizeFirstWord } from '../utils/helpers';
import { getCFH, getPolyResults } from '../utils/maths';


class Zone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: null,
      equipment: null,
      equipment_id: null,
      site: null,
      zone: null,
      site_id: null,
      ffh: null,
      ground_thickness: null,
      comments: null,
      pictures: [],
      impacts: [],
      isDeleteModalActive: false,
      warnings: null,
      formErrors: {},
      serverErr: null,
      clientErr: null,
      isLoading: false,
      isSuccess: false,
      isReleaseLoading: false,
      isDeleteZoneLoading: false,
      isDeleteZoneSuccess: false,
      screenIsLoading: true,
      releaseError: null,
      temp: null,
      hygro: null,
      specimen_temp: null,
      specimen_hygro: null
    };
    this.temp = React.createRef();
    this.hygro = React.createRef();
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api/zones/${
          this.props.location.pathname.split('zones/')[1]
        }`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        console.log('my res', res)
        this.setState({
          ...res.data,
          ffh: res.data.ffh / 100,
          pictures: res.data.picture
            ? [
                {
                  source: res.data.picture,
                  options: {
                    type: 'local'
                  }
                }
              ]
            : [],
          impacts: res.data.impacts.map( impact => {
            const { hic, height, gmax, ...rest } = impact
            impact.HIC = hic
            const polyResult = getPolyResults([impact], res.data.ffh, res.data.method)
            return {
              ...rest,
              gmax,
              height: height / 100,
              HIC: hic,
              result: (res.data.method === 1) 
                ? (impact.gmax < 200 && impact.hic < 1000) 
                  ? 'Adequate' 
                  : 'Not Adequate'
                : polyResult.result
            }
          }),
          serverErr: null,
          clientErr: null,
          screenIsLoading: false
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          serverErr: JSON.stringify(err.response),
          screenIsLoading: false
        });
      });

    }
  }


  render() {
    const { t } = this.props;
    const {
      method,
      site,
      equipment,
      equipment_id,
      zone,
      temp,
      hygro,
      site_id,
      ffh,
      ground_thickness,
      comments,
      clientErr,
      serverErr,
      formErrors,
      isLoading,
      isReleaseLoading,
      isSuccess,
      pictures,
      impacts,
      isDeleteModalActive,
      isDeleteZoneLoading,
      isDeleteZoneSuccess,
      screenIsLoading,
      releaseError,
      specimen_temp,
      specimen_hygro
    } = this.state;

    const impactsForCalculs = impacts.filter(i => !i.deleted_at && !i.deletedAt)
    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;
    const breadcrumbs = [
      { path: `/`, name: t('Home') },
      { path: `/sites/${site_id}`, name: site },
      {
        path: `/equipments/${equipment_id}`,
        name: equipment
      },
      { name: zone }
    ];
    const HICResult = findParabolicAandC(
      impactsForCalculs.map(({ height, HIC }) => [height, HIC])
    );

    const GmaxResult = findParabolicAandC(
      impactsForCalculs.map(({ height, gmax }) => [height, gmax])
    );
    const CFH = getCFH(HICResult, GmaxResult);
    let heightTarget = CFH ? parseFloat(CFH[1].toFixed(2)) + 0.1 : null;
    if (method === 2) heightTarget = parseFloat(ffh);
    const isLabTest = this.state.is_lab_test
    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>{capitalizeFirstWord(zone)} - Lugdosphère</title>
        </Helmet>
        <ErrorAlert error={serverErr} />
        <ConfirmDelete
          isActive={isDeleteModalActive}
          title={zone}
          onDelete={this.handleDeleteZone}
          isDeleteLoading={isDeleteZoneLoading}
          isDeleteSuccess={isDeleteZoneSuccess}
          toggleModal={() => this.setState({ isDeleteModalActive: false })}
        />
        <div className="w-full max-w-md mx-auto my-8">
          <Header
            title={zone}
            subtitle={t('zone to test')}
            method={method}
            isLabTest={isLabTest}
            breadcrumbs={breadcrumbs}
          />
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Input
                fieldName="zone"
                label={t('zone')}
                type="text"
                value={zone}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-12">
            {!isLabTest && 
              <div className="w-1/2 px-3">
                <Input
                  fieldName="ffh"
                  label={t('FFH')}
                  type="number"
                  value={parseFloat(ffh)}
                  onChangeField={this.handleChangeField}
                  unit="m"
                  errors={formErrors}
                />
              </div>
            }
            <div className="w-1/2 px-3">
              <Input
                fieldName="ground_thickness"
                label={t('ground_thickness')}
                type="number"
                value={parseFloat(ground_thickness)}
                onChangeField={this.handleChangeField}
                unit="mm"
                errors={formErrors}
              />
            </div>
          </div>
          {method === 1 && isLabTest && 
            <div className={'flex-wrap -mx-3 mb-10 flex'}>
              <div className="w-1/2 px-3">
                <Input
                  fieldName="specimen_temp"
                  label={t('specimen_temp')}
                  type="number"
                  value={parseFloat(specimen_temp)}
                  onChangeField={this.handleChangeField}
                  unit="°C"
                  errors={formErrors}
                />
              </div>
              <div className="w-1/2 px-3">
                <Input
                  fieldName="specimen_hygro"
                  label={t('specimen_hygro')}
                  type="number"
                  value={parseFloat(specimen_hygro)}
                  onChangeField={this.handleChangeField}
                  unit="%"
                  errors={formErrors}
                />
              </div>
            </div>
          }
          {method === 1 && (
            <div className="flex -mx-3 mb-10">
              <div className="w-full px-3">
                <Label htmlFor="curve">
                  {t('curve')} / {t('method')} {method}
                </Label>
                <div className="my-4">
                  <MethodOneChart impacts={impactsForCalculs} />
                </div>
              </div>
            </div>
          )}
          <div
            className={`flex-wrap -mx-3 mb-10 ${
              method === 1 ? 'flex' : 'hidden'
            }`}
          >
            <div className="flex flex-col justify-end w-full sm:w-1/3 px-3 mb-6 sm:mb-0">
              <DisabledInput
                fieldName="cfh"
                label={t('estimated cfh')}
                value={CFH ? parseFloat(CFH[1].toFixed(2)) : '-'}
                unit="m"
              />
            </div>
            
            <div className="flex flex-col justify-end w-full sm:w-1/3 px-3">
              <DisabledInput
                fieldName="temp"
                label={t('T°C')}
                value={temp || this.state.temp}
                unit="°C"
                ref={this.temp}
              />
            </div>
            <div className="flex flex-col justify-end w-full sm:w-1/3 px-3">
              <DisabledInput
                fieldName="hygro"
                label={t('hygro.%')}
                value={
                  hygro || parseFloat(this.state.hygro)
                }
                unit="%"
                ref={this.hygro}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-12">
            <div className="w-full px-3">
              <Label htmlFor="table">{t('impacts table')}</Label>
              <ImpactsTable
                impacts={impacts}
                onDeleteImpact={this.handleDeleteImpact}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="pics">{t('zone picture')}</Label>
              <UploadImage
                pictures={pictures}
                onDrop={this.onDrop}
                processURL={`/pictures?type=zone&id=${
                  this.props.location.pathname.split('zones/')[1]
                }`}
                picturesURL={`/uploads/zones/${
                  this.props.location.pathname.split('zones/')[1]
                }/`}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Label htmlFor="comments">{t('comments')}</Label>
              <textarea
                name="comments"
                className={`appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white border-${
                  formErrors && formErrors['comments']
                    ? 'red'
                    : 'grey-lighter focus:border-primary-lighter'
                }`}
                cols="40"
                rows="2"
                value={comments || ''}
                onChange={e => this.handleChangeField('comments', e)}
              />
              {formErrors && formErrors['comments'] && (
                <p
                  className={`text-${
                    formErrors && formErrors['comments'] ? 'red' : 'orange'
                  } text-xs italic`}
                >
                  {formErrors && formErrors['comments']}
                </p>
              )}
            </div>
          </div>
          <BottomActions
            primaryAction={this.handleUpdate}
            primaryTxt={t('Update Zone')}
            primarySuccessTxt={t('Updated')}
            clientErr={clientErr}
            serverErr={serverErr}
            secondaryAction={() => this.setState({ isDeleteModalActive: true })}
            secondaryTxt={t('Delete')}
            isSecondaryNegative={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </Container>
    );
  }

  handleDeleteZone = () => {
    const token = localStorage.getItem('x-auth');
    this.setState({ isDeleteZoneLoading: true });
    axios
      .delete(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState({
          isDeleteZoneLoading: false,
          isDeleteZoneSuccess: true,
          serverErr: null,
          clientErr: null
        });
        this.props.history.push(
          this.props.location.pathname.split('/zones')[0]
        );
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          serverErr: JSON.stringify(err.response)
        });
      });
  };

  onDrop = (error, file) => {
    if (file) {
      this.setState({
        pictures: file
          ? [
              {
                source: file.file,
                options: {
                  type: 'local'
                }
              }
            ]
          : []
      });
    }
  };

  handleNewImpact = (data, err) => {
    const token = localStorage.getItem('x-auth');
    console.log('firmware-new_record zone', data, err)
    if(err){
      console.log('error on new record', err)
      this.setState({
        isReleaseLoading: false,
        releaseError: err
      })
    }
    else {
      return axios
      .post(window.location.protocol + '//' + window.location.hostname + `/api/zones/${
          this.props.location.pathname.split('zones/')[1]
        }/impacts`,
        {
          impact: data
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState({
          isReleaseLoading: false,
          serverErr: null,
          clientErr: null,
          releaseError: null
        }, () => {
          this.props.history.push(
            `/zones/${this.props.location.pathname.split('zones/')[1]}/impacts/${
              res.data.id
            }`
          );
        });
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err);
        } else console.error(err);
        this.setState({
          isReleaseLoading: false,
          serverErr:
            get(err, 'response.status') === 408
              ? null
              : JSON.stringify(err.response),
          releaseError: get(err, 'response.statusText')
        });
      });
    } 
  }

  handleDeleteImpact = (e, row) => {
    if (row && row._original) {
      const token = localStorage.getItem('x-auth');
      axios
        .delete(window.location.protocol + '//' + window.location.hostname + `/api/zones/${
            this.props.location.pathname.split('zones/')[1]
          }/impacts/${row._original.id}`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState(({ impacts }) => ({
            impacts: impacts.filter(({ id }) => id !== row._original.id),
            serverErr: null,
            clientErr: null
          }));
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            serverErr: JSON.stringify(err.response)
          });
        });
    }
  };

  handleReleaseHIC = () => {
    this.setState({
      isReleaseLoading: true
    });
    this.handleUpdate();
  };

  areFieldsValids = () => {
    const { t } = this.props;
    let errors = {};
    ['zone'].forEach(field => {
      if (!this.state[field]) {
        errors[field] = t('Cannot be empty');
      } else if (this.state[field].length > 42) {
        errors[field] = t('Too long (max. 42)');
      }
    });
    if (this.state.comments && this.state.comments.length > 200) {
      errors['comments'] = t('Too long (max. 200)');
    }
    if (errors && Object.keys(errors).length > 0) {
      this.setState(prevState => ({
        formErrors: { ...prevState.formErrors, ...errors }
      }));
      console.log(errors);
      this.setState({
        clientErr: t('Please correct form errors')
      });
      return false;
    }
    return true;
  };

  handleUpdate = () => {
    const { zone, ffh, ground_thickness, comments, temp, hygro, specimen_temp, specimen_hygro } = this.state;
    if (this.areFieldsValids()) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api/equipments/${
            this.props.location.pathname.split('equipments/')[1]
          }`,
          {
            zone,
            temp:
              temp ||
              Math.round(parseFloat(get(this.temp, 'current.innerText'))),
            hygro:
              hygro ||
              Math.round(parseFloat(get(this.hygro, 'current.innerText'))),
            ffh: Math.floor(ffh*100),
            ground_thickness: Math.round(ground_thickness),
            specimen_temp,
            specimen_hygro,
            comments
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          console.log('updated res', res);
          this.setState({
            isLoading: false,
            isSuccess: true,
            serverErr: null,
            clientErr: null
          });
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            isLoading: false,
            serverErr: JSON.stringify(err.response)
          });
        });
    }
  };  
  
  // debouncedSave = debounce(() => {
  //   this.handleUpdate()
  // }, 2000);

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: { ...prevState.formErrors, [key]: null },
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
    // this.scedSave();
  };
}

export default withTranslation()(Zone);
