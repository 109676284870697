import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { sumBy, orderBy } from 'lodash';

import { capitalizeFirstWord, getResultColor } from '../../../../utils/helpers';
import { getPolyResults } from '../../../../utils/maths';
import defaultImg from '../../commons/default.jpg';

moment.locale('fr');

const Zone = ({ j, zone: zone_data, equipment, t }) => {
  let impacts = [];
  let zone = {
    ...zone_data,
    FFH: zone_data.ffh ? zone_data.ffh / 100 : null
  };

  if (zone.impacts && zone.impacts.length > 0) {
    impacts = zone.impacts
      .map(({ hic, height, ...rest }) => ({
        ...rest,
        HIC: hic,
        height: height / 100
      }))
  }

  
  let resultColor;
  if (zone.FFH && impacts && impacts.length > 0) {
    const {result} = getPolyResults(impacts, zone.FFH, 2)
    resultColor = getResultColor(result);
    zone = { ...zone, result, FallHeight1: impacts[0].height };
  }

  const columns = ['height', 'HIC', 'gmax'].map(el => ({
    Header: capitalizeFirstWord(t(el)),
    accessor: el,
    minWidth: 40,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-0 text-sm">
            {el === 'height' ? `${row.value} m` : row.value}
          </div>
        </div>
      );
    },
    Footer:
      impacts.length > 3 ? (
        <div
          className="w-full text-sm capitalize"
          style={{
            textDecoration: 'none',
            textAlign: el !== 'height' ? 'right' : 'center',
            fontWeight: el === 'height' ? 'bold' : 'normal'
          }}
        >
          {el === 'height'
            ? `${t('average_for_3_bigger_gmax_and_hic')}`
            : Math.round(
                sumBy(orderBy(impacts, el, 'desc').slice(0, 3), el) / 3
              )}
        </div>
      ) : null
  }));

  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="mt-4mm flex justify-between">
        <div className="flex-39 pr-2mm">
          <div className="text-left text-2xl">{`${t('zone')}: ${zone.zone}`}</div>
          <ul className="mt-4mm list-reset text-xl text-left font-bold text-black">
            {['FFH', 'FallHeight1', 'result'].map(
              item =>
                zone[item] && (
                  <li key={item} className="mt-2mm">
                    {capitalizeFirstWord(t(item === "FallHeight1" ? "fall_height" : item))} :{' '}
                    <span
                      className={`text-primary`}
                      style={{color: item === 'result' ? resultColor: 'inherit'}}
                    >
                      {item !== 'result'
                        ? `${zone[item].toFixed(2)} m`
                        : zone[item]}
                    </span>
                  </li>
                )
            )}
          </ul>
          <div className="mt-4mm mr-2mm text-sm">
            <ReactTable
              data={impacts}
              columns={columns}
              minRows={1}
              previousText={t('previous')}
              nextText={t('next')}
              loadingText={t('loading')}
              noDataText={t('no data found')}
              pageText={t('page')}
              ofText={t('ofText')}
              rowsText={t('rows')}
              defaultPageSize={5}
              showPagination={false}
              className="-striped -highlight"
              defaultSorted={[
                {
                  id: 'height'
                }
              ]}
            />
          </div>
        </div>
        <div className="flex-59 overflow-hidden">
          <img
            className="max-h-80mm object-contain border-2 border-primary-dark"
            src={
              zone.picture
                ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/zones/${zone.id}/${zone.picture}`
                : defaultImg
            }
            alt="zone"
          />
          <div className="mt-2mm text-sm text-left font-normal text-black text-center">
            <p className="font-bold">
              {capitalizeFirstWord(t('temperature'))} :{' '}
              <span className="text-primary-dark">{zone['temp']} °C</span>
              <span className="mx-4mm">/</span>
              {capitalizeFirstWord(t('hygrometry'))} :{' '}
              <span className="text-primary-dark">{zone['hygro']} %</span>
            </p>
          </div>
          <div className="mt-4mm text-sm font-normal text-black">
          {zone.comments && <p>
              <span className='font-bold'>{t('Comment') + ' : '}</span> 
              <span>{zone.comments}</span>
            </p>
          }
          </div>
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(Zone);
