import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import {
  Input,
  Label,
  BottomActions,
  ErrorAlert,
  Switch,
  AutocompleteInput
} from '../../components/commons';

class DropTestInitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: '1',
      mission: '',
      client: '',
      site: props.clone ? props.clone.site : '',
      isLabTest: false,
      isLoading: false,
      isSuccess: false,
      formErrors: {},
      serverErr: null,
      clientErr: null
    };
  }

  render() {
    const { t, clone, missions, onToggle } = this.props;
    const {
      method,
      mission,
      client,
      site,
      isLabTest,
      isLoading,
      isSuccess,
      formErrors,
      clientErr,
      serverErr
    } = this.state;
    return (
      <>
        <ErrorAlert error={serverErr} />
        <div className="w-full max-w-md">
          {!clone && (
            <div className="flex -mx-3 mb-10">
              <div className="w-full px-3">
                <Label htmlFor="method">{t('method')}</Label>
                <div className="flex mt-3">
                  <label className="container capitalize mr-6">
                    {t('method')} 1: {t('CFH')}
                    <input
                      type="checkbox"
                      value="1"
                      checked={method === '1'}
                      onChange={ev => this.handleChangeField('method', ev)}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="container capitalize">
                    {t('method')} 2: {t('Adequacy')}
                    <input
                      type="checkbox"
                      value="2"
                      checked={method === '2'}
                      onChange={ev => this.handleChangeField('method', ev)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          )}
          {clone && (
            <div className="block mb-6 md:mb-10 tracking-wide text-grey-darker text-base font-bold uppercase">
              Duplicate: mission{' '}
              <span className="text-primary-dark">{clone.mission}</span> · site{' '}
              <span className="text-primary-dark">{clone.site}</span>
            </div>
          )}
          <div className="flex -mx-3 mb-6 md:mb-10">
            <div className="w-full md:w-1/2 px-3">
              <AutocompleteInput
                fieldName="mission"
                label={t('mission Id')}
                type="text"
                value={mission}
                onChangeField={this.handleChangeField}
                errors={formErrors}
                options={missions}
              />
            </div>
            {!clone && (
              <div className="w-full md:w-1/2 px-3">
                <Switch
                  fieldName="isLabTest"
                  label={t('Is Lab Test ?')}
                  onChange={() =>
                    this.setState(prevState => ({
                      isLabTest: !prevState.isLabTest
                    }))
                  }
                  isChecked={isLabTest}
                  isDisabled={method === '2'}
                />
              </div>
            )}
          </div>
          <div className="flex flex-wrap -mx-3 mb-12">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Input
                fieldName="client"
                label={t('client')}
                type="text"
                value={client}
                onChangeField={this.handleChangeField}
                errors={formErrors}
                // defaultHint={t('client hint')}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <Input
                fieldName="site"
                label={t('site')}
                type="text"
                value={site}
                onChangeField={this.handleChangeField}
                errors={formErrors}
                // defaultHint={t('site hint')}
              />
            </div>
          </div>
          <BottomActions
            primaryAction={this.handleSubmit}
            primaryTxt={t(clone ? 'duplicate' : 'validate')}
            primarySuccessTxt={t(clone ? 'duplicated' : 'validated')}
            clientErr={clientErr}
            serverErr={serverErr}
            secondaryAction={onToggle}
            secondaryTxt={t('cancel')}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </>
    );
  }

  areFieldsValids = () => {
    const { t } = this.props;
    let errors = {};
    ['mission', 'client', 'site'].forEach(field => {
      if (!this.state[field]) {
        errors[field] = t('Cannot be empty');
      } else if (this.state[field].length > 42) {
        errors[field] = t('Too long (max. 42)');
      }
    });
    if (errors && Object.keys(errors).length > 0) {
      this.setState(prevState => ({
        formErrors: { ...prevState.formErrors, ...errors }
      }));
      console.log(errors);
      this.setState({
        clientErr: t('Please correct form errors')
      });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { method, mission, client, site, isLabTest } = this.state;
    const { history, clone } = this.props;
    const token = localStorage.getItem('x-auth');
    console.log('clone', clone)
    if (clone) {
      if (this.areFieldsValids() && mission && client && site) {
        this.setState({
          isLoading: true
        });
        return axios
          .post(window.location.protocol + '//' + window.location.hostname + `/api/sites/${clone.id}/clone`,
            {
              mission,
              client,
              site
            },
            {
              headers: {
                Authorization: token
              }
            }
          )
          .then(res => {
            console.log(res);
            this.setState({
              isLoading: false,
              isSuccess: true,
              serverErr: null,
              clientErr: null
            });
            history.push(`/sites/${res.data.id}`);
          })
          .catch(err => {
            if (err && err.response) {
              console.error(err.response);
            } else console.error(err);
            this.setState({
              isLoading: false,
              serverErr: JSON.stringify(err.response),
              clientErr: null
            });
          });
      }
    } else if (this.areFieldsValids() && method && mission && client && site) {
      this.setState({
        isLoading: true
      });
      return axios
        .post(
          window.location.protocol + '//' + window.location.hostname + `/api/sites`,
          {
            method: parseInt(method),
            mission,
            client,
            site,
            is_lab_test: isLabTest
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({
            isLoading: false,
            isSuccess: true,
            serverErr: null,
            clientErr: null
          });
          history.push(`/sites/${res.data.id}`);
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            isLoading: false,
            serverErr: JSON.stringify(err.response),
            clientErr: null
          });
        });
    }
  };

  handleChangeField = (key, event, inputValue) => {
    const value = inputValue !== undefined ? inputValue : event.target.value;
    if (key === 'method' && value === '2') {
      this.setState(prevState => ({
        [key]: value,
        isLabTest: false,
        formErrors: { ...prevState.formErrors, [key]: null },
        isSuccess: false,
        serverErr: null,
        clientErr: null
      }));
    } else {
      this.setState(prevState => ({
        [key]: value,
        formErrors: { ...prevState.formErrors, [key]: null },
        isSuccess: false,
        serverErr: null,
        clientErr: null
      }));
    }
  };
}

export default withRouter(withTranslation()(DropTestInitForm));
