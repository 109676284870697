import React from 'react';
import { withTranslation } from 'react-i18next';

import defaultImg from './default.jpg';

const Second = ({ site, t }) => {
  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="text-2xl">{t('annexes_more_photos')}</div>
      {site.annexes &&
        site.annexes.map((annex, i) => (
          <div
            key={annex.id}
            className={`${i > 0 ? 'mt-6mm' : 'mt-6mm'} flex justify-between`}
          >
            <div className="w-1/2 mt-8mm px-2mm text-center text-md text-black">
              {annex.title}
            </div>
            <div className="w-1/2">
              <img
                className="h-44mm object-contain border-2 border-primary-dark"
                src={
                  annex.picture
                    ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/annexes/${annex.id}/${annex.picture}`
                    : defaultImg
                }
                alt="annex"
              />
            </div>
          </div>
        ))}
    </article>
  );
};

export default withTranslation()(Second);
