import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactTable from 'react-table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { Container } from '../components';
import {
  Input,
  Label,
  Header,
  UploadImage,
  ConfirmDelete,
  BottomActions,
  ErrorAlert,
  Spinner
} from '../components/commons';
import { capitalizeFirstWord, getResultColor } from '../utils/helpers';
import { getPolyResults, getCFH, getQuadraRes } from '../utils/maths';
import { findParabolicAandC } from '../utils/goldenSectionSearch'

const handleKeyPress = (e, onSubmit) => {
  if (e.key === 'Enter') {
    onSubmit();
  }
};

class Equipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: null,
      mission: null,
      site: null,
      site_id: null,
      equipment: null,
      newZone: null,
      pictures: [],
      zones: [],
      newEquipIsLoading: null,
      equipmentDate: null,
      equipmentManufacturer: null,
      equipmentRef: null,
      FFH: null,
      floorCondition: null,
      floorDate: null,
      floorManufacturer: null,
      floorKind: null,
      floorRef: null,
      floorSubstrat: null,
      is_lab_test: false,
      specimenData: [{ id: 0 }],
      geolocation: null,
      formErrors: {},
      isDeleteModalActive: false,
      isDeleteEquipmentLoading: null,
      isDeleteEquipmentSuccess: null,
      isLoading: false,
      isSuccess: false,
      serverErr: null,
      clientErr: null,
      screenIsLoading: true
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}`, {
        headers: {
          Authorization: token
        }
      })
        .then(res => {
          console.log('equipment', res.data) //, CFH, HICCFH, GmaxCFH);
          let specimenData = res.data.specimenData ? JSON.parse(res.data.specimenData) : []
          let minimalSpecimenData = [
            'specimen_name',
            'specimen_ref',
            'specimen_manufacturer',
            'specimen_dimension',
            'specimen_weight',
            'specimen_kind',
            'specimen_density',
            'specimen_surface_mass',
            'specimen_properties',
            'specimen_loose_thickness',
            'specimen_particule_distribution',
            'specimen_9331_results',
            'fixing_method',
            'fixing_dimension',
            'specimen_condition',
            'specimen_temperature',
            'specimen_hygro'
          ]
          let nextId = specimenData.length + 1
          minimalSpecimenData.forEach( (specData) => {
            if(specimenData.findIndex(e => e.title === specData) === -1){
              specimenData.push({
                id: nextId,
                title: specData,
                value: ''
              })
              nextId++
            }
          })

          this.setState({
            ...res.data,
            picture2: res.data.picture2
              ? [
                  {
                    source: res.data.picture2,
                    options: {
                      type: 'local'
                    }
                  }
                ]
              : [],
            pictures: res.data.picture
              ? [
                  {
                    source: res.data.picture,
                    options: {
                      type: 'local'
                    }
                  }
                ]
              : [],
            zones: res.data.zones
              .map(({ ffh, impacts, ...rest }) => {
                impacts = impacts.filter(i => !i.deleted_at && !i.deletedAt)
                impacts = impacts.map( ({height, hic, ...rest}) => {
                  return {
                    ...rest,
                    HIC: hic,
                    height: height
                  }
                })
                
                // Methods 1
                if (res.data.method === 1 && impacts && impacts.length > 0) {
                  const HICpoints = impacts.map(({ height, HIC }) => [height/100, HIC])
                  const gMaxPoints = impacts.map(({ height, gmax }) => [height/100, gmax])
                  const HICResult = findParabolicAandC(HICpoints);
                  const GmaxResult = findParabolicAandC(gMaxPoints);
                  const [, CFH] = getCFH(HICResult, GmaxResult);
                  const HICCFH = getQuadraRes(HICResult, 1000);
                  const GmaxCFH = getQuadraRes(GmaxResult, 200);
                  
                  // Specific method 1 lab
                  if(res.data.is_lab_test){
                    return {
                      ...rest,
                      HIC_Theorical_CFH: HICCFH*100,
                      GMAX_theorical_CFH: GmaxCFH*100,
                      CFH_to_take_into_account: CFH*100,
                    }
                  }
                  // Specific method 1 on site
                  else {
                    return {
                      ...rest,
                      FFH: ffh,
                      CFH: CFH*100,
                      ...getPolyResults(impacts, ffh, res.data.method)
                    }
                  }
                }

                // If no data
                if (!ffh || !impacts || impacts.length === 0) {
                  return {
                    ...rest,
                    FFH: ffh
                  };
                }
                
                // Method 2
                return {
                  ...rest,
                  fall_height: impacts[0].height,
                  FFH: ffh,
                  ...getPolyResults(impacts, ffh, res.data.method)
                };
              }),
            specimenData,
            serverErr: null,
            screenIsLoading: false
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            serverErr: JSON.stringify(err.response),
            screenIsLoading: false
          });
        });
    }
  }

  render() {
    const { t, location } = this.props;
    const {
      method,
      site,
      site_id,
      equipment,
      newZone,
      pictures,
      picture2,
      zones,
      isLoading,
      isSuccess,
      clientErr,
      serverErr,
      isDeleteModalActive,
      equipmentDate,
      equipmentManufacturer,
      equipmentRef,
      FFH,
      floorCondition,
      floorDate,
      floorManufacturer,
      floorKind,
      floorRef,
      floorSubstrat,
      is_lab_test,
      specimenData,
      isDeleteEquipmentLoading,
      isDeleteEquipmentSuccess,
      newZoneIsLoading,
      formErrors,
      screenIsLoading
    } = this.state;
    const isLabTest = is_lab_test;
    const isMethodOne = method === 1
    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    const columnNames = isMethodOne 
    ? isLabTest 
      ? ['zone', 'HIC_Theorical_CFH', 'GMAX_theorical_CFH', 'CFH_to_take_into_account'] 
      : ['zone', 'FFH', 'CFH', 'ground_thickness', 'result']  
    : ['zone', 'ground_thickness', 'FFH', 'fall_height', 'Gmax', 'HIC', 'result']
    const columns = columnNames.map(el => ({
      Header: capitalizeFirstWord(t(el).replace(/_/g, ' ')),
      accessor: el,
      minWidth: el === 'zone' ? 200 : el === 'result' ? 120 : 60,
      Cell: row => {
        return (
          <Link
            to={{
              pathname: `/equipments/${
                location.pathname.split('equipments/')[1]
              }/zones/${row.original.id}`
            }}
            className="w-full"
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: getResultColor(row.value),
              textAlign: typeof row.value === 'number' ? 'right' : 'center'
            }}
          >
            <div className="w-full p-2">
              {['FFH', 'HIC_Theorical_CFH', 'fall_height', 'GMAX_theorical_CFH', 'CFH_to_take_into_account', 'CFH'].includes(el) && row.value
                ? `${parseFloat(row.value/100).toFixed(2)} m`
                : el === 'ground_thickness'
                  ? row.value
                    ? (row.value + ' mm')
                    : ''  
                  : row.value}
            </div>
          </Link>
        );
      }
    }));
    const breadcrumbs = [
      { path: `/`, name: t('Home') },
      { path: `/sites/${site_id}`, name: site },
      {
        name: equipment
      }
    ];
    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>{capitalizeFirstWord(equipment)} - Lugdosphère</title>
        </Helmet>
        <ErrorAlert error={serverErr} />
        <ConfirmDelete
          isActive={isDeleteModalActive}
          title={equipment}
          onDelete={this.handleDeleteEquipment}
          isDeleteLoading={isDeleteEquipmentLoading}
          isDeleteSuccess={isDeleteEquipmentSuccess}
          toggleModal={() => this.setState({ isDeleteModalActive: false })}
        />
        <div className="w-full max-w-md mx-auto my-8">
          <Header
            title={equipment}
            subtitle={t('equipment to test')}
            method={method}
            isLabTest={isLabTest}
            breadcrumbs={breadcrumbs}
          />
          <div className="flex -mx-3 mb-10">
            <div className="w-full px-3">
              <Input
                fieldName="equipment"
                label={t('equipment')}
                type="text"
                value={equipment}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
          </div>
          <div className="flex -mx-3 mb-16">
            <div className="w-full px-3">
              <Label htmlFor="table">{t('zones tested')}</Label>
              <ReactTable
                data={zones}
                columns={columns}
                minRows={zones.length === 0 ? 3 : 1}
                previousText={t('previous')}
                nextText={t('next')}
                loadingText={t('loading')}
                noDataText={t('no data found')}
                pageText={t('page')}
                ofText={t('ofText')}
                rowsText={t('rows')}
                defaultPageSize={10}
                showPagination={zones.length > 10 ? true : false}
                className="-striped -highlight"
                getTrProps={(state, rowInfo, instance) =>
                  rowInfo && rowInfo.original.deleted_at
                    ? {
                        style: {
                          background: '#fff9c2'
                        }
                      }
                    : {}
                }
              />
            </div>
          </div>
          <div className="mb-10 -mx-3 flex">
            <div className="w-full px-3">
              <Label htmlFor="pics">{t('picture of the equipment')}</Label>
              <UploadImage
                pictures={pictures}
                onDrop={this.onDrop}
                processURL={`/pictures?type=equipment&id=${
                  this.props.location.pathname.split('equipments/')[1]
                }`}
                picturesURL={`/uploads/equipments/${
                  this.props.location.pathname.split('equipments/')[1]
                }/`}
              />
            </div>
          </div>

          {isLabTest &&
          <div className="mb-10 -mx-3 flex">
            <div className="w-full px-3">
              <Label htmlFor="pics2">{t('Picture showing all test positions')}</Label>
              <UploadImage
                pictures={picture2}
                onDrop={this.onDropPictureTwo}
                processURL={`/pictures?type=equipment&attr=picture2&id=${parseInt(
                  this.props.location.pathname.split('equipments/')[1]
                )}`}
                picturesURL={`/uploads/equipments/${parseInt(
                  this.props.location.pathname.split('equipments/')[1]
                )}/`}
              />
            </div>
          </div>
          }

          <div className="mt-4 mb-16">
            <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
              <span className="border-b-6 border-grey pr-16">
                {t(isLabTest ? 'specimen data' : 'equipment')}
              </span>
            </div>
            {isLabTest && (
              <>
                <p className="text-grey text-xs mt-4 italic">
                  {t('This data will appear on the specimen page of the report')}
                </p>
                <div className="mt-6 flex -mx-2">
                  <div className="w-1/2 px-2">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                      {'Title'}
                    </label>
                  </div>
                  <div className="w-1/2 px-2">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                      {'Value'}
                    </label>
                  </div>
                  <button
                    className={`invisible ml-2 flex bg-red-light hover:bg-red text-red-dark px-4 rounded focus:outline-none`}
                    type="button"
                    onClick={() => {}}
                    disabled={true}
                  >
                    <div className="text-center self-center">
                      <FontAwesomeIcon
                        icon="trash-alt"
                        size="1x"
                        color="#fff"
                      />
                    </div>
                  </button>
                </div>
              </>
            )}
            {isLabTest &&
              specimenData &&
              specimenData.map((item, index) => (
                <div
                  key={item.id}
                  className={`${index > 0 ? 'mt-2 ' : ''}flex -mx-2`}
                >
                  <div className="w-1/2 px-2">
                    <input
                      className={`flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
                        typeof value === 'number' ? 'text-right' : ''
                      } border border-grey-lighter focus:border-primary-lighter`}
                      type="text"
                      onChange={ev =>
                        this.handleChangeSpecimenData(item.id, 'title', ev)
                      }
                      value={t(item.title) || ''}
                      placeholder={'Specimen ...'}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <input
                      className={`flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
                        typeof value === 'number' ? 'text-right' : ''
                      } border border-grey-lighter focus:border-primary-lighter`}
                      type="text"
                      onChange={ev =>
                        this.handleChangeSpecimenData(item.id, 'value', ev)
                      }
                      value={item.value || ''}
                      placeholder={''}
                    />
                  </div>
                  <button
                    className={`ml-2 flex bg-red-light hover:bg-red text-red-dark py-3 px-4 rounded focus:outline-none`}
                    type="button"
                    onClick={() => this.handleDeleteSpecimenData(item.id)}
                  >
                    <div className="text-center self-center">
                      <FontAwesomeIcon
                        icon="trash-alt"
                        size="1x"
                        color="#fff"
                      />
                    </div>
                  </button>
                </div>
              ))}
            {isLabTest && (
              <div className="flex mt-4">
                <div className="w-full md:w-auto pr-2">
                  <button
                    className={`flex bg-grey hover:bg-grey-dark text-white font-bold py-4 pl-8 pr-10 tracking-wide mb-3 rounded focus:outline-none uppercase w-full justify-center md:justify-start`}
                    type="button"
                    onClick={this.handleAddSpecimenData}
                  >
                    <div className="text-center self-center">
                      <FontAwesomeIcon icon="plus" size="1x" color="#fff" />
                    </div>
                    <div className="ml-4 self-center">{t('New row')}</div>
                  </button>
                </div>
              </div>
            )}
            {!isLabTest && (
              <div className="mt-6 flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3 mb-10">
                  <Input
                    fieldName="equipmentRef"
                    label={t('ref')}
                    type="text"
                    value={equipmentRef}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10 md:mb-0">
                  <Input
                    fieldName="equipmentManufacturer"
                    label={t('manufacturer')}
                    type="text"
                    value={equipmentManufacturer}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10 md:mb-0">
                  <Input
                    fieldName="equipmentDate"
                    label={t('installation date')}
                    type="date"
                    value={moment(equipmentDate).format('YYYY-MM-DD')}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <Input
                    fieldName="FFH"
                    label={t('FFH')}
                    type="text"
                    value={FFH}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                    defaultHint={t('FFH hint')}
                    unit='m'
                  />
                </div>
              </div>
            )}
          </div>
          {!isLabTest && (
            <div className="mt-4 mb-16">
              <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
                <span className="border-b-6 border-grey pr-16">
                  {t('floor')}
                </span>
              </div>
              <div className="mt-6 flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3 mb-10">
                  <Input
                    fieldName="floorRef"
                    label={t('ref')}
                    type="text"
                    value={floorRef}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10 md:mb-0">
                  <Input
                    fieldName="floorManufacturer"
                    label={t('manufacturer')}
                    type="text"
                    value={floorManufacturer}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10">
                  <Input
                    fieldName="floorKind"
                    label={t('kind')}
                    type="text"
                    value={floorKind}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10">
                  <Input
                    fieldName="floorSubstrat"
                    label={t('substrat')}
                    type="text"
                    value={floorSubstrat}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-10 md:mb-0">
                  <Input
                    fieldName="floorDate"
                    label={t('floor Date')}
                    type="date"
                    value={moment(floorDate).format('YYYY-MM-DD')}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <Input
                    fieldName="floorCondition"
                    label={t('floor Condition')}
                    type="text"
                    value={floorCondition}
                    onChangeField={this.handleChangeField}
                    errors={formErrors}
                  />
                </div>
              </div>
            </div>
          )}
          <BottomActions
            primaryAction={this.handleUpdate}
            primaryTxt={t('Update Equipment')}
            primarySuccessTxt={t('Updated')}
            clientErr={clientErr}
            serverErr={serverErr}
            secondaryAction={() => this.setState({ isDeleteModalActive: true })}
            secondaryTxt={t('Delete')}
            isSecondaryNegative={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </Container>
    );
  }

  handleAddSpecimenData = () => {
    this.setState(prevState => ({
      specimenData: [
        ...prevState.specimenData,
        {
          id: prevState.specimenData[prevState.specimenData.length - 1].id + 1,
          title: '',
          value: ''
        }
      ]
    }));
  };

  handleChangeSpecimenData = (id, field, ev) => {
    const value = ev.target.value;
    this.setState(prevState => ({
      specimenData: prevState.specimenData.map(item =>
        item.id === id
          ? {
              ...item,
              [field]: value
            }
          : item
      )
    }));
  };

  handleDeleteSpecimenData = id => {
    this.setState(prevState => ({
      specimenData:
        prevState.specimenData.length > 1
          ? prevState.specimenData.filter(item => item.id !== id)
          : [{ id: prevState.specimenData[0].id, title: '', value: '' }]
    }));
  };

  handleSubmit = () => {
    const { history } = this.props;
    const { newZone } = this.state;
    if (newZone) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        newZoneIsLoading: true
      });
      return axios
        .post(window.location.protocol + '//' + window.location.hostname + `/api/equipments/${
            this.props.location.pathname.split('equipments/')[1]
          }/zones`,
          {
            zone: newZone
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            newZoneIsLoading: false,
            serverErr: null,
            clientErr: null
          });
          history.push(`${this.props.location.pathname}/zones/${res.data.id}`);
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            newZoneIsLoading: false,
            serverErr: JSON.stringify(err.response)
          });
        });
    }
  };

  handleDeleteEquipment = () => {
    const token = localStorage.getItem('x-auth');
    this.setState({ isDeleteEquipmentLoading: true });
    axios
      .delete(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        console.log('delete equipment', res);
        this.setState({
          isDeleteEquipmentLoading: false,
          isDeleteEquipmentSuccess: true,
          serverErr: null,
          clientErr: null
        });
        this.props.history.push(`/sites/${this.state.site_id}`);
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          serverErr: JSON.stringify(err.response)
        });
      });
  };

  areFieldsValids = () => {
    const { t } = this.props;
    let errors = {};
    ['equipment'].forEach(field => {
      if (!this.state[field]) {
        errors[field] = t('Cannot be empty');
      } else if (this.state[field].length > 42) {
        errors[field] = t('Too long (max. 42)');
      }
    });
    if (errors && Object.keys(errors).length > 0) {
      this.setState(prevState => ({
        formErrors: { ...prevState.formErrors, ...errors }
      }));
      console.log(errors);
      this.setState({
        clientErr: t('Please correct form errors')
      });
      return false;
    }
    return true;
  };

  handleUpdate = () => {
    const {
      equipment,
      equipmentDate,
      equipmentManufacturer,
      equipmentRef,
      FFH,
      floorCondition,
      floorDate,
      floorManufacturer,
      floorKind,
      floorRef,
      floorSubstrat,
      specimenData
    } = this.state;
    if (this.areFieldsValids()) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}`,
          {
            equipment,
            ref: equipmentRef,
            manufacturer: equipmentManufacturer,
            installed_at: equipmentDate,
            ffh_max: FFH,
            floor_ref: floorRef,
            floor_manufacturer: floorManufacturer,
            floor_kind: floorKind,
            floor_substrat: floorSubstrat,
            floor_condition: floorCondition,
            floor_installed_at: floorDate,
            specimen_data: JSON.stringify(specimenData)
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            isLoading: false,
            isSuccess: true,
            clientErr: null,
            serverErr: null
          });
        })
        .catch(err => {
          if (err && err.response) {
            console.error(err.response);
          } else console.error(err);
          this.setState({
            isLoading: false,
            serverErr: JSON.stringify(err.response)
          });
        });
    }
  };

  debouncedSave = debounce(() => {
    this.handleUpdate()
  }, 2000);

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: { ...prevState.formErrors, [key]: null },
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
    this.debouncedSave();
  };

  onDrop = (error, file) => {
    if (file) {
      this.setState({
        pictures: file
          ? [
              {
                source: file.file,
                options: {
                  type: 'local'
                }
              }
            ]
          : []
      });
    }
  };
  onDropPictureTwo = (error, file) => {
    if (file) {
      this.setState({
        picture2: file
          ? [
              {
                source: file.file,
                options: {
                  type: 'local'
                }
              }
            ]
          : []
      });
    }
  };
}

export default withTranslation()(Equipment);
