import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../utils/helpers'
import defaultImg from './default.jpg';

moment.locale('fr');

const First = ({ user, site, isLabTest, t }) => {
  return (
    <article className="text-center text-primary-darker font-bold">
      <div className="text-4xl">{t('Mission') + ` : ${site.mission}`}</div>
      <div className="mt-4mm text-2xl">{capitalizeFirstLetter(t('client')) + ` : ${site.client}`}</div>
      <div className="mt-16mm text-xl">
        <p>{t('Tests carried out on site in accordance EN1177-2018') + ` - ${t('Method')} ${site.method} ${isLabTest ? t('in the laboratory') : t('on site')}`}</p>
      </div>
      <div className="mt-12mm text-2xl underline">{t('Site') + ` : ${site.site}`}</div>
      <div className="mt-2mm px-16mm overflow-hidden">
        <img
          className="max-h-80mm object-contain border-2 border-primary-dark"
          src={
            site.picture
              ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/sites/${site.id}/${site.picture}`
              : defaultImg
          }
          alt="site"
        />
      </div>
      {site.address && (
        <div className="mt-4mm text-md text-black">{t('Site address') + ` : ${site.address}`}</div>
      )}
      {user.professional_designation && (
        <div className="mt-10mm text-md">{t('Inspection by') + ` : ${user.professional_designation}`}</div>
      )}
      <div className="mt-2mm text-md">{t('The') + ` : ${moment(
        site.equipments && site.equipments[0]
          ? site.equipments[0].updated_at
          : site.updated_at
      ).format('DD/MM/YYYY')}`}</div>
      {!isLabTest && (
        <div className="mt-12mm mx-12mm text-sm text-black font-bold">
          {t('This test was carried out on site with the particular climatic and site conditions occurring on the day of test. The results cannot be used to indicate the performance of the product under any other conditions or in other locations.')}
        </div>
      )}
    </article>
  );
};

export default withTranslation()(First);
