import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';

import {
  capitalizeFirstWord,
  capitalizeFirstLetter,
  getEquipmentResult,
  getResultColor
} from '../../../../utils/helpers';

import SecondTempHygro from '../../commons/SecondTempHygro' 

const Second = ({ site, t }) => {

  const columns = ['equipment', 'result'].map(el => ({
    Header: capitalizeFirstWord(t(el)),
    accessor: el,
    minWidth: el === 'equipment' ? 200 : el === 'result' ? 120 : 60,
    Cell: row => {
      let value = row.value;
      if (el === 'result') {
        value = getEquipmentResult(row.original, 1);
      }
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: getResultColor(value),
            textAlign: 'center'
          }}
        >
          <div className="w-full p-1">{value}</div>
        </div>
      );
    }
  }));
  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="text-xl">{t('Test conditions')}</div>
      <SecondTempHygro site={site} />
      
      <div className="mt-8mm text-xl">{`${capitalizeFirstLetter(t('site results summary'))} : ${site.site}`}</div>
      <div className="mt-4mm text-sm">
        <ReactTable
          data={site.equipments}
          columns={columns}
          minRows={1}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('no data found')}
          pageText={t('page')}
          ofText={t('ofText')}
          rowsText={t('rows')}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    </article>
  );
};

export default withTranslation()(Second);
