import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import logo from '../text-logo.svg';
import { Container } from '../components';
import { Link } from 'react-router-dom';

const handleKeyPress = (e, onSubmit) => {
  if (e.key === 'Enter') {
    onSubmit();
  }
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      isSuccess: false,
      isLoading: false,
      error: null
    };
  }

  render() {
    const { t } = this.props;
    const { username, password, isLoading, isSuccess, error } = this.state;
    return (
      <Container isPrivate={false}>
        <div className="flex items-center justify-center w-full h-screen">
          <Helmet>
            <title>{t('login')} - Lugdosphère</title>
            <style>
              {`body {    
                background-image: url(./fond-section-1.jpg);
                background-repeat: no-repeat;
                background-position: left bottom;
              }`}
            </style>
          </Helmet>
          <div className="w-full max-w-xs md:mx-auto">
            <img src={logo} className="block mx-auto mb-6" alt="logo" />
            <div className="flex p-8 flex-col bg-white shadow-md rounded mb-3 md:flex-wrap md:justify-between">
              <div className="flex flex-col mb-6 md:w-full">
                <label
                  className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  htmlFor="identifiant"
                >
                  {t('username')}
                </label>
                <input
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary-light"
                  type="text"
                  name="identifiant"
                  placeholder="Lug"
                  onChange={ev => this.handleChangeField('username', ev)}
                  value={username}
                  onKeyPress={ev => handleKeyPress(ev, this.handleSubmit)}
                  required
                  autoFocus
                />
              </div>
              <div className="flex flex-col mb-6 md:w-full">
                <label
                  className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  {t('password')}
                </label>
                <input
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary-light"
                  type="password"
                  name="password"
                  placeholder="*********"
                  onChange={ev => this.handleChangeField('password', ev)}
                  value={password}
                  onKeyPress={ev => handleKeyPress(ev, this.handleSubmit)}
                  required
                />
              </div>
              <div className="flex flex-col">
                <button
                  className="block text-white uppercase tracking-wide text-base font-bold py-4 w-full rounded bg-primary-light hover:bg-primary mx-auto mb-4"
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={isLoading || isSuccess}
                >
                  {t('sign In')}
                </button>
                {isSuccess && (
                  <p className="text-center text-green text-sm">
                    {t('successfully signed in')}
                  </p>
                )}
                {error && (
                  <p className="text-center text-red text-sm">{error}</p>
                )}
              </div>
            </div>
            <p className="text-center text-primary-light text-xs">
              ©2019 lugdosphère
            </p>
          </div>
        </div>
      </Container>
    );
  }

  handleSubmit = async () => {
    const { username, password } = this.state;
    if (!username || !password) return;
    this.setState({ isLoading: true });
    return axios
      .post(window.location.protocol + '//' + window.location.hostname + `/api/login`, {
        username,
        password
      })
      .then(res => {
        this.setState({ isLoading: false });
        if (res.data && res.data.token) {
          this.setState({ isSuccess: true });
          localStorage.setItem('x-auth', res.data.token);
          localStorage.setItem('is-admin', res.data.user.is_admin);
          localStorage.setItem('username', res.data.user.username);
          localStorage.setItem('enterprise', res.data.user.enterprise);
          this.props.history.push('/');
        }
      })
      .catch(err => { 
        let msg = 'Une erreur est apparue.';
        if (err && err.response && err.response.status === 404) {
          msg = "Cet utilisateur n'existe pas.";
        } else if (err && err.response && err.response.status === 400) {
          msg = 'Mot de passe invalide.';
        }
        this.setState({ error: msg, isLoading: false });
      });
  };

  handleChangeField = (key, event) => {
    this.setState({
      [key]: event.target.value
    });
  };
}

export default withTranslation()(Login);
