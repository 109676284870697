import React from 'react';

import { Header, Footer } from './commons';

export default ({ report, pageNumber, totalPages, children }) => (
  <section className="sheet p-8mm">
    <Header report={report} />
    {children}
    <Footer report={report} pageNumber={pageNumber} totalPages={totalPages} />
  </section>
);
