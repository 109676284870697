import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { omit } from 'lodash';

import {
  Input,
  Header,
  BottomActions,
  Spinner
} from '../../components/commons';
import { DotLoader } from 'react-spinners';

class Wifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssid: '',
      password: '',
      networks: undefined,
      formErrors: {},
      scanIsLoading: false,
      isSuccess: false,
      screenIsLoading: false,
      connecting: false,
    };
  }

  render() {
    const { t } = this.props;
    const { 
      ssid,
      password,
      formErrors,
      error,
      isSuccess,
      screenIsLoading,
      networks,
      scanIsLoading,
      connecting
    } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    return (
      <div>
        <Header title={t('wifi')} subtitle={t('connect largueur to network')} />
        <div className="w-full max-w-md mb-10">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="ssid"
                label={t('ssid')}
                type="text"
                placeholder="MyBox-8327"
                value={ssid}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="password"
                label={t('password')}
                type="text"
                placeholder="1AE4DA87303DE12FE"
                value={password}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
            <div className="w-full flex flex-row justify-center px-3">
              <BottomActions
                primaryAction={this.handleConnect}
                primaryTxt={connecting ? t('connecting') : t('connect')}
                primarySuccessTxt={t('you are connected')}
                serverErr={error}
                isSuccess={isSuccess}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap flex flex-col justify-center items-center">
          <button
            className="mb-10 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded capitalize"
            onClick={this.handleScan}
          >
            {scanIsLoading ? t('Scanning') : t('Scan')}
          </button>

          {scanIsLoading && 
            <DotLoader 
              sizeUnit={'px'} 
              size={42} 
              color={'#1a74ff'} 
              loading={scanIsLoading} 
            />
          }

          {!scanIsLoading && networks && networks.length > 0 && 
          <table className="table">
            <thead>
              <tr className="bg-primary text-white">
                <th scope="col">SSID</th>
                <th scope="col">{t('Signal level')}</th>
              </tr>
            </thead>
            <tbody>
              {networks.map( (n, k) => (
                <tr
                  key={k}
                  onClick={() => this.handleSelectNetwork(n.ssid)}
                  className={
                    n.ssid === this.state.ssid 
                      ? 'bg-primary-lighter text-white' 
                      : k % 2 
                        ? 'bg-primary-lightest' 
                        : ''
                  }
                >
                  <td>{n.ssid}</td>
                  <td>{n.signal_level}</td>
                </tr>
              ))}
            </tbody>
          </table>
          }
        </div>
      </div>
    );
  }

  handleScan = () => {
    this.setState({
      scanIsLoading: true
    })
    return axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/wpa/scan')
      .catch(err => {
        this.setState({
          scanIsLoading: false,
          error: JSON.stringify(err.response)
        });
      })
      .then(axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/wpa/scan'))
      .catch(err => {
        this.setState({
          scanIsLoading: false,
          error: JSON.stringify(err.response)
        });
      })
      .then(res => {
        let networks = []
        if(res && res.data){
          networks = Object.values(res.data)
            .map(n => {
              let network = n[0]
              network.signal_level = -network.signal_level
              return network
            })
            .filter(n => n.ssid !== '')
            .sort((a, b) => a.signal_level - b.signal_level);
        }
        this.setState({
          networks,
          scanIsLoading: false,
        });
      })
  }

  handleConnect = () => {
    this.setState({
      connecting: true
    })
    return axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/wpa/connect',{
      params: {
        id:0,
        ssid: this.state.ssid,
        psk: this.state.password
      }
    })
    .then( () => {
      setTimeout(() => {
        axios.get(window.location.protocol + '//' + window.location.hostname + ':9090/wpa/status')
          .then( (res) => {
            if(res && res.data && res.data.wpa_state && res.data.wpa_state === "COMPLETED"){
              this.setState({
                connecting: false,
                isSuccess: true
              });
            }
            else {
              this.setState({
                connecting: false,
                isSuccess: false,
                error: "bad informations, not connected"
              });
            }
          })
          .catch( err => {
            this.setState({
              connecting: false,
              isSuccess: false,
              error: JSON.stringify(err.message)
            });
          })
        }
      , 10000)  
    })
    .catch(err => {
      this.setState({
        connecting: false,
        isSuccess: false,
        error: JSON.stringify(err.message)
      });
    })
  }

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: omit(prevState.formErrors, key),
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
  };

  handleSelectNetwork = (ssid) => {
    this.setState({ ssid })
  }

}

export default withTranslation()(Wifi);
