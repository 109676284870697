import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConnected: false,
      isLoading: true
    };
  }

  componentDidMount() {
    this.mounted = true;
    const token = localStorage.getItem('x-auth');
    if (token && token !== 'undefined') {
      this.mounted && this.setState({ isLoading: true });
      return axios
        .post(window.location.protocol + '//' + window.location.hostname + `/api/verify/jwt`, { token })
        .then(() => {
          this.mounted &&
            this.setState({ isLoading: false, isConnected: true });
        })
        .catch(err => {
          console.log('private route mount err', err && err.response);
          if (err && err.response && err.response.status === 401) {
            return (
              this.mounted &&
              this.setState({ isLoading: false, isConnected: false })
            );
          }
          return (
            this.mounted &&
            this.setState({ isLoading: false, isConnected: true })
          );
        });
    }
    this.mounted && this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isConnected, isLoading } = this.state;
    if (isLoading) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return isConnected ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          );
        }}
      />
    );
  }
}

export default PrivateRoute;
