import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Title, Subtitle } from '.';

const Header = ({ title, subtitle, method, breadcrumbs, isLabTest, t, onClick }) => (
  <div className="mb-10" 
    onClick={onClick}
  >
    {breadcrumbs && breadcrumbs.length > 0 && (
      <div className="flex mb-6 pb-4 text-sm text-grey border-b border-primary-lightest items-center">
        <div className="rounded-full bg-primary-lightest hover:shadow-inner">
          <Link
            to={breadcrumbs[breadcrumbs.length - 2].path}
            className="flex py-1 pl-1 pr-3 text-primary-lighter capitalize items-center no-underline hover:no-underline "
          >
            <div className="h-5 w-5 bg-primary-lighter rounded-full flex">
              <div className="text-center self-center w-full">
                <FontAwesomeIcon icon="arrow-left" size="sm" color="#0065ff" />
              </div>
            </div>
            <div className="ml-2">{t('Back')}</div>
          </Link>
        </div>
        <div className="ml-6">
          {breadcrumbs.slice(0, -1).map(({ path, name }, id) => (
            <span key={`${name}${id}`}>
              <Link to={path} className="text-primary-lighter capitalize">
                {name}
              </Link>{' '}
              »{' '}
            </span>
          ))}
          <span className="capitalize">
            {breadcrumbs[breadcrumbs.length - 1].name}
          </span>
        </div>
      </div>
    )}
    <div className="flex justify-between items-baseline">
      <Subtitle>{subtitle}</Subtitle>
      {method && (
        <div
          className={`${
            isLabTest ? 'flex items-baseline py-1 pl-1 pr-3' : 'py-2 px-4'
          }
          uppercase tracking-wide  rounded-full ${
            method === 1
              ? `bg-pink-lightest text-pink-dark`
              : `bg-indigo-lightest text-indigo-dark`
          } text-xs font-bold`}
        >
          {isLabTest && (
            <div
              className={`h-5 w-5 ${
                method === 1 ? `bg-pink-dark` : `bg-indigo-dark`
              } rounded-full flex mr-2`}
            >
              <div className="text-center self-center w-full">
                <FontAwesomeIcon
                  icon="flask"
                  size="sm"
                  color={`#${method === 1 ? 'ffebef' : 'e6e8ff'}`}
                />
              </div>
            </div>
          )}
          <div>
            {t('method')}{' '}
            {method === 1 ? `1: ${t('CFH')}` : `2: ${t('Adequacy')}`}
          </div>
        </div>
      )}
    </div>
    <Title>{title}</Title>
  </div>
);

export default withTranslation()(Header);
