import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { capitalizeFirstWord } from '../../utils/helpers';

class ErrorAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      display: false,
      close: false
    };
  }

  render() {
    const { t, error } = this.props;
    const { copied, display, close } = this.state;
    if (!error || close) return null;
    return (
      <div className="fixed pin-t pin-x z-50 p-4 max-w-md mx-auto" role="alert">
        <div className="flex bg-red-lightest border-t-4 border-red rounded-b text-red-dark px-4 py-3 shadow-md w-full relative">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-red mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div className="overflow-auto">
            <p className="font-bold">
              {capitalizeFirstWord(t('an error appeared'))}
            </p>
            <p className="mt-1 text-sm">
              <CopyToClipboard
                text={error}
                onCopy={() => this.setState({ copied: true })}
              >
                <span className="hover:underline font-bold italic cursor-pointer">
                  {t('Copy the error infos')}
                </span>
              </CopyToClipboard>
              {copied ? <span className="ml-1">[{t('copied')}]</span> : null}{' '}
              {t('and send it to the developer and/or')}{' '}
              <span
                className="font-bold italic cursor-pointer hover:underline"
                onClick={() =>
                  this.setState(prevState => ({
                    display: !prevState.display
                  }))
                }
              >
                {t('display it')}
              </span>
            </p>
            {display && (
              <div className="mt-1">
                <p className="text-sm">{error}</p>
              </div>
            )}
          </div>
          <span
            className="absolute pin-t pin-r px-4 py-3"
            onClick={() => this.setState({ close: true })}
          >
            <svg
              className="fill-current h-6 w-6 text-red"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ErrorAlert);
