import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { uniq, uniqBy, maxBy, countBy } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';

import defaultHICPicture from './defaultHICPicture.png';
import { Container } from '../../components';
import { Modal, Label, ErrorAlert, Spinner } from '../../components/commons';
import DropTestInitForm from './DropTestInitForm';
import { capitalizeFirstWord } from '../../utils/helpers';
import SitesTable from './SitesTable';
import ImpactsTable from '../Impact/ImpactsTable';

moment.locale('fr');


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      impacts: [],
      impactsNb: null,
      orange: null,
      red: null,
      lastMetrology: null,
      nextMetrology: null,
      hicPicture: null,
      error: null,
      screenIsLoading: true,
      isModalActive: false,
      clone: null,
      versionGUI: null,
      hicSerial: null,
      hasNewUpdate: false
    };
  }

  async componentDidMount() {
    try {
      const token = localStorage.getItem('x-auth');
      if (token) {
        this.setState({ screenIsLoading: true });
        const sitesRes = await axios(window.location.protocol + '//' + window.location.hostname + `/api/sites?with=deleted`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const adminRes = await axios(window.location.protocol + '//' + window.location.hostname + `/api/admin`, {
          headers: {
            Authorization: token
          }
        });
        this.setState({
          sites:
            sitesRes.data.sites && sitesRes.data.sites.length > 0
              ? sitesRes.data.sites.map(({ geolocation, ...site }) => ({
                  ...site,
                  geolocation: !!geolocation
                    ? `https://www.google.com/maps/search/${
                        geolocation.split(';')[0]
                      },${geolocation.split(';')[1]}`
                    : null,
                  report: `/sites/${site.id}/report`
                }))
              : [],
          impactsNb: sitesRes.data.impacts ? sitesRes.data.impacts.length : 0,
          impacts: sitesRes.data.impacts
            ? sitesRes.data.impacts.map(({ hic, height, gmax, ...rest }) => ({
                ...rest,
                gmax,
                height: height / 100,
                HIC: hic,
                result: hic > 1000 || gmax > 200 ? 'Not Adequate' : 'Adequate'
              }))
            : [],
          ...adminRes.data,
          error: null,
          screenIsLoading: false
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        error: JSON.stringify(err.response),
        screenIsLoading: false
      });
    }
  }

  render() {
    const { t } = this.props;
    const {
      sites,
      impactsNb,
      impacts,
      lastMetrology,
      nextMetrology,
      error,
      screenIsLoading,
      isModalActive,
      clone,
      versionGUI
    } = this.state;
    const username = localStorage.getItem('username');
    const enterprise = localStorage.getItem('enterprise')
    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;
    const isAdmin = localStorage.getItem('is-admin') || false;

    const sitesNb = uniqBy(sites, 'site').length;
    const missionsNb = uniqBy(sites, 'mission').length;
    let maxHIC = null,
      maxGmax = null;
    if (impacts && impacts.length > 0) {
      maxHIC = maxBy(impacts, 'HIC').HIC;
      maxGmax = maxBy(impacts, 'gmax').gmax;
    }
    const limitGmax = 250
    const timesAboveGmaxLimit =
      countBy(impacts, impact => impact.gmax > limitGmax).true || 0;
    let metrologyPercent = null;


    if (lastMetrology && nextMetrology) {

    }

    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>{capitalizeFirstWord(t('home'))} - Lugdosphère</title>
        </Helmet>
        {isModalActive && (
          <Modal isActive={isModalActive} onToggle={this.toggleModal}>
            <DropTestInitForm
              clone={clone}
              onToggle={this.toggleModal}
              missions={
                sites.length > 0
                  ? uniq(sites.map(({ mission }) => mission))
                  : []
              }
            />
          </Modal>
        )}
        <ErrorAlert error={error} />
        <div className="w-full mx-auto mt-0 mb-32 sm:mt-8 sm:mb-8 px-4 sm:px-0">
          <div className="max-w-lg mx-auto">
            <div className="mt-6 flex justify-between text-xs sm:text-base">
              <div className="shadow-inner py-2 px-4 rounded-full flex items-center bg-green-lightest text-green">
                <FontAwesomeIcon icon="user-circle" size="lg" color="#51d88a" />
                <span className="ml-2 font-bold">{username}</span>
              </div>
              {(enterprise !== 'undefined') &&
              <div className="shadow-inner py-2 px-4 rounded-full flex items-center bg-grey-lightest text-grey">
                <FontAwesomeIcon icon="building" size="lg" color="grey" />
                <span className="ml-2 font-bold">{enterprise}</span>
              </div>
              }
              <div className="shadow-inner py-2 px-4 uppercase tracking-wide rounded-full flex items-center bg-orange-lightest text-orange-dark">
                <span className="mr-2 font-bold">+33 (0) 4 28 87 00 33</span>
                <FontAwesomeIcon icon="headset" size="lg" color="#de751f" />
              </div>
            </div>
            <div className="mt-6 flex justify-center sm:items-stretch flex-wrap sm:flex-no-wrap">
              
              <div className="mt-2 sm:mt-0 w-full sm:ml-6 flex flex-col sm:justify-between">
                <div className="sm:mb-6">
                  <Label htmlFor="hic-infos">
                    <div className="flex justify-between">
                      {this.state.hicSerial &&
                        <div>{`${t('Head HIC')} : ${this.state.hicSerial}`}</div>
                      }
                      {versionGUI && (
                        <div>
                          software <span className="lowercase">v</span>
                          {versionGUI}
                        </div>
                      )}
                    </div>
                  </Label>
                  <div className="w-full bg-grey-light text-grey-darker leading-tight text-xl font-bold rounded flex flex-wrap text-center text-right">
                    <div className="relative w-1/2 py-3 px-6 rounded-tl bg-grey-lighter">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {t('missions')}
                      </span>
                      {missionsNb}
                    </div>
                    <div className="relative w-1/2 py-3 px-6 rounded-tr">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {t('sites tested')}
                      </span>
                      {sitesNb}
                    </div>
                    <div className="relative w-1/2 py-3 px-6">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {t('impacts')}
                      </span>
                      {impactsNb}
                    </div>
                    <div className="relative w-1/2 py-3 px-6 bg-grey-lighter">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {t('max. Gmax')}
                      </span>
                      {maxGmax || '-'}
                    </div>
                    <div className="relative w-1/2 py-3 px-6 rounded-bl bg-grey-lighter">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {t('max. HIC')}
                      </span>
                      {maxHIC || '-'}
                    </div>
                    <div className="relative w-1/2 py-3 px-6 rounded-br">
                      <span className="mt-1 ml-1 absolute pin-t pin-l tracking-wide text-grey-dark text-2xs font-bold uppercase">
                        {`${t('times above')} ${limitGmax}G`}
                      </span>
                      {timesAboveGmaxLimit}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {sites && sites.length > 0 && (
            <div className="mt-8 sm:mt-12 flex">
              <div className="mx-auto w-full max-w-xl">
                <Label htmlFor="table">{t('Past missions')}</Label>
                <SitesTable sites={sites} handleClone={this.onClone} />
              </div>
            </div>
          )}
          {isAdmin === 'true' && impacts && impacts.length > 0 && (
            <div className="mt-8 sm:mt-12 flex">
              <div className="mx-auto w-full max-w-lg">
                <Label htmlFor="table">{t('impacts table')}</Label>
                <ImpactsTable impacts={impacts} withDate={true} />
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({
      isModalActive: !prevState.isModalActive
    }));
  };

  onClone = (e, clone) => {
    e.preventDefault();
    this.setState({ isModalActive: true, clone });
  };
}

export default withTranslation()(Home);