import React, { Component } from 'react';

import { Label } from '.';

class AutocompleteInput extends Component {
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: ''
  };

  render() {
    const {
      fieldName,
      label,
      type,
      placeholder,
      errors,
      warnings,
      defaultHint
    } = this.props;
    const {
      activeOption,
      filteredOptions,
      showOptions,
      userInput
    } = this.state;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <div className="mt-2 relative w-full">
            <ul className="absolute list-reset shadow bg-white text-grey-darker rounded leading-tight w-full max-h-sm overflow-y-scroll border border-grey-lighter">
              {filteredOptions.map((optionName, index) => (
                <li
                  key={optionName}
                  className={`px-4 py-3 cursor-pointer hover:bg-primary-lightest ${
                    index === activeOption
                      ? 'bg-primary-lightest'
                      : index % 2 === 0
                      ? 'bg-grey-lightest'
                      : ''
                  }`}
                  onClick={this.onClick}
                >
                  {optionName}
                </li>
              ))}
            </ul>
          </div>
        );
      }
    }
    return (
      <>
        <Label htmlFor={fieldName}>{label}</Label>
        <div className={`mb-2 flex flex-col`}>
          <input
            id={fieldName}
            className={`flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border border-${
              errors && errors[fieldName]
                ? 'red'
                : warnings && warnings[fieldName]
                ? 'orange'
                : 'grey-lighter focus:border-primary-lighter'
            }`}
            type={type}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            value={userInput || ''}
            placeholder={placeholder}
          />
          {optionList}
        </div>
        {(((errors && errors[fieldName]) ||
          (warnings && warnings[fieldName])) && (
          <p
            className={`text-${
              errors && errors[fieldName] ? 'red' : 'orange'
            } text-xs italic`}
          >
            {(errors && errors[fieldName]) || (warnings && warnings[fieldName])}
          </p>
        )) ||
          (defaultHint && (
            <p className="text-grey-dark text-xs italic">{defaultHint}</p>
          ))}
      </>
    );
  }

  onChange = e => {
    const { options, onChangeField, fieldName } = this.props;
    const userInput = e.currentTarget.value;
    const filteredOptions = options.filter(
      option => option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput
    });
    onChangeField(fieldName, null, userInput);
  };

  onClick = e => {
    const { onChangeField, fieldName } = this.props;
    this.setState({
      activeOption: 0,
      filteredOption: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
    onChangeField(fieldName, null, e.currentTarget.innerText);
  };

  onKeyDown = e => {
    const { onChangeField, fieldName } = this.props;
    const { activeOption, filteredOptions } = this.state;
    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showSuggestions: false,
        userInput: filteredOptions[activeOption]
      });
      onChangeField(fieldName, null, filteredOptions[activeOption]);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption - 1 === filteredOptions.length) {
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };
}

export default AutocompleteInput;
