import React, { Fragment } from 'react';
import { chunk, maxBy } from 'lodash';

import Page from './Page';
import { First, CustomPage, Annex } from './commons';
import { Second, Equipment, Zone, EquipmentSummary } from './pages/Method2Site';
import ImpactChart from '../Impact/ImpactChart';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { useTranslation } from 'react-i18next'

export default ({ report, user, site }) => {
  const { t } = useTranslation();

  let i = 0,
    j = 0;
  let pageCount = 0

  // Total pages
  let totalPages = 0
  // First + Second + nbEquipement + nbZone for each Equipment + Annex ? + Custom page ?
  if(site){
    totalPages += 2 // First + Second
    if(site.equipments && site.equipments.length > 0){
      totalPages += site.equipments.length * 2
      totalPages += site.equipments.reduce( (nbZone, e) => nbZone += e.zones ? e.zones.length : 0, 0)
    }
    if (site.annexes && site.annexes.length > 0) totalPages++;
  }

  if (report.htmlCustomPage && report.htmlCustomPage.length > 10) {
    totalPages++;
  }

  return (
    <>
      <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
        <First user={user} site={site} />
      </Page>
      {report.htmlCustomPage && report.htmlCustomPage.length > 10 && (
        <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
          <CustomPage report={report} />
        </Page>
      )}
      <Page
        report={report}
        pageNumber={++pageCount}
        totalPages={totalPages}
      >
        <Second site={site} />
      </Page>
      {site.equipments &&
        site.equipments.map(equipment => {
          i++;
          return (
            <Fragment key={equipment.id}>
              <Page
                report={report}
                pageNumber={++pageCount}
                totalPages={totalPages}
              >
                <Equipment i={i} equipment={equipment} />
              </Page>
              <Page
                report={report}
                pageNumber={++pageCount}
                totalPages={totalPages}
              >
                <EquipmentSummary i={i} equipment={equipment} />
              </Page>
              {equipment.zones &&
                chunk([...equipment.zones, 'curve'], 2).map(
                  (zones, zonesIndex) => {
                    j++;

                    let impacts = [];
                    equipment.zones.forEach(zone => {
                      impacts = [...impacts, ...zone.impacts];
                    });
                    return (
                      <Page
                        key={j}
                        report={report}
                        pageNumber={++pageCount}
                        totalPages={totalPages}
                      >
                        {zones.map((zone, zoneIndex) => {
                          if (zone === 'curve') {
                            return (
                              <div
                                key={zone}
                                className={`flex${
                                  zoneIndex === 1 ? ' mt-12mm' : ''
                                }`}
                              >
                                <div className="mt-12mm text-center text-black text-2xl text-lg font-bold px-4mm">
                                  {capitalizeFirstLetter(t('time/acceleration curve of the highest value measured'))}
                                </div>
                                <ImpactChart
                                  points={maxBy(impacts, 'gmax').points}
                                  height={240}
                                />
                              </div>
                            );
                          }
                          return (
                            <div
                              key={zone.id}
                              className={`${zoneIndex === 1 ? 'mt-12mm' : ''}`}
                            >
                              {zonesIndex === 0 && zoneIndex === 0 && (
                                <div className="text-center text-primary-darker font-bold text-2xl">{`${equipment.equipment} - ${zone.zone}`}</div>
                              )}
                              <Zone j={j} zone={zone} equipment={equipment} />
                            </div>
                          );
                        })}
                      </Page>
                    );
                  }
                )}
            </Fragment>
          );
        })}
      {site.annexes && site.annexes.length > 0 && (
        <Page report={report} pageNumber={++pageCount} totalPages={totalPages}>
          <Annex site={site} />
        </Page>
      )}
    </>
  );
};
