import React from 'react';

import { Label } from '.';

export default ({
  fieldName,
  label,
  type,
  placeholder,
  value,
  onChangeField,
  unit,
  errors,
  warnings,
  defaultHint,
  disabled
}) => (
  <>
    <Label htmlFor={fieldName}>{label}</Label>
    <div className={`flex mb-2`}>
      <input
        id={fieldName}
        className={`flex-1 appearance-none block w-full bg-grey-lighter text-grey-darker rounded${
          unit ? '-l' : ''
        } py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
          typeof value === 'number' ? 'text-right' : ''
        } border border-${
          errors && errors[fieldName]
            ? 'red'
            : warnings && warnings[fieldName]
            ? 'orange'
            : 'grey-lighter focus:border-primary-lighter'
        }`}
        disabled={disabled}
        type={type}
        onChange={ev => onChangeField(fieldName, ev)}
        value={value || ''}
        placeholder={placeholder}
      />
      {unit && (
        <div className="flex flex-col justify-center bg-grey-lighter text-grey-darker rounded-r py-3 px-4 border-l border-grey-light">
          <span>{unit}</span>
        </div>
      )}
    </div>
    {(((errors && errors[fieldName]) || (warnings && warnings[fieldName])) && (
      <p
        className={`text-${
          errors && errors[fieldName] ? 'red' : 'orange'
        } text-xs italic`}
      >
        {(errors && errors[fieldName]) || (warnings && warnings[fieldName])}
      </p>
    )) ||
      (defaultHint && (
        <p className="text-grey-dark text-xs italic">{defaultHint}</p>
      ))}
  </>
);
