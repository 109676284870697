import React from 'react';

export default ({ htmlFor, children }) => (
  <label
    className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
    htmlFor={htmlFor}
  >
    {children}
  </label>
);
