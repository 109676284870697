import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Input,
  Header,
  BottomActions,
  UploadImage,
  ErrorAlert,
  Spinner
} from '../../components/commons';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orange: null,
      red: null,
      lastMetrology: null,
      nextMetrology: null,
      hicPicture: [],
      isLoading: false,
      isSuccess: false,
      warnings: {
        footnote: null
      },
      errors: {
        footnote: null
      },
      error: null,
      screenIsLoading: true
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api/admin`, {
        headers: {
          Authorization: token
        }
      })
        .then(res => {
          this.setState({
            ...res.data,
            error: null,
            hicPicture: res.data.hicPicture
              ? [
                  {
                    source: res.data.hicPicture,
                    options: {
                      type: 'local'
                    }
                  }
                ]
              : [],
            screenIsLoading: false
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            error: JSON.stringify(err.response),
            screenIsLoading: false
          });
        });
    }
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      isSuccess,
      screenIsLoading,
      orange,
      red,
      lastMetrology,
      nextMetrology,
      hicPicture,
      errors,
      error
    } = this.state;
    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;
    return (
      <div>
        <ErrorAlert error={error} />
        <Header
          title={t('admin')}
          subtitle={t('configure your admin settings')}
        />
        <div className="w-full max-w-md mb-10">
          <div className="flex mb-10">
            <div className="w-full">
              <div className="mb-6 block uppercase tracking-wide text-grey-darker text-lg font-bold">
                <span className="border-b-6 border-grey pr-16">
                  {t('HIC Picture')}
                </span>
              </div>
              <UploadImage
                pictures={hicPicture}
                onDrop={this.onDrop}
                processURL="/admin/hic"
                picturesURL="/uploads/HIC/"
              />
            </div>
          </div>
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
            <span className="border-b-6 border-grey pr-16">
              {`${t('CGV')} - ${t('override rules')}`}
            </span>
          </div>
          <div className="mt-8 flex flex-wrap mb-10">
            <div className="w-1/3">
              <Input
                fieldName="orange"
                label={t('orange')}
                type="number"
                value={parseFloat(orange)}
                onChangeField={this.handleChangeField}
                unit="g"
                errors={errors}
              />
            </div>
            <div className="w-1/3 md:ml-6">
              <Input
                fieldName="red"
                label={t('red')}
                type="number"
                value={parseFloat(red)}
                onChangeField={this.handleChangeField}
                unit="g"
                errors={errors}
              />
            </div>
          </div>
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
            <span className="border-b-6 border-grey pr-16">
              {t('metrology verifications')}
            </span>
          </div>
          <div className="mt-8 flex flex-wrap mb-10">
            <div className="w-1/3">
              <Input
                fieldName="lastMetrology"
                label={t('last metrology')}
                type="date"
                value={moment(lastMetrology).format('YYYY-MM-DD')}
                onChangeField={this.handleChangeField}
                errors={errors}
              />
            </div>
            <div className="w-1/3 md:ml-6">
              <Input
                fieldName="nextMetrology"
                label={t('next metrology')}
                type="date"
                value={moment(nextMetrology).format('YYYY-MM-DD')}
                onChangeField={this.handleChangeField}
                errors={errors}
              />
            </div>
          </div>
          <BottomActions
            primaryAction={this.handleSubmit}
            primaryTxt={t('save modifications')}
            primarySuccessTxt={t('modifications saved')}
            serverErr={error}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    );
  }

  onDrop = (error, file) => {
    if (file) {
      this.setState({
        hicPicture: file
          ? [
              {
                source: file.file,
                options: {
                  type: 'local'
                }
              }
            ]
          : []
      });
    }
  };

  handleSubmit = () => {
    const { orange, red, lastMetrology, nextMetrology, warnings } = this.state;
    const { t } = this.props;
    let errors = null;
    Object.entries(warnings).forEach(warning => {
      if (warning[1]) {
        errors = { ...errors, [warning[0]]: warning[1] };
      }
    });
    if (errors) {
      this.setState({
        errors,
        error: t('Please correct form errors')
      });
    } else if (orange || red || lastMetrology || nextMetrology) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api/admin`,
          {
            orange,
            red,
            lastMetrology,
            nextMetrology
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            isLoading: false,
            isSuccess: true,
            error: null
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isLoading: false,
            error: JSON.stringify(err.response)
          });
        });
    }
  };

  handleChangeField = (key, event) => {
    const value = event.target.value;
    // const { t } = this.props;
    this.setState({
      [key]: value,
      isSuccess: false,
      error: null
    });
    // if (value.length > 0 && value.length < 6 && key !== 'footnote') {
    //   this.setState(prevState => ({
    //     warnings: { ...prevState.warnings, [key]: t('Minimum 6 characters') }
    //   }));
    // } else if (value.length > 42) {
    //   this.setState(prevState => ({
    //     warnings: {
    //       ...prevState.warnings,
    //       [key]: t('Maximum 42 characters')
    //     }
    //   }));
    // } else {
    //   this.setState(prevState => ({
    //     warnings: { ...prevState.warnings, [key]: null },
    //     errors: { ...prevState.errors, [key]: null }
    //   }));
    // }
  };
}

export default withTranslation()(Admin);
