import React, { PureComponent } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
  ReferenceLine,
  ComposedChart,
  Scatter
} from 'recharts';
import { findParabolicAandC } from '../../utils/goldenSectionSearch'
import { merge } from 'lodash';
import { withTranslation } from 'react-i18next';

import { capitalizeFirstWord } from '../../utils/helpers';
import { getCFH, getLinspace } from '../../utils/maths';

class MethodOneChart extends PureComponent {
  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return (
      <span className="capitalize" style={{ color }}>
        {value}
      </span>
    );
  }

  render() {
    const { impacts, t } = this.props;
    if (!impacts) return null;
    const HICResult = findParabolicAandC(
      impacts.map(({ height, HIC }) => [height, HIC])
    );
    const GmaxResult = findParabolicAandC(
      impacts.map(({ height, gmax }) => [height, gmax])
    );
    const HICArray = HICResult.points.map(([height, HIC]) => ({
      height,
      HICPol: HIC
    }));
    const GmaxArray = GmaxResult.points.map(([height, gmax]) => ({
      height,
      gmaxPol: gmax
    }));
    
    const CFH = getCFH(HICResult, GmaxResult);
    var HICLinspace = []
    var GmaxLinspace = []
    if (HICResult.points.length > 0) {
      HICLinspace = getLinspace(HICResult, 0, CFH ? CFH[1]+1 : HICResult.points[HICResult.points.length-1][0]).map(
        ([ height, HIC ]) => ({
            height: +height.toFixed(2),
            HICPol: +HIC.toFixed(2)
          })
      )
    }
    if (GmaxResult.points.length > 0) {
      GmaxLinspace = getLinspace(GmaxResult, 0, CFH ? CFH[1]+1 : GmaxResult.points[GmaxResult.points.length-1][0]).map(
        ([ height, gmax ]) => ({
            height: +height.toFixed(2),
            gmaxPol: +gmax.toFixed(2)
          })
      )
    }

    const gmaxList = GmaxArray.map(e => e.gmaxPol)
    const hicList = HICArray.map(e => e.HICPol)
    
    const MIN_TOP_HIC_AXIS = 1000
    const MIN_TOP_GMAX_AXIS = 200
    let maxGMax = Math.max(...gmaxList)
    maxGMax = maxGMax < MIN_TOP_GMAX_AXIS ? MIN_TOP_GMAX_AXIS : maxGMax
    let maxHIC = Math.max(...hicList)
    maxHIC = maxHIC < MIN_TOP_HIC_AXIS ? MIN_TOP_HIC_AXIS : maxHIC

    // Get max values for hic and gmax axis
    // We want 1000 Hic to be in front of 200 Gmax
    const marge = 20 // in percent
    let topHicAxis = maxHIC > (MIN_TOP_HIC_AXIS * (100 + marge) / 100) ? maxHIC : MIN_TOP_HIC_AXIS
    // Add marge 
    topHicAxis *= (100 + marge) / 100
    let topGmaxAxis = maxGMax * (100 + marge) / 100

    // score is a value to know who from gmax and HIC are the bigget axis
    // 200 GMAX = 1000 HIC
    // => GMAX_scale = 5 HIC_scale
    // => HIC_scale = GMAX_scale / 5
    if(5 * maxGMax < maxHIC){
      topGmaxAxis = maxHIC / 5
      // add marge
      topGmaxAxis = topGmaxAxis * (100 + marge) / 100
    }
    else {
      // already has marge
      topHicAxis = topGmaxAxis * 5
    }

    let newImpacts =  impacts.map(e => {
      e.height = +e.height.toFixed(2)
      return e
    }).concat(merge(
      HICLinspace, GmaxLinspace
    ))

    const data = newImpacts
      .map(({ id, ...rest }, i) => ({ ...rest, id: i }));

    return (
      <ResponsiveContainer height={this.props.height || 350} width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 25
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="height"
            scale="linear"
            type="number"
            domain={[
              0,
              dataMax => (CFH && CFH[1] + 1 < dataMax ? CFH[1] + 1 : dataMax)
            ]}
          >
            <Label
              value={`${t('Fall height')} (m)`}
              offset={-10}
              position="insideBottom"
              style={{
                textAnchor: 'middle',
                fontSize: '90%',
                fill: '#606f7b'
              }}
            />
          </XAxis>
          <YAxis
            yAxisId="left"
            dataKey="HIC"
            scale="linear"
            type="number"
            domain={[0, topHicAxis]}
          >
            <Label
              value="HIC"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
                fontSize: '90%',
                fill: '#606f7b'
              }}
            />
          </YAxis>
          <YAxis
            yAxisId="right"
            orientation="right"
            dataKey="gmax"
            scale="linear"
            type="number"
            domain={[0, topGmaxAxis]}
          >
            <Label
              value="Gmax"
              angle={90}
              position="insideRight"
              style={{
                textAnchor: 'middle',
                fontSize: '90%',
                fill: '#606f7b'
              }}
            />
          </YAxis>
          <Tooltip
            labelFormatter={(value, name, props) => `${value} m`}
            formatter={(value, name) => [value, capitalizeFirstWord(name)]}
          />
          <Legend
            verticalAlign="top"
            height={30}
            formatter={this.renderColorfulLegendText}
          />
          {impacts.length > 0 && CFH && (
            <ReferenceLine
              yAxisId="right"
              x={CFH[1]}
              stroke={CFH[0] === 'HIC' ? '#8884d8' : '#82ca9d'}
            />
          )}
          {impacts.length > 0 && CFH && (
            <ReferenceLine
              yAxisId="right"
              y={CFH[0] === 'HIC' ? 1000 : 200}
              stroke={CFH[0] === 'HIC' ? '#8884d8' : '#82ca9d'}
            />
          )}
          {/* <ReferenceDot
            yAxisId="right"
            x={CFH[1]}
            y={CFH[0] === 'HIC' ? 1000 : 200}
            r={4}
            fill={CFH[0] === 'HIC' ? '#8884d8' : '#82ca9d'}
            stroke="none"
          /> */}
          <Line
            yAxisId="left"
            type="linear"
            dataKey="HICPol"
            stroke="#8884d8"
            dot={false}
            activeDot={false}
            // activeDot={{ r: 6 }}
          />
          <Line
            yAxisId="right"
            type="linear"
            dataKey="gmaxPol"
            stroke="#82ca9d"
            dot={false}
            activeDot={false}
            // activeDot={{ r: 6 }}
          />
          <Scatter yAxisId="left" dataKey="HIC" fill="#8884d8" />
          <Scatter yAxisId="right" dataKey="gmax" fill="#82ca9d" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default withTranslation()(MethodOneChart);
