import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { omit } from 'lodash';
import {
  Input,
  Header,
  ErrorAlert,
  Spinner
} from '../../components/commons';

const NB_CLICK_BEFORE_SHOW_BETA_UPDATE_BUTTON = 10

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secretBetaUpdateClick: 0,
      betaUpdating: false,
      betaUpdated: false,
      hasBetaUpdate: false,
      hotspotSSID: '',
      formErrors: {},
      isSuccess: false,
      changingHotspotSSID: false,
      screenIsLoading: false,
      loadingHicParams: false,
      hicMacAddress: '',
      updatingMacAddress: false,
      hicConnected: false,
      serial: '',
      lastUpdate: '',
      Xsensibility: '',
      Xoffset: '',
      Xserial: '',
      Ysensibility: '',
      Yoffset: '',
      Yserial: '',
      Zsensibility: '',
      Zoffset: '',
      Zserial: '',
      correct5V: '',
      triggerThreshold: '',
      maxG: '',
      dividerBatteryPoint: '',
      fallOffset: ''
    };
  }

  render() {
    const { t } = this.props;
    const { 
      hotspotSSID,
      screenIsLoading,
      changingHotspotSSID,
      error,
      serial,
      hicMacAddress,
      lastUpdate,
      Xsensibility,
      Xoffset,
      Xserial,
      Ysensibility,
      Yoffset,
      Yserial,
      Zsensibility,
      Zoffset,
      Zserial,
      correct5V,
      triggerThreshold,
      nbChute,
      maxG,
      dividerBatteryPoint,
      fallOffset
    } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;

    return (
      <div>
        <Header 
          title={t('configuration')} 
          subtitle={t('set configuration to HIC equipments')}
          onClick={this.handleSecretBetaUpdateClick}
        />
        <ErrorAlert error={error} />
        <div className="w-full">
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-5">
            <span className="border-b-6 border-grey pr-16">
              {t('hotspot')}
            </span>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-10">
              <Input
                fieldName="hotspotSSID"
                label={t('hotspot ssid')}
                type="text"
                placeholder="LargueurV2"
                value={hotspotSSID}
                onChangeField={this.handleChangeField}
                className="mb-20"
                />
              <button
                className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded capitalize mb-5"
                onClick={this.handleChangeHotspotSSID}
                >
                {changingHotspotSSID ? t('updatig hotspot') : t('update hotspot')}
              </button>
              <p>{t('NB: after change of hotspot SSID, please restart your product')}</p>
            </div>
          </div>
        </div>

        <div className='w-full mb-10'>
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-5">
            <span className="border-b-6 border-grey pr-16">
              {t('HIC MAC Adress')}
            </span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <Input
                fieldName="hicMacAddress"
                type="text"
                placeholder="00:06:66:0D:F3:9B"
                value={hicMacAddress}
                onChangeField={this.handleChangeField}
              />
            </div>
          </div>
          <button
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded capitalize"
            onClick={this.handleUpdateHicMacAddress}
            disabled={this.state.hicMacAddress.length === 0}
          >
            {this.state.updatingMacAddress ? t('updating MAC address') : t('update HIC MAC address')}
          </button>
        </div>
        
        <div>
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-5">
            <span className="border-b-6 border-grey pr-16">
              {t('HIC parameters')}
            </span>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="serial"
                label={t('serial')}
                type="text"
                placeholder="FFK"
                value={serial}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="lastUpdate"
                label={t('last control date')}
                type="text"
                placeholder="04/03/1992"
                value={lastUpdate}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Xsensibility"
                label={t('SensorX Sensitivity')}
                type="text"
                placeholder="0.2"
                value={Xsensibility}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Xoffset"
                label={t('SensorX Offset')}
                type="text"
                placeholder="1"
                value={Xoffset}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Xserial"
                label={t('SensorX Serial Number')}
                type="text"
                placeholder="FFKCCSQIJOD"
                value={Xserial}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Ysensibility"
                label={t('SensorY Sensitivity')}
                type="text"
                placeholder="0.2"
                value={Ysensibility}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Yoffset"
                label={t('SensorY Offset')}
                type="text"
                placeholder="1"
                value={Yoffset}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Yserial"
                label={t('SensorY Serial Number')}
                type="text"
                placeholder="FFKIHODQS"
                value={Yserial}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Zsensibility"
                label={t('SensorZ Sensitivity')}
                type="text"
                placeholder="0.2"
                value={Zsensibility}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Zoffset"
                label={t('SensorZ Offset')}
                type="text"
                placeholder="1"
                value={Zoffset}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="Zserial"
                label={t('SensorZ Serial Number')}
                type="text"
                placeholder="JHDQSLKKKDQ"
                value={Zserial}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="correct5V"
                label={t('Real 5V')}
                type="text"
                placeholder="5.2"
                value={correct5V}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="triggerThreshold"
                label={t('Triger Point In G')}
                type="text"
                placeholder="2"
                value={triggerThreshold}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="nbChute"
                label={t('Fall Count')}
                type="text"
                placeholder="0"
                value={nbChute}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="maxG"
                label={t('All Time Max G')}
                type="text"
                placeholder="0"
                value={maxG}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="dividerBatteryPoint"
                label={t('Battery Coef')}
                type="text"
                placeholder="1.2"
                value={dividerBatteryPoint}
                onChangeField={this.handleChangeField}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="fallOffset"
                label={t('Fall Offset')}
                type="text"
                placeholder="0"
                value={fallOffset}
                onChangeField={this.handleChangeField}
              />
            </div>
          </div>
          <button
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded capitalize mb-10"
            onClick={this.handleSendHicParameters}
          >
            {this.state.loadingHicParams ? t('Sending HIC params') : t('HIC params updated')}
          </button>
        </div>
        {this.betaUpdateModeActivated && 
        <div className='w-full mb-10'>
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-5">
            <span className="border-b-6 border-grey pr-16">
              {t('Beta updates')}
            </span>
          </div>
          <button
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded capitalize"
            onClick={this.handleBetaUpdate}
            disabled={this.state.hasBetaUpdate}
          >
            {this.state.betaUpdating ? t('Updating') : (this.state.betaUpdated ? 'Updated (Restarting)' : 'Update')}
          </button>
        </div>
        }
      </div>
    );
  }


  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: omit(prevState.formErrors, key),
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
  };

}

export default withTranslation()(Configuration);
