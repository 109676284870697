import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Switch } from '../../components/commons';

class DownloadDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dlImpactPoints: true,
      dlPictures: true,
      isLoading: false
    };
  }

  render() {
    const { t, onToggle } = this.props;
    const { dlImpactPoints, dlPictures, isLoading } = this.state;
    return (
      <div className="modal fixed z-50 pin overflow-auto flex items-center p-8 justify-center">
        <div
          className="fixed pin overflow-auto bg-primary opacity-50 cursor-pointer"
          onClick={onToggle}
        />
        <div className="animated fadeInUp max-w-sm relative pin-b pin-x align-top m-auto justify-end justify-center p-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-8">
            <span className="border-b-6 border-grey pr-16">
              {t('download project data')}
            </span>
          </div>
          <div className="flex -mx-3 mb-8">
            <div className="w-full md:w-1/2 px-3">
              <Switch
                fieldName="impactPoints"
                label={t('impact points')}
                onChange={() => this.toggle('dlImpactPoints')}
                isChecked={dlImpactPoints}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <Switch
                fieldName="pictures"
                label={t('pictures uploaded')}
                onChange={() => this.toggle('dlPictures')}
                isChecked={dlPictures}
              />
            </div>
          </div>
          <div className="flex items-baseline -mx-3 justify-end">
            <div className="px-3">
              <button
                className="inline-block align-baseline font-bold text-sm text-grey-darker hover:text-black capitalize"
                onClick={onToggle}
              >
                {t('cancel')}
              </button>
            </div>
            <div className="px-3">
              <button
                className={`${
                  isLoading ? 'spinner ' : ''
                }bg-primary hover:bg-primary-dark text-white font-bold py-4 px-16 tracking-wide rounded focus:outline-none focus:shadow-outline uppercase`}
                type="button"
                onClick={this.handleDownload}
                disabled={isLoading}
              >
                {t('Download')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggle = switchName => {
    this.setState(prevState => ({
      [switchName]: !prevState[switchName]
    }));
  };

  handleDownload = () => {
    const { dlImpactPoints, dlPictures } = this.state;
    // const {onToggle} = this.props;
    console.log('ok lets download', dlImpactPoints, dlPictures);
    // all site, equipments, zones, impacts data as csv
    // all pictures
    // zip everything
    // send to client & make it download
    // how to not save it on the server or delete the zip just after?
    let withBonus = '';

    if (dlImpactPoints || dlPictures) {
      withBonus = '?with=';
      withBonus += dlImpactPoints ? 'points' : '';
      withBonus += dlPictures ? `${dlImpactPoints ? ',' : ''}pictures` : '';
    }

    const token = localStorage.getItem('x-auth');
    this.setState({
      isLoading: true
    });
    return axios
      .post(window.location.protocol + '//' + window.location.hostname + `/api${this.props.location.pathname}/download${withBonus}`,
        {},
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState({
          isLoading: false,
          error: null
        });
        const blob = new Blob([res.data], {type: "text/csv;charset=utf-8"});
        saveAs(blob, "export.csv");
        this.props.onToggle()
      })
      .catch(err => {
        if (err && err.response) {
          console.error(err.response);
        } else console.error(err);
        this.setState({
          isLoading: false,
          error: JSON.stringify(err.response)
        });
      });
  };
}

export default withRouter(withTranslation()(DownloadDataForm));
