import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { omit } from 'lodash';

import {
  Input,
  BottomActions,
  Header,
  ErrorAlert,
  Spinner
} from '../../components/commons';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      username: '',
      professionalDesignation: '',
      password: '',
      confirmPassword: '',
      isLoading: false,
      isSuccess: false,
      formErrors: {},
      clientErr: null,
      serverErr: null,
      screenIsLoading: true,
      buzzerBeforeFall: false
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token) {
      this.setState({ screenIsLoading: true });
      axios(window.location.protocol + '//' + window.location.hostname + `/api/me`, {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        this.setState({
          user: res.data,
          username: res.data.username,
          professionalDesignation: res.data.professional_designation || '',
          serverErr: null,
          screenIsLoading: false
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          serverErr: JSON.stringify(err.response),
          screenIsLoading: false
        });
      });
    }

  }

  render() {
    const { t } = this.props;
    const {
      username,
      professionalDesignation,
      password,
      confirmPassword,
      isLoading,
      isSuccess,
      formErrors,
      serverErr,
      clientErr,
      screenIsLoading
    } = this.state;

    if (screenIsLoading) return <Spinner loading={screenIsLoading} />;
    if (serverErr) return <ErrorAlert error={serverErr} />;

    console.log('formErrors', formErrors);
    return (
      <div>
        <Header title={t('account')} />
        <div className="w-full max-w-md mb-10">
          <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-5">
            <span className="border-b-6 border-grey pr-16">
              {t('User Informations')}
            </span>
          </div>
          <div className="flex flex-wrap -mx-3 mb-12">
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="username"
                label={t('username')}
                type="text"
                placeholder="Elon"
                disabled
                value={username}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10">
              <Input
                fieldName="professionalDesignation"
                label={t('professional designation')}
                type="text"
                placeholder="Mr Elon Musk"
                value={professionalDesignation}
                onChangeField={this.handleChangeField}
                errors={formErrors}
                defaultHint={t('Professional designation hint')}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-10 md:mb-0">
              <Input
                fieldName="password"
                label={t('password')}
                type="password"
                placeholder="************"
                value={password}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <Input
                fieldName="confirmPassword"
                label={t('confirm')}
                type="password"
                placeholder="************"
                value={confirmPassword}
                onChangeField={this.handleChangeField}
                errors={formErrors}
              />
            </div>
          </div>
          <BottomActions
            primaryAction={this.handleSubmit}
            primaryTxt={t('save modifications')}
            primarySuccessTxt={t('modifications saved')}
            clientErr={clientErr}
            serverErr={serverErr}
            secondaryAction={this.props.handleLogout}
            secondaryTxt={t('log out')}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    );
  }

  areFieldsValids = () => {
    const { t } = this.props;
    const { username, password, confirmPassword, formErrors } = this.state;
    if (!username) {
      this.setState(prevState => ({
        formErrors: { ...prevState.formErrors, username: t('Cannot be empty') }
      }));
    }
    if (password && (!confirmPassword || password !== confirmPassword)) {
      this.setState(prevState => ({
        formErrors: {
          ...prevState.formErrors,
          confirmPassword: t('Does not correspond with your password')
        }
      }));
      return false;
    }
    if (formErrors && formErrors.length) {
      this.setState({
        clientErr: t('Please correct form errors')
      });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { user, username, professionalDesignation, password } = this.state;
    if (
      this.areFieldsValids() &&
      (username || professionalDesignation || password)
    ) {
      const token = localStorage.getItem('x-auth');
      this.setState({
        isLoading: true
      });
      return axios
        .patch(window.location.protocol + '//' + window.location.hostname + `/api/me`,
          {
            username: username !== user.username ? username : null,
            professional_designation: professionalDesignation,
            password
          },
          {
            headers: {
              Authorization: token
            }
          }
        )
        .then(res => {
          this.setState({
            username: res.data.username,
            professionalDesignation: res.data.professional_designation || '',
            isLoading: false,
            isSuccess: true,
            serverErr: null,
            clientErr: null
          });
          localStorage.setItem('x-auth', res.data.token);
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isLoading: false,
            serverErr: JSON.stringify(err.response),
            clientErr: null
          });
        });
    }
  };

  handleChangeField = (key, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      [key]: value,
      formErrors: omit(prevState.formErrors, key),
      isSuccess: false,
      serverErr: null,
      clientErr: null
    }));
  };
}

export default withTranslation()(Account);
