import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { findParabolicAandC } from '../../../../utils/goldenSectionSearch'
import { maxBy } from 'lodash';

import { capitalizeFirstWord, capitalizeFirstLetter } from '../../../../utils/helpers';
import MethodOneChart from '../../../../components/commons/MethodOneChart';
import ImpactChart from '../../../Impact/ImpactChart';
import defaultImg from '../../commons/default.jpg';
import { getPoint, getCFH, getQuadraRes } from '../../../../utils/maths';

moment.locale('fr');

const switchName = n => {
  switch (n) {
    case 'temp':
      return 'temperature';
    case 'hygro':
      return 'Hygrometry';
    default:
      return null;
  }
};

const Zone = ({ i, j, zone: zone_data, equipment, t }) => {
  let impacts = [];

  let zone = {
    ...zone_data,
    FFH: zone_data.ffh ? zone_data.ffh / 100 : null
  };

  if (zone.impacts && zone.impacts.length > 0) {
    impacts = zone.impacts
      .map(({ hic, height, ...rest }) => ({
        ...rest,
        HIC: hic,
        height: height / 100
      }))
    const HICResult = findParabolicAandC(
      impacts.map(({ height, HIC }) => [height, HIC])
    );
    const GmaxResult = findParabolicAandC(
      impacts.map(({ height, gmax }) => [height, gmax])
    );
    const HICPoint = getPoint(HICResult, zone.FFH, 'HIC');
    const GmaxPoint = getPoint(GmaxResult, zone.FFH, 'Gmax');
    const HICCFH = getQuadraRes(HICResult, 1000);
    const GmaxCFH = getQuadraRes(GmaxResult, 200);

    if (HICPoint && GmaxPoint) {
      const HIC = Math.round(HICPoint);
      const Gmax = Math.round(GmaxPoint);
      const CFH = getCFH(HICResult, GmaxResult);
      zone = { 
        ...zone, 
        HIC, 
        Gmax, 
        CFH: CFH ? CFH[1].toFixed(2) : null,
        HIC_Theorical_CFH: HICCFH,
        GMAX_theorical_CFH: GmaxCFH,
        CFH_to_take_into_account: CFH[1]
      };
    }
  }

  const columns = ['height', 'HIC', 'gmax'].map(el => ({
    Header: capitalizeFirstWord(t(el)),
    accessor: el,
    minWidth: 40,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-0 text-sm">
            {el === 'height' ? `${row.value} m` : row.value}
          </div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker font-bold">
      <div className="text-2xl">{`${equipment.equipment} - ${zone.zone}`}</div>
      <div className="mt-4mm flex justify-between">
        <div className="flex-39 pr-2mm">
          <ul className="list-reset text-sm text-left font-normal text-black">
            {['HIC_Theorical_CFH', 'GMAX_theorical_CFH', 'CFH_to_take_into_account'].map(
              item =>
                zone[item] && (
                  <li key={item} className="mt-2mm font-bold">
                    {capitalizeFirstWord(t(item).replace(/_/g, ' '))} :{' '}
                    <span className="text-primary-dark font-bold">
                      {zone[item].toFixed(2)} m
                    </span>
                  </li>
                )
            )}
          </ul>
          <div className="mt-4mm mr-2mm text-sm">
            <ReactTable
              data={impacts}
              columns={columns}
              minRows={1}
              previousText={t('previous')}
              nextText={t('next')}
              loadingText={t('loading')}
              noDataText={t('no data found')}
              pageText={t('page')}
              ofText={t('ofText')}
              rowsText={t('rows')}
              defaultPageSize={5}
              showPagination={false}
              className="-striped -highlight"
              defaultSorted={[
                {
                  id: 'height'
                }
              ]}
            />
          </div>
        </div>
        <div className="flex-59 overflow-hidden">
          <img
            className="max-h-80mm object-contain border-2 border-primary-dark"
            src={
              zone.picture
                ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/zones/${zone.id}/${zone.picture}`
                : defaultImg
            }
            alt="zone"
          />
          <div className="mt-2mm text-sm text-left font-normal text-black text-center">
            {zone.temp && zone.hygro && <p className="font-bold">
              {capitalizeFirstWord(t(switchName('temp')))} :{' '}
              <span className="text-primary-dark">{zone['temp']} °C</span>
              <span className="mx-4mm">/</span>
              {capitalizeFirstWord(t(switchName('hygro')))} :{' '}
              <span className="text-primary-dark">{zone['hygro']} %</span>
            </p>
            }
            
            {zone.specimen_temp && zone.specimen_hygro && <p className="font-bold">
              {capitalizeFirstWord(t('specimen_temp'))}:{' '}
              <span className="text-primary-dark">{zone['specimen_temp']}°C</span>
              <span className="mx-4mm">/</span>
              {capitalizeFirstWord(t('specimen_hygro'))}:{' '}
              <span className="text-primary-dark">{zone['specimen_hygro']}%</span>
            </p>
            }
          </div>
        </div>
      </div>
      <div className="mt-8mm text-sm font-normal text-black">
      {zone.comments && <p>
          <span className='font-bold'>{t('Comment') + ' : '}</span> 
          <span>{zone.comments}</span>
        </p>
      }
      </div>
      <div className="mt-8mm -mx-8mm flex text-sm">
        <div className="w-2/5">
          <div>
          <ImpactChart points={(impacts && maxBy(impacts, 'gmax') && maxBy(impacts, 'gmax').points) ? maxBy(impacts, 'gmax').points : '[]'} height={240} />
          </div>
          <div className="mt-4mm text-black text-lg">
            {capitalizeFirstLetter(t('time/acceleration curve of the highest value measured'))}
          </div>
        </div>
        <div className="w-3/5">
          <div>
            <MethodOneChart impacts={impacts} height={240} />
          </div>
          <div className="mt-4mm text-black text-lg">
          {capitalizeFirstLetter(t('curves of HIC and gmax vs. drop height'))}
          </div>
        </div>
      </div>
      <div className="mt-8mm text-xs text-black">
        {t('The uncertainty is ±7 %')} (EN1177 :2018 - §8.2 – h)
      </div>
    </article>
  );
};

export default withTranslation()(Zone)