import React from 'react';

const Modal = ({ isActive, children, onToggle }) => (
  <div
    className={`modal fixed z-40 pin overflow-auto flex items-center p-8 justify-center${
      isActive ? ' visible' : ' invisible'
    }`}
  >
    <div
      className="fixed pin overflow-auto bg-primary opacity-50 cursor-pointer"
      onClick={onToggle}
    />
    <div className="animated fadeInUp max-w-md relative pin-b pin-x align-top m-auto justify-end justify-center p-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
      {children}
    </div>
  </div>
);

export default Modal;
