import React from 'react';

const CustomPage = ({ report }) => {
  // Here i replace the Quill tags with custom style
  let customContent = report.htmlCustomPage
    .replace(/<h2>/g, "<div class='mt-8mm text-xl'>")
    .replace(/<\/h2>/g, '</div>')
    .replace(
      /<p>/g,
      "<div class='mt-4mm text-left text-sm font-normal text-black'>"
    )
    .replace(/<\/p>/g, '</div>');
  return (
    <article
      className="px-12mm text-center text-primary-darker font-bold"
      dangerouslySetInnerHTML={{ __html: JSON.parse(customContent) }}
    ></article>
  );
};

export default CustomPage;
