import { sumBy, orderBy } from 'lodash';
import { findParabolicAandC } from './goldenSectionSearch'

const DEFAULT_PERCENT_ERROR = 7 / 100

const linspace = (startValue, stopValue, cardinality) => {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + (step * i));
  }
  return arr;
}

export const getQuadraRes = ({ equation }, cNeg) => {
  let [a, b, c] = equation;
  c = c - cNeg;
  const delta = Math.pow(b, 2) - 4 * a * c;
  if (delta < 0) return null;
  const x1 = -b / 2 / a + Math.pow(delta, 0.5) / 2 / a;
  const x2 = -b / 2 / a - Math.pow(delta, 0.5) / 2 / a;
  if (!isFinite(x1) || !isFinite(x1)) return null;

  // If a > 0 the curve is convexe and if a < 0, curve is concave
  // So the normal is a > 0 where we want the righter value
  // Else it's unusual but we want lefter one
  return a > 0 ? Math.max(x1, x2) : Math.min(x1, x2)
};

export const getCFH = (HIC, Gmax) => {
  const HICCFH = getQuadraRes(HIC, 1000);
  const GmaxCFH = getQuadraRes(Gmax, 200);
  if (!HICCFH && !GmaxCFH) return null;
  if (!HICCFH) return ['gmax', GmaxCFH, Gmax.equation];
  if (!GmaxCFH) return ['HIC', HICCFH, HIC.equation];
  return HICCFH < GmaxCFH
    ? ['HIC', HICCFH, HIC.equation]
    : ['gmax', GmaxCFH, Gmax.equation];
};

export const getPoint = (dataPol, height, type) => {
  const [a, b, c] = dataPol.equation;
  const CFH = getQuadraRes(dataPol, type === 'HIC' ? 1000 : 200);
  if (!CFH) return null;
  return a * Math.pow(height, 2) + b * height + c - CFH;
};

export const getLinspace = (dataPol, left, right, cardinality=200) => {
  const [a, b, c] = dataPol.equation;
  let lnsp = linspace(left, right, cardinality).map(x => [ x, a*Math.pow(x, 2) + b*x + c]);
  return lnsp
};

export const getPolyResults = (impacts, ffh, method, marginError) => {
  marginError = marginError || DEFAULT_PERCENT_ERROR
  
  const limitGMAX = 200
  const limitHIC = 1000

  if(method === 1){
    const HICpoints = findParabolicAandC(
      impacts.map(({ height, HIC }) => [height/100, HIC])
    );
    const gMaxPoints = findParabolicAandC(
      impacts.map(({ height, gmax }) => [height/100, gmax])
    );
    const [, CFH] = getCFH(HICpoints, gMaxPoints);

    if (CFH && ffh) {        
      // Margin error = 7% * CFH
      const marginErrorCFH = CFH * marginError
      const bottomCFH = CFH - marginErrorCFH
      const topCFH = CFH + marginErrorCFH

      ffh = ffh / 100
      
      let result = 'Not Adequate';
      switch (true) {
        case ffh < bottomCFH:
          result = 'Adequate';
          break;
        case ffh >= bottomCFH && ffh <= topCFH:
          result = 'To Review';
          break;
        default:
          break;
      }
      return {
        HIC: null,
        Gmax: null,
        result
      };
    }
    else {
      return {
        HIC: null,
        Gmax: null,
        result: null
      };
    }
  }
  else if (method === 2) {
    const bottomGMAX = 195
    const topGMAX = 205
    const bottomHIC = 950
    const topHIC = 1050

    let HIC = null,
      Gmax = null;
    if (impacts.length === 1) {
      HIC = Math.round(impacts[0].HIC);
      Gmax = Math.round(impacts[0].gmax);
    } else {
      HIC = Math.round(
        sumBy(orderBy(impacts, 'HIC', 'desc').slice(0, 3), 'HIC') / 3
      );
      Gmax = Math.round(
        sumBy(orderBy(impacts, 'gmax', 'desc').slice(0, 3), 'gmax') / 3
      );
    }

    
    let result;
    
    if(impacts.length === 1){
      if( HIC < bottomHIC && Gmax < bottomGMAX ) {
        result = 'Adequate';
      }
      else if( HIC >= topHIC || Gmax >= topGMAX ) {
        result = 'Not Adequate';
      }
      else {
        result = 'To Review'
      }
    }
    else if(impacts.length < 4){
      result = 'To Review'
    }
    else {
      if( HIC < limitHIC && Gmax < limitGMAX ) {
        result = 'Adequate';
      }
      else {
        result = 'Not Adequate'
      }
    }
    
    return {
      HIC,
      Gmax,
      result
    };
  }
  else {
    throw new Error('method not handled')
  }
};
