import React from 'react';
import { withTranslation } from 'react-i18next';

import defaultImg from './default.jpg';

const Header = ({ report, t }) => (
  <header className="h-40mm flex border-b-4 border-primary-light mb-12mm pb-6mm justify-between text-sm">
    <img
      className="object-cover h-32mm"
      src={
        report.logo
          ? window.location.protocol + '//' + window.location.hostname + `/api/uploads/logo/${report.logo}`
          : defaultImg
      }
      alt="logo"
    />
    <div className="flex flex-col text-right justify-between">
      <div>{t(report.ref)}</div>
      <div className="flex flex-col">
        <div className="mt-1">{t('publishing') + ` : ${report.date}`}</div>
        <div className="mt-1">v{report.version}</div>
        <div className="mt-1 text-2xs italic">
          {t('This version cancels and replaces the previous one')}
        </div>
      </div>
    </div>
  </header>
);

export default withTranslation()(Header);
