import React from 'react';

import { Label } from '.';

export default React.forwardRef(
  ({ fieldName, label, value, unit, warning, forceTextRight }, ref) => (
    <>
      <Label htmlFor={fieldName}>{label}</Label>
      <div className={`flex mb-2`}>
        <div
          id={fieldName}
          className={`flex-1 appearance-none block w-full bg-${
            warning ? 'orange-lighter' : 'grey-light'
          } text-${warning ? 'orange' : 'grey'}-darker rounded${
            unit ? '-l' : ''
          } py-3 px-4 leading-tight ${
            (typeof value === 'number' || forceTextRight) ? 'text-right' : ''
          } border border-${warning ? 'orange-lighter' : 'grey-light'}
        `}
          ref={ref}
        >
          {value || '-'}
        </div>
        {unit && (
          <div
            className={`flex flex-col justify-center bg-grey-light text-grey-darker rounded-r py-3 px-4 border-l border-grey`}
          >
            <span>{unit}</span>
          </div>
        )}
      </div>
    </>
  )
);
