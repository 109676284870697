import React from 'react';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { findParabolicAandC } from '../../../../utils/goldenSectionSearch'

import { capitalizeFirstWord, getResultColor } from '../../../../utils/helpers';
import { getCFH, getPolyResults } from '../../../../utils/maths';

moment.locale('fr');

const EquipmentSummary = ({ i, equipment, t }) => {
  let zones = [];

  if (equipment.zones && equipment.zones.length > 0) {
    zones = equipment.zones
      .map(({ ffh, impacts, ...rest }) => {
        if (!ffh || !impacts || impacts.length === 0) {
          return {
            ...rest,
            FFH: ffh
          };
        }

        impacts = impacts.filter(i => !i.deleted_at && !i.deletedAt)
        impacts = impacts.map( ({height, hic, ...rest}) => {
          return {
            ...rest,
            HIC: hic,
            height: height
          }
        })
        const HICpoints = impacts.map(({ height, HIC }) => [height / 100, HIC])
        const gMaxPoints = impacts.map(({ height, gmax }) => [height / 100, gmax])
        const HICResult = findParabolicAandC(HICpoints);
        const GmaxResult = findParabolicAandC(gMaxPoints);
        const [, CFH] = getCFH(HICResult, GmaxResult);
        return {
          ...rest,
          FFH: ffh,
          CFH: CFH * 100,
          ...getPolyResults(impacts, ffh, 1)
        };
      })
  }

  const columns = ['zone', 'FFH', 'CFH', 'ground_thickness', 'result'].map(el => ({
    Header: capitalizeFirstWord(t(el)),
    accessor: el,
    minWidth: el === 'zone' ? 200 : el === 'result' ? 120 : 60,
    Cell: row => {
      return (
        <div
          className="w-full"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: getResultColor(row.value),
            textAlign: typeof row.value === 'number' ? 'right' : 'center'
          }}
        >
          <div className="w-full p-1">
            {(['FFH', 'CFH'].includes(el) && row.value)
              ? `${parseFloat(row.value / 100).toFixed(2)} m`
              : el === 'ground_thickness' 
                ? (row.value && row.value > 0) 
                  ? `${row.value} mm`
                  : ''
                : row.value}
          </div>
        </div>
      );
    }
  }));

  return (
    <article className="px-12mm text-center text-primary-darker">
      <div className="mt-10mm text-2xl">{t('Equipment results summary') + ` n°${i}`}</div>
      <div className="mt-4mm text-sm">
        <ReactTable
          data={zones}
          columns={columns}
          minRows={1}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('no data found')}
          pageText={t('page')}
          ofText={t('ofText')}
          rowsText={t('rows')}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    </article>
  );
};

export default withTranslation()(EquipmentSummary);
