import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { Container } from '../../components';
import Account from './Account';
import Report from './Report';
import Admin from './Admin';
import Wifi from './Wifi';
import Configuration from './Configuration';
import { capitalizeFirstWord } from '../../utils/helpers';

class Settings extends Component {

  render() {
    const { t } = this.props;
    const {
      match: {
        params: { settingsSection }
      }
    } = this.props;
    const isAdmin = localStorage.getItem('is-admin') || false;
    return (
      <Container isPrivate={true}>
        <Helmet>
          <title>
            {capitalizeFirstWord(t(settingsSection || 'settings'))} -
            Lugdosphère
          </title>
        </Helmet>
        <div className="w-full max-w-md mx-auto">
          <ul className="list-reset flex mb-8">
            <li className="flex-1 mr-2">
              <Link
                className={`${
                  (!this.props.wifiConfigOffline && (!settingsSection || settingsSection === 'account'))
                    ? 'tab-active'
                    : 'tab'
                } capitalize hover:no-underline border border-grey-lighter rounded`}
                to="/settings/account"
              >
                {t('account')}
              </Link>
            </li>
            <li className="flex-1 mr-2">
              <Link
                className={`${
                  settingsSection && settingsSection === 'report'
                    ? 'tab-active'
                    : 'tab'
                } capitalize hover:no-underline border border-grey-lighter rounded`}
                to="/settings/report"
              >
                {t('report')}
              </Link>
            </li>
          </ul>
          {this.props.wifiConfigOffline ? <Wifi /> : 
          (!settingsSection || settingsSection === 'account') ? (
            <Account handleLogout={this.handleLogout} />
          ) : settingsSection === 'report' ? (
            <Report />
          ) : (settingsSection === 'configuration' && isAdmin) ? (
            <Configuration />
          ) : (settingsSection === 'wifi') ? (
            <Wifi />
          ) : (
            <Admin />
          )}
        </div>
      </Container>
    );
  }

  handleLogout = () => {
    localStorage.removeItem('x-auth');
    localStorage.removeItem('is-admin');
    this.props.history.push('/login');
  };
}

export default withTranslation()(Settings);
